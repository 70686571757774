import Model from '@/models/Model'

const REGISTRATION_TYPES = {
  MATERIAL: 'M',
  HOURS_PROJECT: 'H',
  HOURS_GENERALLY: 'G',
}

class RegistrationTypeModel extends Model {
  constructor(data) {
    super(data)

    this.id = Number(data.id)
    this.replacedWith = data.replaced_with
    this.name = data.name
    this.unit = data.unit
    this.price = data.price
    this.groupId = data.group_id ?? null

    this.type = data.type

    this.isHoursProject = data.type === REGISTRATION_TYPES.HOURS_PROJECT
    this.isHoursGenerally = data.type === REGISTRATION_TYPES.HOURS_GENERALLY
    this.isHours = this.isHoursProject || this.isHoursGenerally

    this.isMaterial = data.type === REGISTRATION_TYPES.MATERIAL

    this.createdAt = this.fixData(data.created_at_utc)
    this.createdAtM = this.toMomentDate(data.created_at_utc)
  }
}

export { RegistrationTypeModel, REGISTRATION_TYPES }
