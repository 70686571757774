import Model from '@/models/Model'
import UserModel from '@/models/User'

class PlanningResourceModel extends Model {
  constructor(data) {
    super(data)

    this.id = Number(data.id)
    this.name = data.name
    this.position = Number(data.position)
    this.resourceGroupId = data.resource_group_id

    this.user = new UserModel(data.user || { first_name: this.name })
  }
}

export default PlanningResourceModel
