import { keyBy } from 'lodash';
import LabelsProvider from '@provider/labels';
import * as TYPES from '@/store/types';
import { DEFAULT_LABEL_COLOR } from '@/constants.js';

// initial state
const state = {
  labels: [],
  labelsById: {},
  defaultColors: [],
};

// getters
const getters = {
  labels: state => {
    return [...state.labels].sort((a, b) => {
      return a.id - b.id;
    });
  },

  defaultColors: state => state.defaultColors,

  getLabelByColor: state => color =>
    state.labels.find(label => label.color.toUpperCase() === color.toUpperCase()),

  getLabelById: state => id => state.labels.find(label => label.id === id),

  companyLabels: state => {
    const { labels, defaultColors } = state;
    const labelsMap = keyBy(labels, e => e.color.toUpperCase());
    const defaultLabels = defaultColors.reduce((list, color) => {
      const key = color.toUpperCase();
      if (!(key in labelsMap)) {
        list.push({ color: key });
      }
      return list;
    }, []);

    return [...labels.slice().sort((a, b) => a.id - b.id), ...defaultLabels];
  },

  resolveLabel: (_state, getters) => (value = {}) => {
    const existentLabel = getters.companyLabels.find(label => {
      const byId = label.id === value.id;
      const byColor = label.color.toUpperCase() === value.color.toUpperCase();
      return value.id ? byId && byColor : byColor;
    });
    const label = existentLabel ?? { ...value };
    return {
      color: label.color?.toUpperCase(),
      name: label.name || '',
      id: label.id || null,
    };
  },

  resolveDefaultLabel: (_state, getters) => () =>
    getters.resolveLabel({ color: DEFAULT_LABEL_COLOR }),
};

// actions
const actions = {
  addLabel: ({ commit }, { name, color }) => {
    return new Promise((resolve, reject) => {
      LabelsProvider.addLabel({ name, color })
        .then(label => {
          commit(TYPES.LABELS.INIT_LABEL, label);
          resolve(label);
        })
        .catch(reject);
    });
  },

  getLabels: ({ commit }) => {
    return new Promise((resolve, reject) => {
      LabelsProvider.getLabels()
        .then(labels => {
          commit(TYPES.LABELS.INIT_LABELS, labels);
          resolve(labels);
        })
        .catch(reject);
    });
  },

  getDefaultColors: ({ commit }) => {
    return new Promise((resolve, reject) => {
      LabelsProvider.getDefaultColors()
        .then(colors => {
          commit(TYPES.LABELS.INIT_DEFAULT_COLORS, colors);
          resolve(colors);
        })
        .catch(reject);
    });
  },

  getLabel: ({ commit }, { id }) => {
    return new Promise((resolve, reject) => {
      LabelsProvider.getLabel({ id })
        .then(label => {
          commit(TYPES.LABELS.INIT_LABEL, label);
          resolve(label);
        })
        .catch(reject);
    });
  },

  updateLabel: ({ commit }, { id, name, color }) => {
    return new Promise((resolve, reject) => {
      LabelsProvider.updateLabel({ id, name, color })
        .then(label => {
          commit(TYPES.LABELS.INIT_LABEL, label);
          resolve(label);
        })
        .catch(reject);
    });
  },

  deleteLabel: ({ dispatch }, { id }) => {
    return new Promise((resolve, reject) => {
      LabelsProvider.deleteLabel({ id })
        .then(() => {
          dispatch('deleteLabelLocally', id);
          resolve();
        })
        .catch(reject);
    });
  },

  deleteLabelLocally: ({ commit }, id) => {
    commit(TYPES.LABELS.DELETE_LABEL, id);
    commit(TYPES.PROJECTS.MOD.REMOVE_PROJECT_LABELS, id, { root: true });
  },
};

// mutations
const mutations = {
  [TYPES.LABELS.INIT_LABELS](state, labels) {
    const { labelsById } = state;
    labels.forEach(label => (labelsById[label.id] = label));
    state.labels = Object.values(labelsById);
  },

  [TYPES.LABELS.INIT_DEFAULT_COLORS](state, colors) {
    state.defaultColors = colors;
  },

  [TYPES.LABELS.INIT_LABEL](state, label) {
    const oldLabel = state.labelsById[label.id];
    if (oldLabel) {
      Object.assign(oldLabel, label);
    } else {
      state.labelsById[label.id] = label;
      state.labels.push(label);
    }
  },

  [TYPES.LABELS.DELETE_LABEL](state, id) {
    const itemIndex = state.labels.findIndex(item => item.id === id);
    if (itemIndex === -1) return;

    state.labels.splice(itemIndex, 1);
    delete state.labelsById[id];
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
