import { middlewareGroup, logMid, mobileMid } from './plugins/middleware';

export default middlewareGroup(
  [logMid, mobileMid],
  [
    {
      component: () => import('@/layouts/mobile'),
      path: '/mobile',
      children: [
        {
          name: 'SuccessResetPassword',
          path: 'success-reset-password',
          component: () => import('@/views/mobile/SuccessResetPassword/Index.vue'),
        },
        {
          path: '/welcome',
          name: 'WelcomeMobile',
          component: () => import('@/views/SignInMobile.vue'),
          // component: () =>
          //   import("@/views/mobile/WelcomeMobile.vue")
        },
      ],
    },
  ]
);
