import { cloneDeep, isUndefined } from 'lodash'
import moment from 'moment'
import checklistsProvider from '@provider/checklists'
import checklistItemsProvider from '@provider/checklistItems'
import * as TYPES from '@/store/types'
import { ChecklistItemModel } from '@/models';

function resolveChecklistItemDate(date) {
  if (isUndefined(date)) return date;
  if (!date) return null;
  return moment(date);
}

function findChecklistItem(state, { checklistId, checklistItemId }) {
  const checklist = state.checklists.find(checklist => checklist.id === checklistId);
  if (!checklist) return null;

  return checklist.items?.find?.(checklistItem => checklistItem.id === checklistItemId);
}

// initial state
const state = {
  checklists: [],
  checklistsByIds: {},
  checklistIdsByChecklistItemIds: {},
  checklistItemLogs: [],
  updatingChecklistItems: {},
  tasks: [],
  flagHideChecklistItems: false,
}

// getters
const getters = {
  tasks: state => state.tasks,

  getChecklistById: state => checklistId => state.checklists.find(c => c.id === checklistId),

  getChecklistItemByIds: state => ({ checklistId, itemId }) => {
    let checklistItem = null;

    let ch = state.checklists.find(e => e.id == checklistId)
    if (ch) {
      checklistItem = ch.items.find(e => e.id == itemId);
    }

    if (!checklistItem && itemId) {
      const { checklists } = state;

      for (let i = 0; i < checklists.length; i++) {
        const { items } = checklists[i];
        for (let j = 0; j < items.length; j++) {
          const item = items[j];
          if (item.id === itemId) {
            return item;
          }
        }
      }
    }

    return checklistItem;
  },

  getTaskById: state => ({ itemId }) => {
    return state.tasks.find(task => Number(task.id) === Number(itemId)) ?? null;
  },

  getChecklistItemOrTaskByIds: state => ({ checklistId, itemId }) => {
    const checklist = state.checklists.find(c => Number(c.id) === Number(checklistId));

    let item;

    if (checklist) {
      item = checklist.items.find(ci => Number(ci.id) === Number(itemId));
    }

    if (!item) {
      item = state.tasks.find(task => Number(task.id) === Number(itemId));
    }

    return item ?? null;
  },

  getterProjectChecklists: state => projectId => {
    return state.checklists
      .filter(e => e.projectId === projectId && e.chatId === 0)
      .sort((a, b) => a.id - b.id)
  },

  getterLogsByCheckListItemId: state => checklistItemId => {
    return state.checklistItemLogs.filter(
      e => e.checklistItemId == checklistItemId
    )
  },

  getterFlagHideChecklistCheckedItems: state => state.flagHideChecklistItems,

  getterCountCheckedItems: state => ({ projectId, chatId }) => {
    let res = chatId
      ? state.checklists.filter(e => e.chatId == chatId)
      : state.checklists.filter(e => e.projectId == projectId)

    return res
      .map(checklist => checklist.items.filter(item => item.checked).length)
      .reduce((a, b) => a + b, 0)
  },

  getterChatChecklists: state => chatId => {
    return state.checklists
      .filter(e => e.chatId === chatId)
      .sort((a, b) => a.id - b.id)
  },

  chatHasChecklists: state => chatId => {
    return !!state.checklists.find(e => e.chatId === chatId)
  },

  getChecklistItemUpdating: state => checklistItemId => state.updatingChecklistItems[checklistItemId],
}

// actions
const actions = {
  getChecklistsLastUsed: ({}) => {
    return checklistsProvider.getChecklistsLastUsed()
  },

  exportChecklist: (_, form) => {
    return checklistsProvider.exportChecklist(form)
  },

  downloadChecklistFiles: (_, form) => {
    return checklistsProvider.downloadChecklistFiles(form)
  },

  cloneChecklist: ({ commit }, form) => {
    return checklistsProvider.cloneChecklist(form)
      .then(checklist => {
        commit(TYPES.CHECKLISTS.INIT_CHECKLIST, checklist);
        return checklist;
      });
  },

  hideChecklistItems: ({ commit }) => {
    commit(TYPES.CHECKLISTS.HIDE_COMPLETED_CHECKLIST_ITEMS);
  },

  showChecklistItems: ({ commit }) => {
    commit(TYPES.CHECKLISTS.SHOW_COMPLETED_CHECKLIST_ITEMS);
  },

  addChecklist: ({ commit }, { name, projectId, chatId }) => {
    return new Promise((resolve, reject) => {
      checklistsProvider
        .addChecklist({ name, projectId, chatId })
        .then(checklist => {
          commit(TYPES.CHECKLISTS.INIT_CHECKLIST, checklist)
          resolve(checklist)
        })
        .catch(reject)
    })
  },

  getChecklist: ({ commit }, { id }) => {
    return checklistsProvider.getChecklist({ id })
      .then((checklist) => {
        commit(TYPES.CHECKLISTS.INIT_CHECKLIST, checklist)
      });
  },

  updateChecklistAccess: ({ commit }, { id, access }) => {
    return checklistsProvider.updateChecklistAccess({ id, access })
      .then(() => {
        commit(TYPES.CHECKLISTS.UPDATE_CHECKLIST_ACCESS, { id, access })
      });
  },

  updateChecklistCover: ({ commit }, { id, file }) => {
    return checklistsProvider.updateChecklistCover({ id, file })
      .then((checklist) => {
        commit(TYPES.CHECKLISTS.UPDATE_CHECKLIST, checklist);
      });
  },

  updateChecklistFavourite: ({ commit }, { id, favourite }) => {
    return checklistsProvider.updateChecklistFavourite({ id, favourite })
      .then(() => {
        commit(TYPES.CHECKLISTS.UPDATE_CHECKLIST_FAVOURITE, { id, favourite })
      });
  },

  updateChecklist: ({ commit, state }, { id, name, chatId }) => {
    return new Promise((resolve, reject) => {
      let save = { ...state.checklists.find(e => e.id == id) }
      commit(TYPES.CHECKLISTS.UPDATE_CHECKLIST, { ...save, name, chatId })

      checklistsProvider
        .updateChecklist({ id, name, chatId })
        .then(updatedChecklist => {
          // commit(TYPES.CHECKLISTS.UPDATE_CHECKLIST, updatedChecklist)
          resolve(updatedChecklist)
        })
        .catch(error => {
          commit(TYPES.CHECKLISTS.UPDATE_CHECKLIST, save)
          reject(error)
        })
    })
  },

  deleteChecklist: ({ commit }, { id }) => {
    return checklistsProvider.deleteChecklist({ id })
      .then(() => {
        commit(TYPES.CHECKLISTS.DELETE_CHECKLIST, id);
      });
  },

  updatePositionChecklist({ state, commit }, { id, position }) {
    return new Promise((resolve, reject) => {
      let save = { ...state.checklists.find(e => e.id == id) }

      commit(TYPES.CHECKLISTS.UPDATE_POSITION_CHECKLIST, { id, position })

      checklistsProvider
        .updateChecklist({
          id,
          position,
        })
        .then(checklist => {
          commit(TYPES.CHECKLISTS.UPDATE_CHECKLIST, checklist)
          resolve(checklist)
        })
        .catch(error => {
          commit(TYPES.CHECKLISTS.INIT_CHECKLIST, save)
          reject(error)
        })
    })
  },

  /* CHECKLIST LOGS */
  getChecklistItemLogs: (
    { commit },
    { offset, limit, checklistId, checklistItemId } = {}
  ) => {
    return new Promise((resolve, reject) => {
      checklistItemsProvider
        .getChecklistItemLogs({ offset, limit, checklistId, checklistItemId })
        .then(list => {
          commit(TYPES.CHECKLISTS.INIT_CHECKLIST_ITEM_LOGS, list)
          resolve(list)
        })
        .catch(reject)
    })
  },

  getAllChecklistItems: ({ commit }, params) => {
    return new Promise((resolve, reject) => {
      checklistItemsProvider
        .getAllChecklistItems(params)
        .then(list => {
          commit(TYPES.CHECKLISTS.CLEAR_TASKS)
          commit(TYPES.CHECKLISTS.INIT_TASKS, list)
          resolve(list)
        })
        .catch(reject)
    })
  },

  /* CHECKLSIT ITEMS */

  addChecklistItemMembers: ({ commit }, { checklistId, checklistItemId, synchronize, userIds }) => {
    return checklistItemsProvider
      .addChecklistItemMembers({
        checklistId,
        checklistItemId,
        synchronize,
        userIds,
      })
      .then(() => {
        userIds.forEach(userId => {
          commit(TYPES.CHECKLISTS.INIT_CHECKLIST_ITEM_MEMBER, {
            checklistId,
            checklistItemId,
            userId,
          });
          commit(TYPES.CHECKLISTS.INIT_TASK_MEMBER, {
            checklistItemId,
            userId,
          });
        });
      })
  },

  deleteChecklistItemMembers: ({ commit }, { checklistId, checklistItemId, synchronize, userIds }) => {
    return checklistItemsProvider.deleteChecklistItemMembers({
      checklistId,
      checklistItemId,
      synchronize,
      userIds,
    })
      .then(users => {
        commit(TYPES.CHECKLISTS.SET_CHECKLIST_ITEM_MEMBER, {
          checklistId,
          checklistItemId,
          userIds: users.map(({ id }) => id),
        });
        commit(TYPES.CHECKLISTS.SET_TASK_MEMBER, {
          checklistItemId,
          userIds: users.map(({ id }) => id),
        });
      });
  },

  setChecklistItemMembersLocally: ({ commit }, { checklistId, checklistItemId, userIds }) => {
    commit(TYPES.CHECKLISTS.SET_CHECKLIST_ITEM_MEMBER, { checklistId, checklistItemId, userIds });
    commit(TYPES.CHECKLISTS.SET_TASK_MEMBER, { checklistItemId, userIds: cloneDeep(userIds) });
  },

  addChecklistItemResources: (
    { commit },
    { checklistId, checklistItemId, synchronize, resourceIds }
  ) => {
    return checklistItemsProvider
      .addChecklistItemResources({
        checklistId,
        checklistItemId,
        synchronize,
        resourceIds,
      })
      .then(() => {
        resourceIds.forEach(resourceId => {
          commit(TYPES.CHECKLISTS.INIT_CHECKLIST_ITEM_RESOURCE, {
            checklistId,
            checklistItemId,
            resourceId,
          });
          commit(TYPES.CHECKLISTS.INIT_TASK_RESOURCE, {
            checklistItemId,
            resourceId,
          });
        });
      });
  },

  deleteChecklistItemResources: (
    { commit },
    { checklistId, checklistItemId, synchronize, resourceIds }
  ) => {
    return checklistItemsProvider
      .deleteChecklistItemResources({
        checklistId,
        checklistItemId,
        synchronize,
        resourceIds,
      })
      .then(resources => {
        commit(TYPES.CHECKLISTS.SET_CHECKLIST_ITEM_RESOURCES, {
          checklistId,
          checklistItemId,
          resourceIds: resources.map(({ id }) => id),
        });
        commit(TYPES.CHECKLISTS.SET_TASK_RESOURCES, {
          checklistItemId,
          resourceIds: resources.map(({ id }) => id),
        });
      });
  },

  // setChecklistItemResourcesLocally: ({ commit }, { checklistId, checklistItemId, resources }) => {
  //   commit(TYPES.CHECKLISTS.SET_CHECKLIST_ITEM_RESOURCES, {
  //     checklistId,
  //     checklistItemId,
  //     resourceIds: resources.map(({ id }) => id),
  //   });
  //   commit(TYPES.CHECKLISTS.SET_TASK_RESOURCES, {
  //     checklistItemId,
  //     resourceIds: resources.map(({ id }) => id),
  //   });
  // },

  addChecklistItem: (
    { commit },
    {
      checklistId,
      name,
      startAt,
      endAt,
      userIds,
      resourceIds,
      synchronize,
      emails,
      location,
      description,
    }
  ) => {
    return checklistItemsProvider
      .addChecklistItem({
        checklistId,
        name,
        startAt,
        endAt,
        userIds,
        resourceIds,
        synchronize,
        emails,
        location,
        description
      })
      .then(checklistItem => {
        commit(TYPES.CHECKLISTS.INIT_CHECKLIST_ITEMS, { data: checklistItem, checklistId });
        commit(TYPES.CHECKLISTS.INIT_TASKS, cloneDeep(checklistItem));
        return checklistItem;
      });
  },

  cloneChecklistItems: ({ commit }, { checklistId, checklistItemIds }) => {
    return checklistItemsProvider.cloneChecklistItems({
      checklistId,
      checklistItemIds,
    })
      .then((checklistItems) => {
        checklistItems.forEach((checklistItem) => {
          commit(TYPES.CHECKLISTS.INIT_CHECKLIST_ITEMS, { data: checklistItem, checklistId });
          commit(TYPES.CHECKLISTS.INIT_TASKS, cloneDeep(checklistItem));
        });
      });
  },

  getChecklistItems: ({}, { checklistId }) => {
    return checklistItemsProvider.getChecklistItems({ checklistId })
  },

  getChecklistItem: ({ commit }, { checklistId, itemId }) => {
    return checklistItemsProvider.getChecklistItem({ checklistId, itemId })
      .then((checklistItem) => {
        commit(TYPES.CHECKLISTS.INIT_CHECKLIST_ITEMS, { data: checklistItem, checklistId });
        commit(TYPES.CHECKLISTS.INIT_TASKS, cloneDeep(checklistItem));
      });
  },

  updateChecklistItem: (
    { commit },
    {
      checklistId,
      newChecklistId,
      itemId,
      checked,
      name,
      position,
      startAt,
      endAt,
      synchronize,
      eventId,
      emails,
      location,
      description,
    }
  ) => {
    commit(TYPES.CHECKLISTS.SET_UPDATING_CHECKLIST_ITEM, {
      checklistItemId: itemId,
      value: true,
    });

    return checklistItemsProvider.updateChecklistItem({
      checklistId,
      newChecklistId,
      itemId,
      checked,
      name,
      position,
      startAt,
      endAt,
      eventId,
      synchronize,
      emails,
      location,
      description,
    })
      .then((updatedChecklistItem) => {
        commit(TYPES.CHECKLISTS.UPDATE_CHECKLIST_ITEM, {
          ...updatedChecklistItem,
          checklistId,
          newChecklistId,
          checked,
          name,
          position,
          synchronize,
          location,
          description,
          checklistItemId: itemId,
          emails: updatedChecklistItem.emails,
          eventId: eventId ?? updatedChecklistItem.eventId,
          startAt: resolveChecklistItemDate(startAt),
          endAt: resolveChecklistItemDate(endAt),
        });
        commit(TYPES.CHECKLISTS.UPDATE_TASK, {
          ...updatedChecklistItem,
          checklistId,
          newChecklistId,
          checked,
          name,
          position,
          synchronize,
          location,
          description,
          checklistItemId: itemId,
          emails: updatedChecklistItem.emails,
          eventId: eventId ?? updatedChecklistItem.eventId,
          startAt: resolveChecklistItemDate(startAt),
          endAt: resolveChecklistItemDate(endAt),
        });
      })
      .finally(() => {
        commit(TYPES.CHECKLISTS.SET_UPDATING_CHECKLIST_ITEM, {
          checklistItemId: itemId,
          value: false,
        });
      });
  },

  deleteChecklistItem: ({ commit }, { checklistId, itemId }) => {
    return checklistItemsProvider.deleteChecklistItem({ checklistId, itemId })
      .then(() => {
        commit(TYPES.CHECKLISTS.DELETE_CHECKLIST_ITEM, { checklistId, checklistItemId: itemId });
        commit(TYPES.CHECKLISTS.DELETE_TASK, { checklistItemId: itemId });
      });
  },

  updatePositionChecklistItem: (
    { state, commit },
    { newChecklistId, oldChecklistId, itemId, position }
  ) => {
    return new Promise((resolve, reject) => {
      // let checklist = state.checklists.find(e => e.id == checklistId)
      // if (!checklist) return

      // let checklistItem = checklist.items.find(e => e.id == itemId)
      // if (!checklistItem) return

      // const save = { ...checklistItem }

      if (position <= 0) position = 1

      commit(TYPES.CHECKLISTS.UPDATE_POSITION_CHECKLIST_ITEM, {
        newChecklistId,
        oldChecklistId,
        position,
        itemId,
      })

      // resolve()
      // return

      checklistItemsProvider
        .updateChecklistItem({
          checklistId: oldChecklistId,
          newChecklistId:
            oldChecklistId != newChecklistId ? newChecklistId : undefined,

          itemId,
          position,
        })
        .then(checklistItem => {
          // commit(TYPES.CHECKLISTS.INIT_CHECKLIST_ITEMS, {data: checklistItem, checklistId});
          resolve(checklistItem)
        })
        .catch(error => {
          // commit(TYPES.CHECKLISTS.INIT_CHECKLIST_ITEMS, {data: save, checklistId});
          reject(error)
        })
    })
  },

  updateChecklistExpandedState: ({ commit }, { checklistId, value }) => {
    return checklistsProvider.updateChecklistExpandedState({ checklistId, value }).then(() => {
      commit(TYPES.CHECKLISTS.UPDATE_CHECKLIST_EXPANDED_STATE, { checklistId, value });
    });
  },

  updateChecklistItemLocally: (
    { commit },
    {
      checklistId,
      itemId,
      labels,
      startAt,
      endAt,
    },
  ) => {
    commit(TYPES.CHECKLISTS.UPDATE_CHECKLIST_ITEM, {
      checklistId,
      checklistItemId: itemId,
      labels,
      startAt: resolveChecklistItemDate(startAt),
      endAt: resolveChecklistItemDate(endAt),
    });
  },

  addChecklistItemLabel: ({ commit }, { checklistId, checklistItemId, labelId }) => {
    return checklistItemsProvider.addChecklistItemLabel({ checklistId, checklistItemId, labelId })
      .then(labels => {
        commit(TYPES.CHECKLISTS.SET_CHECKLIST_ITEM_LABELS, {
          checklistId,
          checklistItemId,
          labels: cloneDeep(labels),
        });
        commit(TYPES.CHECKLISTS.SET_TASK_LABELS, {
          checklistItemId,
          labels,
        });
      });
  },

  deleteChecklistItemLabel: ({ commit }, { checklistId, checklistItemId, labelId }) => {
    return checklistItemsProvider.deleteChecklistItemLabel({ checklistId, checklistItemId, labelId })
      .then(labels => {
        commit(TYPES.CHECKLISTS.SET_CHECKLIST_ITEM_LABELS, {
          checklistId,
          checklistItemId,
          labels: cloneDeep(labels),
        });
        commit(TYPES.CHECKLISTS.SET_TASK_LABELS, {
          checklistItemId,
          labels,
        });
      });
  },

  changeChecklistItemLabel: async ({ dispatch }, { checklistId, checklistItemId, label, oldLabel }) => {
    if (label && oldLabel && label.id  === oldLabel.id) return;

    await dispatch('addChecklistItemLabel', {
      checklistId,
      checklistItemId,
      labelId: label.id,
    });

    if (oldLabel) {
      await dispatch('deleteChecklistItemLabel', {
        checklistId,
        checklistItemId,
        labelId: oldLabel.id,
      });
    };
  },

  deleteChecklistLocally({ commit }, checklistId) {
    commit(TYPES.CHECKLISTS.DELETE_CHECKLIST, checklistId);
  },

  processChecklistItemFromSocketEvent({ commit, state }, item) {
    const checklistItem = new ChecklistItemModel(item);

    if (item.deleted_at) {
      commit(TYPES.CHECKLISTS.DELETE_CHECKLIST_ITEM, {
        checklistId: checklistItem.checklistId,
        checklistItemId: checklistItem.id,
      });

      commit(TYPES.CHECKLISTS.DELETE_TASK, {
        checklistItemId: checklistItem.id,
      });
    } else {
      const checklistId = state.checklistIdsByChecklistItemIds[item.id];

      if (checklistId) {
        const { checklist: _, ...updatedProperties } = checklistItem;
        commit(TYPES.CHECKLISTS.UPDATE_CHECKLIST_ITEM, {
          ...updatedProperties,
          newChecklistId: checklistItem.checklistId,
          checklistItemId: checklistItem.id,
          checklistId,
        });
      } else {
        commit(TYPES.CHECKLISTS.INIT_CHECKLIST_ITEMS, {
          data: checklistItem,
          checklistId: checklistItem.checklistId,
        });
      }

      // TODO: update the task via properties patch
      commit(TYPES.CHECKLISTS.INIT_TASKS, checklistItem);
    }
  },
}

// mutations
const mutations = {
  /**
   * ATTENTION: the mutation does not update a list of checklist items.
   * This is necessary to optimize calculations in a project planning.
   */
  [TYPES.CHECKLISTS.UPDATE_CHECKLIST](state, checklist) {
    let findIndex = state.checklists.findIndex(e => e.id == checklist.id)

    if (findIndex !== -1) {
      const { items, ...updatedProperties } = checklist;
      // TODO: research the needs for deep merging
      Object.assign(state.checklists[findIndex], updatedProperties)
    }
  },

  [TYPES.CHECKLISTS.UPDATE_POSITION_CHECKLIST](state, { id, position }) {
    let checklist = state.checklists.find(e => e.id == id)
    if (!checklist) return

    let checklists = [...state.checklists]
        .filter(e => e.id != id)
        .sort((a, b) => a.position - b.position),
      nextItem = checklists[position - 1],
      prevItem = checklists[position - 2],
      newPos = 1

    if (!prevItem && nextItem) {
      newPos = nextItem.position - 0.0000005
    } else if (!nextItem && prevItem) {
      newPos = prevItem.position + 1
    } else if (prevItem && nextItem) {
      newPos = (prevItem.position + nextItem.position) / 2
    }

    checklist.position = newPos
  },

  [TYPES.CHECKLISTS.UPDATE_CHECKLIST_ACCESS](state, { id, access }) {
    let checklist = state.checklists.find(e => e.id == id)
    if (!checklist) return

    checklist.access = access
  },

  [TYPES.CHECKLISTS.UPDATE_CHECKLIST_FAVOURITE](state, { id, favourite }) {
    let checklist = state.checklists.find(e => e.id == id)
    if (!checklist) return

    checklist.favourite = favourite
  },

  [TYPES.CHECKLISTS.UPDATE_POSITION_CHECKLIST_ITEM](
    state,
    { newChecklistId, oldChecklistId, position, itemId }
  ) {
    // let checklist = state.checklists.find(e => e.id == checklistId)
    // if (!checklist) return

    // let checklistItem = checklist.items.find(e => e.id == itemId)
    // if (!checklistItem) return

    let checklist = state.checklists.find(e => e.id == newChecklistId)
    if (!checklist) return

    /*
    let checklist = state.checklists.find(e => e.id == checklistId),
      oldChecklist = state.checklists.find(e => e.id == oldChecklistId)

    if (!checklist || !oldChecklist) return

    let checklistItem = oldChecklist.items.find(e => e.id == itemId)

    if (!checklistItem) return

    if (oldChecklist.id != checklist.id) {
      oldChecklist.items = checklist.items.filter(e => e.id != itemId)
      checklist.items.push(checklistItem)
    }
    */

    let items = [...checklist.items]
        .filter(e => e.id != itemId)
        .sort((a, b) => a.position - b.position),
      nextItem = items[position - 1],
      prevItem = items[position - 2],
      newPos = 1

    if (!prevItem && nextItem) {
      newPos = nextItem.position - 0.0000005
    } else if (!nextItem && prevItem) {
      newPos = prevItem.position + 1
    } else if (prevItem && nextItem) {
      newPos = (prevItem.position + nextItem.position) / 2
    }

    /** Logic for move checklist item to another checklist */
    if (newChecklistId != oldChecklistId) {
      // let checklistItem =
      let oldChecklist = state.checklists.find(e => e.id == oldChecklistId)
      if (!oldChecklist) return

      let itemToMove = oldChecklist.items.find(e => e.id == itemId)
      if (!itemToMove) return

      itemToMove.checklistId = newChecklistId
      itemToMove.position = newPos
      checklist.items.push(itemToMove)
      oldChecklist.items = oldChecklist.items.filter(e => e.id != itemId)

      debug(
        `Move from checklist ${oldChecklistId} to ${newChecklistId}`,
        checklist.items.map(e => `${e.name} - ${e.position}`)
      )

      return
    }

    /** Logic for move checklist item in current checklist */
    let checklistItem = checklist.items.find(e => e.id == itemId)
    if (checklistItem) checklistItem.position = newPos
    debug(
      `Move in checklist ${oldChecklistId}`,
      checklist.items.map(e => `${e.name} - ${e.position}`)
    )
  },

  [TYPES.CHECKLISTS.CLEAR_TASKS](state) {
    // state.tasks = []
  },

  [TYPES.CHECKLISTS.INIT_TASKS](state, data) {
    let items = Array.isArray(data) ? data : [data],
      ids = items.map(e => e.id)

    state.tasks = [
      ...state.tasks.filter(e => !ids.includes(e.id)),
      ...items.filter(e => !e.isDeleted),
    ]
  },

  [TYPES.CHECKLISTS.SET_TASKS](state, data) {
    state.tasks = data
  },

  [TYPES.CHECKLISTS.INIT_CHECKLIST_ITEM_LOGS](state, data) {
    let items = Array.isArray(data) ? data : [data],
      ids = items.map(e => e.id)

    state.checklistItemLogs = [
      ...state.checklistItemLogs.filter(e => !ids.includes(e.id)),
      ...items,
    ]
  },

  [TYPES.CHECKLISTS.INIT_CHECKLIST_ITEMS](state, { data, checklistId }) {
    let checklist = state.checklists.find(e => e.id == checklistId)
    if (!checklist) return

    let items = Array.isArray(data) ? data : [data],
      ids = items.map(e => e.id)

    checklist.items = [
      ...checklist.items.filter(e => !ids.includes(e.id)),
      ...items,
    ]

    items.forEach((checklistItem) => {
      state.checklistIdsByChecklistItemIds[checklistItem.id] = checklistId;
    });
  },

  [TYPES.CHECKLISTS.DELETE_CHECKLIST_ITEM](state, { checklistId, checklistItemId }) {
    const checklist = state.checklists.find(checklist => checklist.id === checklistId);
    if (checklist) {
      checklist.items = checklist.items.filter(checklistItem => checklistItem.id !== checklistItemId);
    }

    delete state.checklistIdsByChecklistItemIds[checklistItemId];
  },

  [TYPES.CHECKLISTS.UPDATE_CHECKLIST_ITEM](state, {
    checklistId,
    newChecklistId = checklistId,
    checklistItemId,
    ...updatedFields
  }) {
    const checklist = state.checklists.find(checklist => checklist.id === checklistId);
    if (!checklist) return;

    const checklistItem = checklist.items.find(checklistItem => checklistItem.id === checklistItemId);
    if (!checklistItem) return;

    if (checklistId !== newChecklistId) {
      checklist.items = checklist.items.filter(checklistItem => checklistItem.id !== checklistItemId);
      // find the next checklist
      const newChecklist = state.checklists.find(checklist => checklist.id === newChecklistId);
      if (!newChecklist) return;

      newChecklist.items.push(checklistItem);
    }

    Object.entries(updatedFields).forEach(([field, value]) => {
      if (!isUndefined(value)) {
        checklistItem[field] = value;
      }
    });

    checklistItem.checklistId = newChecklistId;

    state.checklistIdsByChecklistItemIds[checklistItemId] = newChecklistId;
  },

  [TYPES.CHECKLISTS.UPDATE_CHECKLIST_ITEM_FILE_AND_COMMENT_COUNTS](state, {
    checklistId,
    checklistItemId,
    comments,
  }) {
    const checklist = state.checklists.find(checklist => checklist.id === checklistId);
    if (!checklist) return;

    const checklistItem = checklist.items.find(checklistItem => checklistItem.id === checklistItemId);
    if (!checklistItem) return;

    const countFiles = comments.filter((i) => !!i.file).length;
    checklistItem.countFiles = countFiles;
    checklistItem.countComments = comments.length - countFiles;
  },

  [TYPES.CHECKLISTS.DELETE_TASK](state, { checklistItemId }) {
    state.tasks = state.tasks.filter(task => task.id !== checklistItemId);
  },

  [TYPES.CHECKLISTS.UPDATE_TASK](state, {
    checklistId,
    newChecklistId = checklistId,
    checklistItemId,
    ...updatedFields
  }) {
    const task = state.tasks.find(task => task.id === checklistItemId);
    if (!task) return;

    Object.entries(updatedFields).forEach(([field, value]) => {
      if (!isUndefined(value)) {
        task[field] = value;
      }
    });

    task.checklistId = newChecklistId;
  },

  [TYPES.CHECKLISTS.INIT_CHECKLIST](state, checklist) {
    const index = state.checklists.findIndex(c => c.id === checklist.id);

    if (index !== -1) {
      const [oldChecklist] = state.checklists.splice(index, 1, checklist);

      oldChecklist.items.forEach((checklistItem) => {
        delete state.checklistIdsByChecklistItemIds[checklistItem.id];
      });
    } else {
      state.checklists.push(checklist);
    }

    checklist.items.forEach((checklistItem) => {
      state.checklistIdsByChecklistItemIds[checklistItem.id] = checklist.id;
    });

    state.checklistsByIds[checklist.id] = checklist;
  },

  [TYPES.CHECKLISTS.DELETE_CHECKLIST](state, id) {
    const index = state.checklists.findIndex(c => c.id === id);

    if (index !== -1) {
      const [checklist] = state.checklists.splice(index, 1);

      checklist.items.forEach((checklistItem) => {
        delete state.checklistIdsByChecklistItemIds[checklistItem.id];
      });
    }

    delete state.checklistsByIds[id];
  },

  [TYPES.CHECKLISTS.HIDE_COMPLETED_CHECKLIST_ITEMS](state) {
    state.flagHideChecklistItems = true
  },

  [TYPES.CHECKLISTS.SHOW_COMPLETED_CHECKLIST_ITEMS](state) {
    state.flagHideChecklistItems = false
  },

  [TYPES.CHECKLISTS.INIT_CHECKLIST_ITEM_COMMENTS](state, data) {
    let comments = Array.isArray(data) ? data : [data],
      ids = comments.map(e => e.id)

    state.chats = [
      ...state.itemComments.filter(e => !ids.includes(e.id)),
      ...comments.filter(e => e.canView),
    ]
  },

  [TYPES.CHECKLISTS.INIT_CHECKLIST_ITEM_MEMBER](state, { checklistId, checklistItemId, userId }) {
    const checklist = state.checklists.find(checklist => checklist.id === checklistId);
    if (!checklist) return;

    const checklistItem = checklist.items?.find?.(checklistItem => checklistItem.id === checklistItemId);
    if (!checklistItem) return;

    const index = checklistItem.userIds.indexOf(userId);
    if (index !== -1) {
      checklistItem.userIds.splice(index, 1, userId);
    } else {
      checklistItem.userIds.push(userId);
    }
  },

  [TYPES.CHECKLISTS.DELETE_CHECKLIST_ITEM_MEMBER](state, { checklistId, checklistItemId, userId }) {
    const checklist = state.checklists.find(checklist => checklist.id === checklistId);
    if (!checklist) return;

    const checklistItem = checklist.items?.find?.(checklistItem => checklistItem.id === checklistItemId);
    if (!checklistItem) return;

    if (checklistItem) {
      checklistItem.userIds = [...new Set(checklistItem.userIds).delete(userId)];
    }
  },

  [TYPES.CHECKLISTS.SET_CHECKLIST_ITEM_MEMBER](state, { checklistId, checklistItemId, userIds }) {
    const checklist = state.checklists.find(checklist => checklist.id === checklistId);
    if (!checklist) return;

    const checklistItem = checklist.items?.find?.(checklistItem => checklistItem.id === checklistItemId);
    if (!checklistItem) return;

    if (checklistItem) {
      checklistItem.userIds = userIds;
    }
  },

  [TYPES.CHECKLISTS.INIT_TASK_MEMBER](state, { checklistItemId, userId }) {
    const task = state.tasks.find(task => task.id === checklistItemId);
    if (!task) return;

    const index = task.userIds.indexOf(userId);
    if (index !== -1) {
      task.userIds.splice(index, 1, userId);
    } else {
      task.userIds.push(userId);
    }
  },

  [TYPES.CHECKLISTS.SET_TASK_MEMBER](state, { checklistItemId, userIds }) {
    const task = state.tasks.find(task => task.id === checklistItemId);
    if (!task) return;

    task.userIds = userIds;
  },

  [TYPES.CHECKLISTS.DELETE_TASK_MEMBER](state, { checklistItemId, userId }) {
    const task = state.tasks.find(task => task.id === checklistItemId);
    if (!task) return;

    task.userIds = [...new Set(task.userIds).delete(userId)];
  },

  [TYPES.CHECKLISTS.UPDATE_CHECKLIST_EXPANDED_STATE](state, { checklistId, value }) {
    const checklist = state.checklists.find(checklist => Number(checklist.id) === Number(checklistId));

    if (!checklist) return
      checklist.isClosed = value;
    
  },

  [TYPES.CHECKLISTS.SET_UPDATING_CHECKLIST_ITEM](
    state,
    { checklistItemId, value }
  ) {
    if (value) {
      state.updatingChecklistItems[checklistItemId] = value;
    } else {
      delete state.updatingChecklistItems[checklistItemId];
    }
  },

  [TYPES.CHECKLISTS.SET_CHECKLIST_ITEM_LABELS](
    state,
    {
      checklistId,
      checklistItemId,
      labels,
    },
  ) {
    const checklistItem = findChecklistItem(state, { checklistId, checklistItemId });

    if (checklistItem) {
      checklistItem.labels = labels;
    }
  },

  [TYPES.CHECKLISTS.SET_TASK_LABELS](
    state,
    {
      checklistItemId,
      labels,
    },
  ) {
    const task = state.tasks.find(task => task.id === checklistItemId);

    if (task) {
      task.labels = labels;
    }
  },

  [TYPES.CHECKLISTS.INIT_CHECKLIST_ITEM_RESOURCE](
    state,
    { checklistId, checklistItemId, resourceId }
  ) {
    const checklist = state.checklists.find(checklist => checklist.id === checklistId);
    if (!checklist) return;

    const checklistItem = checklist.items?.find?.(
      checklistItem => checklistItem.id === checklistItemId
    );
    if (!checklistItem) return;

    const index = checklistItem.resourceIds.indexOf(resourceId);
    if (index !== -1) {
      checklistItem.resourceIds.splice(index, 1, resourceId);
    } else {
      checklistItem.resourceIds.push(resourceId);
    }
  },

  [TYPES.CHECKLISTS.SET_CHECKLIST_ITEM_RESOURCES](
    state,
    { checklistId, checklistItemId, resourceIds }
  ) {
    const checklist = state.checklists.find(checklist => checklist.id === checklistId);
    if (!checklist) return;

    const checklistItem = checklist.items?.find?.(
      checklistItem => checklistItem.id === checklistItemId
    );
    if (!checklistItem) return;

    if (checklistItem) {
      checklistItem.resourceIds = resourceIds;
    }
  },

  [TYPES.CHECKLISTS.INIT_TASK_RESOURCE](state, { checklistItemId, resourceId }) {
    const task = state.tasks.find(task => task.id === checklistItemId);
    if (!task) return;

    const index = task.resourceIds.indexOf(resourceId);
    if (index !== -1) {
      task.resourceIds.splice(index, 1, resourceId);
    } else {
      task.resourceIds.push(resourceId);
    }
  },

  [TYPES.CHECKLISTS.SET_TASK_RESOURCES](state, { checklistItemId, resourceIds }) {
    const task = state.tasks.find(task => task.id === checklistItemId);
    if (!task) return;

    task.resourceIds = resourceIds;
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
