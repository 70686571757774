import _ from 'lodash'
import Store from '@/store'

const getAppLocale = () => Store.state.App.language;

class UtilsSort {
  constructor() {
    this.DATE_SORT = {
      NAME_AZ: 'nameAZ',
      NAME_ZA: 'nameZA',
      DATE_OLD_NEW: 'dateOldNew',
      DATE_NEW_OLD: 'dateNewOld',
    }

    this.types = {
      AZ: 'nameAZ',
      ZA: 'nameZA',
      DATE_OLD_NEW: 'dateOldNew',
      DATE_NEW_OLD: 'dateNewOld',
    }
  }

  fnSortBy(a, b, type) {
    type = type || this.DATE_SORT.NAME_AZ
    a = String(a || '')
    b = String(b || '')

    const locale = getAppLocale();

    switch (type) {
      case this.DATE_SORT.NAME_AZ:
        return a.localeCompare(b, locale, { numeric: true, sensitivity: 'base' })
        break

      case this.DATE_SORT.NAME_ZA:
        return b.localeCompare(a, locale, { numeric: true, sensitivity: 'base' })
        break

      case this.DATE_SORT.DATE_NEW_OLD:
        return b - a
        break

      case this.DATE_SORT.DATE_OLD_NEW:
        return a - b
        break
    }
  }

  fnSortObject(obj, key, type) {
    return Object.entries(obj).sort((a, b) =>
      this.sortByName(a[1][key], b[1][key], type)
    )
  }

  sortByName(a, b, type) {
    type = type || this.DATE_SORT.NAME_AZ

    a = a || ''
    b = b || ''

    const locale = getAppLocale();

    switch (type) {
      case this.DATE_SORT.NAME_AZ:
        return a.localeCompare(b, locale, {
          numeric: true,
          sensitivity: 'base',
        })

      case this.DATE_SORT.NAME_ZA:
        return b.localeCompare(a, locale, {
          numeric: true,
          sensitivity: 'base',
        })
    }
  }

  fnSortByDate(a, b, type) {
    type = type || this.DATE_SORT.NAME_AZ

    let formatObj = e => {
      return {
        date: _.get(e, 'date'),
        name: _.get(e, 'name', 'Z'),
      }
    }

    a = formatObj(a)
    b = formatObj(b)

    const locale = getAppLocale();

    switch (type) {
      case this.DATE_SORT.NAME_AZ:
        return a.name.localeCompare(b.name, locale, {
          numeric: true,
          sensitivity: 'base',
        })
        break

      case this.DATE_SORT.NAME_ZA:
        return b.name.localeCompare(a.name, locale, {
          numeric: true,
          sensitivity: 'base',
        })
        break

      case this.DATE_SORT.DATE_NEW_OLD:
        return b.date - a.date
        break

      case this.DATE_SORT.DATE_OLD_NEW:
        return a.date - b.date
        break
    }
  }
}

export default new UtilsSort()
