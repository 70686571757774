class SearchModel {
  constructor(data) {
    this.archived = Boolean(data.archived)
    this.id = Number(data.id)
    this.name = data.name
    this.number = data.number
    this.description = data.description
    this.firstProjectChatId = data.first_project_chat_id

    this.contactPersonDescription = data.contact_person_description || ''
    this.contactPerson = data.contact_person
  }
}

export default SearchModel
