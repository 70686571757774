import Moment from 'moment'
import { computed, ref } from 'vue';
import { addDays, startOfISOWeek, getDay } from 'date-fns';
import { capitalize } from 'lodash';
import { format } from '@/helpers/dates/format';

class UtilsTime {
  constructor() {
    this.HOURS_ARR = [...Array(24).keys()].map(e => this.pad(e))
    this.MINUTES_ARR = [...Array(60).keys()].map(e => this.pad(e))

    this.now = ref(Moment());
    setInterval(() => {
      this.now.value = Moment();
    }, 1000)

    this.weekDays = computed(() => {
      return Array.from(
        { length: 7 },
        (_, index) => {
          const date = addDays(startOfISOWeek(new Date()), index);
          const name = capitalize(format(date, 'iiiiii'));
          const day = getDay(date);
          return {
            day,
            name,
          };
        },
      );
    });
  }

  pad(a) {
    let b = Number(a)
    return String(b < 10 ? '0' + b : b)
  }

  /**
   * @param {date|moment} start The start date
   * @param {date|moment} end The end date
   * @param {string} type The range type. eg: 'days', 'hours' etc
   */
  getRange(startDate, endDate, type) {
    let fromDate = Moment(startDate)
    let toDate = Moment(endDate)
    let diff = toDate.diff(fromDate, type)
    let range = []
    for (let i = 0; i < diff; i++) {
      range.push(Moment(startDate).add(i, type))
    }
    return range
  }

  /**
   * Convert the time to `HH:mm` format
   * @param {string} e The time in available format: `HHmm`, `HH,mm`, `HH:mm`, `HH.mm`
   * @returns {string}
   */
  fixHHmmTime(e) {
    let timeStr = String(e),
      arrTime = timeStr.split(/[:,.]/),
      hh = arrTime[0],
      mm = arrTime[1]

    // Check user enter time without separator ':'
    if (hh.length == 4) {
      mm = hh.slice(-2)
      hh = hh.slice(0, 2)
    }

    mm = this.pad(mm ?? 0)
    hh = this.pad(hh ?? 0)

    // Check valid integer hours and minutes
    if (!this.HOURS_ARR.includes(hh)) hh = Moment().hours()
    if (!this.MINUTES_ARR.includes(mm)) mm = Moment().minutes()

    return Moment(`${hh}:${mm}`, 'HH:mm').clone()
  }

  /**
   * Get a valid input time value
   * @param {String} time - The input time value
   * @returns {String|null} - Valid string of the time or null
   */
  getValidHHmmTime(time) {
    return time
      ? this.fixHHmmTime(time)
      : null;
  }
}

export default new UtilsTime()
