import Company from '@/models/Company'
import { parse } from 'date-fns'
import Project from '@/models/Project'
import { IMPORT_STATUS } from '@/constants'
import Checklist from '@/models/Checklist'
import ChecklistItem from '@/models/ChecklistItem'

const IMPORT_STATUS_ERROR = [
  IMPORT_STATUS.ERROR_PROJECT_NAME_REQUIRED,
  IMPORT_STATUS.ERROR_PROJECT_RECORD_EXIST,
  IMPORT_STATUS.ERROR_WRONG_DATETIME,
  IMPORT_STATUS.ERROR_CANT_CREATE,
  IMPORT_STATUS.ERROR_UPDATE,
  IMPORT_STATUS.ERROR_TEMPLATE_NOT_FOUND,
  IMPORT_STATUS.ERROR_FORBIDDEN_UPDATE_TEMPLATE,
  IMPORT_STATUS.ERROR_TEMPLATES_DISABLED,
  IMPORT_STATUS.ERROR_TASK_PROJECT_NOT_FOUND,
  IMPORT_STATUS.ERROR_TASK_EXIST,
];
export class ImportFileResultsModel {
  constructor(data) {
    this.id = data.id
    this.type = data.type
    this.projectName = data.project_name
    this.projectNumber = data.project_number
    this.projectId = data.project_id
    this.project = data.project ? new Project(data.project) : null

    this.modelId = data.model_id ?? null;
    this.modelType = data.model_type ?? null;

    this.companyId = data.company_id
    this.company = data.company ? new Company(data.company) : null

    this.fileId = data.file_id
    this.description = data.description
    this.createdBy = data.created_by
    this.location = data.location

    this.checklistId = data.checklist_id
    this.checklistName = data.checklist_name
    this.checklistItemId = data.checklistitem_id
    this.checklistItemName = data.checklistitem_name

    this.registrationTypeId = data.registration_type_id
    this.registrationTypeName = data.registration_name
    this.registrationTypeGroupId = data.registration_type_group_id
    this.registrationTypeGroupName = data.registration_group_name
    this.unit = data.unit
    this.price = data.price
    this.registrationType = data.registration_type

    this.calculationsNotes = data.calculations_notes
    this.companyId = data.company_id

    this.contactPerson = data.contact_person
    this.contactPersonDescription = data.contact_person_description
    this.contactPersonEmail = data.contact_person_email
    this.contactPersonPhone = data.contact_person_phone

    this.createdAt = parse(data.created_at, 'yyyy-MM-dd HH:mm:ss', new Date())
    this.createdAtUtc = data.created_at_utc

    this.startAt = data.start_at
    this.startAtUtc = data.start_at_utc
    this.updatedAt = data.updated_at
    this.updatedAtUtc = data.updated_at_utc
    this.endAt = data.end_at
    this.endAtUtc = data.end_at_utc

    this.currentRecord = data.current_record
    this.totalRecords = data.total_records

    this.status = data.status
    this.statusMessage = data.status_message
    this.isError = IMPORT_STATUS_ERROR.includes(data.status)
  }
}

export default {
  ImportFileResultsModel
}