import Model from '@/models/Model'

class LabelModel extends Model {
  constructor(data) {
    super(data)

    this.id = data.id
    this.name = data.name
    this.color = data.color
  }
}

export default LabelModel
