import Provider from '@provider'
import { MessageModel } from '@/models'
import helpers from '@/helpers'

export default {
  getChatMessages: ({ chatId, offset }) => {
    if (!chatId) {
      debug('[-] Provider getChatMessages: chatId required!')
      return
    }

    return new Promise((resolve, reject) => {
      Provider.api
        .get(`/messages/${chatId}`, {
          params: {
            offset,
          },
        })
        .then(response => resolve(response.data.map(e => new MessageModel(e))))
        .catch(reject)
    })
  },

  addMessage: ({ chatId, type, message, clientMsgId, replyToId }) => {
    if (!chatId) {
      return Promise.reject('Chat id is undefined')
    }

    return new Promise((resolve, reject) => {
      Provider.api
        .post(`/messages/${chatId}`, {
          type,
          message,
          client_msg_id: clientMsgId,
          reply_to_id: replyToId,
        })
        .then(response => resolve(new MessageModel(response.data)))
        .catch(reject)
    })
  },

  addBulkMessage: ({ chatIds, type, message, clientMsgId }) => {
    if (!chatIds?.length) {
      return Promise.reject('Chat ids are required')
    }

    const data = helpers.createForm({
      type,
      message,
      chat_ids: chatIds,
      client_msg_id: clientMsgId,
    });

    return new Promise((resolve, reject) => {
      Provider.api
        .post(`/messages/mass`, data)
        .then(response => resolve(response.data.map(e => new MessageModel(e))))
        .catch(reject)
    })
  },

  deleteMessage: messageId => {
    return new Promise((resolve, reject) => {
      Provider.api
        .delete(`/messages/${messageId}`)
        .then(response => resolve(response.data))
        .catch(reject)
    })
  },
}
