import * as TYPES from '@/store/types';

// initial state
const state = {
  selected: [],
  selectedIds: new Set(),
  showPanel: false,
  showPanelPermanently: false,
};

const getters = {
  hasSelected: state => checklistId => state.selectedIds.has(checklistId),
};

// actions
const actions = {
  updateShowPanelPermanently: ({ commit }, value) => {
    commit(TYPES.CHECKLISTS_OPTIONS.SET_SHOW_PANEL_PERMANENTLY, value);
  },

  closePanel: ({ commit }) => {
    commit(TYPES.CHECKLISTS_OPTIONS.CLEAR_ITEMS);
    commit(TYPES.CHECKLISTS_OPTIONS.TOGGLE_PANEL, false);
  },

  toggleItems: ({ commit }, { checked, items }) => {
    commit(TYPES.CHECKLISTS_OPTIONS.TOGGLE_ALL_ITEMS, { checked, items });
  },

  updateCheckedItems: ({ commit }, payload) => {
    commit(TYPES.CHECKLISTS_OPTIONS.UPDATE_ITEM, payload);
  },
};
// mutations
const mutations = {
  [TYPES.CHECKLISTS_OPTIONS.SET_SHOW_PANEL_PERMANENTLY](state, value) {
    state.showPanelPermanently = value;
  },

  [TYPES.CHECKLISTS_OPTIONS.CLEAR_ITEMS](state) {
    state.selected = [];
    state.selectedIds = new Set();
  },

  [TYPES.CHECKLISTS_OPTIONS.TOGGLE_PANEL](state, value) {
    state.showPanel = value;
  },

  [TYPES.CHECKLISTS_OPTIONS.TOGGLE_ALL_ITEMS](state, { checked, items }) {
    if (checked) {
      state.selected = [...items];
      state.selectedIds = new Set(items.map(i => i.id));
    } else {
      state.selected = [];
      state.selectedIds = new Set();
    }
  },

  [TYPES.CHECKLISTS_OPTIONS.UPDATE_ITEM](state, { id, items, checked }) {
    if (checked) {
      state.showPanel = true;

      state.selected.push({ id, items });
      state.selectedIds = new Set([...state.selectedIds, id]);
    } else {
      state.selected = state.selected.filter(i => i.id !== id);

      const newIds = new Set([...state.selectedIds]);
      newIds.delete(id);
      state.selectedIds = newIds;

      if (!state.selected.length) {
        state.showPanel = false;
      }
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
