import Model from '@/models/Model'
import UserModel from '@/models/User'
import MediaFileModel from '@/models/MediaFile'

export const messageTypes = {
  TEXT: 'msg',
  MEDIA: 'media',
  JOIN: 'join',
  LEAVE: 'leave',
  NOTIFICATION: 'notification'
}

class MessageModel extends Model {
  constructor(data = {}) {
    super(data)

    this.id = Number(data.id) || null
    this.chatId = Number(data.chat_id) || null
    this.projectId = Number(data.project_id) || null
    this.text = data.text || ''

    this.user = data.user ? new UserModel(data.user) : null
    this.deletedBy = data.deleted_by ? new UserModel(data.deleted_by) : null
    this.userId = data.user_id
    this.media = data.media ? new MediaFileModel(data.media) : null

    this.clientMsgId = data.client_msg_id
    this.replyToId = data.reply_to_id

    this.replyToIdMessage = data.reply_to_id_message
      ? new MessageModel(data.reply_to_id_message)
      : undefined

    this.isText = data.type === messageTypes.TEXT
    this.isMedia = data.type === messageTypes.MEDIA
    this.isJoin = data.type === messageTypes.JOIN
    this.isLeave = data.type === messageTypes.LEAVE
    this.isNotification = data.type === messageTypes.NOTIFICATION
    this.type = data.type

    this.createdAt = this.fixData(data.created_at_utc, Date.now())
    this.createdAtM = this.toMomentDate(data.created_at_utc)
    this.canDeleteTillTime = this.toMomentDate(data.can_delete_till_time_utc)

    const _joinLeaveUser = data.join_leave_user
      ? new UserModel(data.join_leave_user)
      : null
    if ((this.isJoin || this.isLeave) && _joinLeaveUser) {
      this.user = _joinLeaveUser
    }
    if (this.isNotification) {
      this.notificationData = this.text ?? {}
      this.notificationData.checklist_url = ""

      const { updated_end_at, updated_start_at, end_at, start_at } = this.notificationData
      this.notificationData.human_updated_start_at = this.messageNotificationToMomentDate(updated_start_at)
      this.notificationData.human_updated_end_at = this.messageNotificationToMomentDate(updated_end_at)
      this.notificationData.human_start_at = this.messageNotificationToMomentDate(start_at)
      this.notificationData.human_end_at = this.messageNotificationToMomentDate(end_at)
    }
    this.flagDeleting = false
    this.flagFailSend = false
  }
}

export default MessageModel
