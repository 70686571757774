import DraggEmitter from './emitter'

class DraggList {
  constructor({ el, id, namespace, options }) {
    this.el = el
    this.id = id
    this.namespace = namespace
    this.options = options

    this.scroll = el.getElementsByClassName('dragg-scroll')[0]

    el.setAttribute('data-dragg-list-namespace', namespace)
    el.setAttribute('data-dragg-list', id)
    el.classList.add('dragg-list')
  }

  /**
   *
   * @param {Object} draggItem
   */
  handleDrop(draggItem) {
    let position = 1,
      // Get shadow element from list
      draggShadow = this.el.getElementsByClassName('dragg-shadow')[0]

    if (draggShadow) {
      let parent = draggShadow.parentNode
      const indexDrgShadow = Array.prototype.indexOf.call(
        parent.children,
        draggShadow
      )

      position = indexDrgShadow + 1

      if (draggItem.parentId == this.id) {
        const indexDrgI = Array.prototype.indexOf.call(
          parent.children,
          draggItem.el
        )

        if (indexDrgI < indexDrgShadow) --position
      }
    }

    DraggEmitter.emit(`dragg:${this.namespace}`, {
      id: draggItem.id,
      oldParent: draggItem.parentId,
      newParent: this.id,
      position,
    })

    this.unMark()
  }

  handleMove(draggItem) {
    // if(draggItem.el == this.el) return

    this.clearShadow()
    this.el.classList.add('move')

    const childrens = [...this.el.querySelectorAll('[dragg-elem]')]
    let nodeOnDragg = null

    for (let i = 0; i < childrens.length; i++) {
      let node = childrens[i]

      // TODO: Research
      if (node.getAttribute('data-dragg-item-namespace') != draggItem.namespace)
        continue

      if (node !== draggItem.el && this.isMuseCollide(node)) {
        const rect = node.getBoundingClientRect(),
          before =
            this.options && this.options.orientation == 'horizontal'
              ? event.clientX - rect.left < rect.width / 2
              : event.clientY - rect.top < rect.height / 2

        nodeOnDragg = node

        DraggEmitter.emit('insert-dragg-shadow', {
          referenceNode: node,
          before,
          height: draggItem.el.offsetHeight,
          width: draggItem.el.offsetWidth,
          id: draggItem.el.getAttribute('data-dragg-item'),
        })

        break
      }
    }

    if (!nodeOnDragg && this.scroll) {
      DraggEmitter.emit('insert-dragg-shadow', {
        parent: this.scroll,
        height: draggItem.el.offsetHeight,
        width: draggItem.el.offsetWidth,
        id: draggItem.el.getAttribute('data-dragg-item'),
      })
    }
  }

  unMark() {
    this.el.classList.remove('move')
    this.clearShadow()
  }

  clearShadow() {
    let paras = this.el.getElementsByClassName('dragg-shadow')
    for (let i = 0; i < paras.length; i++) {
      paras[i].parentNode.removeChild(paras[i])
    }
  }

  isMuseCollide(element) {
    const rect = element.getBoundingClientRect(),
      x = event.clientX,
      y = event.clientY

    return !(
      y < rect.top ||
      y > rect.top + rect.height ||
      x < rect.left ||
      x > rect.left + rect.width
    )
  }
}

export default DraggList
