import Model from '@/models/Model'
import UserModel from '@/models/User'

const EVENTS = {
  updated: 'updated',
  created: 'created',
  checked: 'checked',
  unchecked: 'unchecked',
}

class ChecklistItemLogModel extends Model {
  constructor(data) {
    super(data)

    this.checklistItemId = Number(data.checklist_item_id)
    this.user = data.user ? new UserModel(data.user) : null
    this.event = EVENTS[data.event]

    this.updatedAtM = this.toMomentDate(data.updated_at_utc)
    this.createdAtM = this.toMomentDate(data.created_at_utc)
  }
}

export default ChecklistItemLogModel
