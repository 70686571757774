import DraggEmitter from './emitter'

DraggEmitter.on('clear-dragg-shadow', () => {
  let paras = document.getElementsByClassName('dragg-shadow')
  for (let i = 0; i < paras.length; i++) {
    paras[i].parentNode.removeChild(paras[i])
  }
})

DraggEmitter.on(
  'insert-dragg-shadow',
  ({ referenceNode, before, height, width, parent, id }) => {
    let shadow = document.createElement('div')
    shadow.classList.add('dragg-shadow')
    shadow.setAttribute('data-dragg-shadow', id)
    shadow.style.height = `${height}px`

    shadow.style.minWidth = `${width}px`
    shadow.style.maxWidth = `${width}px`

    if (parent) {
      parent.appendChild(shadow)
    } else if (before) {
      referenceNode.parentNode.insertBefore(shadow, referenceNode)
    } else {
      referenceNode.parentNode.insertBefore(shadow, referenceNode.nextSibling)
    }
  }
)
