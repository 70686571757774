import DraggEmitter from './emitter';
import DraggItem from './draggItem';
import DraggList from './draggList';
import DraggScroll from './draggScroll';
import Store from './store';

import './dragShadow';

window.st = Store;

export const installDraggPlugin = app => {
  app.directive('dragg-item', {
    beforeMount: function (el, binding) {
      if (!binding.value) return null;

      const {
        id,
        parentId,
        namespace,
        disabled = false,
        params = { preventClick: false },
        ignoreElementSelector = null,
      } = JSON.parse(binding.value);

      if (id && !disabled) {
        // TODO: Research
        setTimeout(() => {
          Store.draggItems.set(
            id,
            new DraggItem({
              el,
              id,
              parentId,
              namespace,
              params,
              ignoreElementSelector,
            })
          );
        });
      }
    },

    unmounted: function (el) {
      const id = el.getAttribute('data-dragg-item');
      Store.draggItems.delete(id);
    },
  });

  app.directive('dragg-scroll', {
    beforeMount: function (el, binding) {
      if (!binding.value) return null;

      const { id, namespace = null } = JSON.parse(binding.value);

      if (id) {
        Store.draggScroll.set(id, new DraggScroll({ el, id, namespace }));
      }
    },

    unmounted: function (el, binding) {
      const { id } = JSON.parse(binding.value);

      Store.draggScroll.delete(id);
    },
  });

  app.directive('dragg-list', {
    beforeMount: function (el, binding) {
      if (!binding.value) return null;

      const { id, namespace, options } = JSON.parse(binding.value);

      if (id) {
        // TODO: Research
        setTimeout(() => {
          Store.draggList.set(id, new DraggList({ el, id, namespace, options }));
        }, 100);
      }
    },

    unmounted: function (el) {
      const id = el.getAttribute('data-dragg-list');
      Store.draggList.delete(id);
    },
  });

  app.config.globalProperties.$dragg = {
    emitter: DraggEmitter,
  };
};
