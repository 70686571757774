const state = {
  modals: [],
}

const getters = {
  listModals: state =>
    [...state.modals].sort((a, b) => a.updateAt - b.updateAt),

  handle: state =>
    state.modals.reduce((out, item) => {
      out[item.name] = item
      return out
    }, {}),
}

const actions = {
  closeModal({ commit }, name) {
    commit('CLOSE_MODAL', name)
  },

  openModal({ commit }, { name, params }) {
    commit('OPEN_MODAL', { name, params })
  },
}

const mutations = {
  OPEN_MODAL(state, { name, params = [] }) {
    const findModal = name => {
      let index = state.modals.findIndex(e => e.name === name),
        item = { ...state.modals[index] }

      return { index, item }
    }

    let e = findModal(name)

    if (e.index < 0)
      e = {
        index: state.modals.length,
        item: { name },
      }

    e.item.show = true
    e.item.params = params
    e.item.updateAt = Date.now()

    state.modals[e.index] = e.item;
  },

  CLOSE_MODAL(state, name) {
    const index = state.modals.findIndex(e => e.name === name)
    if (index > -1) {
      state.modals.splice(index, 1);
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
