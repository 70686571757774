import QuillMention from 'quill-mention';

import { getMentionCharIndex, hasValidMentionCharIndex, hasValidChars } from './utils.js';

export class QuillMentionModule extends QuillMention {
  constructor(quill, options) {
    super(quill, options);

    this.searchText = null;
    this.currentMentionChar = null;

    this.onOpenPopup = options.onOpenPopup;
    this.onClosePopup = options.onClosePopup;
    this.onSelectItem = options.onSelectItem;
    this.onUpKeydown = options.onUpKeydown;
    this.onDownKeydown = options.onDownKeydown;
    this.onEscapeKeydown = options.onEscapeKeydown;
  }

  selectHandler() {
    return this.onSelectItem?.() ?? true;
  }

  escapeHandler() {
    return this.onEscapeKeydown?.() ?? true;
  }

  upHandler() {
    return this.onUpKeydown?.() ?? true;
  }

  downHandler() {
    return this.onDownKeydown?.() ?? true;
  }

  onSomethingChange() {
    const range = this.quill.getSelection();
    if (range == null) return;

    this.cursorPos = range.index;
    const textBeforeCursor = this.getTextBeforeCursor();
    const { mentionChar, mentionCharIndex } = getMentionCharIndex(
      textBeforeCursor,
      this.options.mentionDenotationChars
    );

    if (
      hasValidMentionCharIndex(mentionCharIndex, textBeforeCursor, this.options.isolateCharacter)
    ) {
      const mentionCharPos = this.cursorPos - (textBeforeCursor.length - mentionCharIndex);
      this.mentionCharPos = mentionCharPos;

      const textAfter = textBeforeCursor.substring(mentionCharIndex + mentionChar.length);

      if (
        textAfter.length >= this.options.minChars &&
        hasValidChars(textAfter, this.getAllowedCharsRegex(mentionChar))
      ) {
        if (this.existingSourceExecutionToken) {
          this.existingSourceExecutionToken.abandoned = true;
        }

        this.existingSourceExecutionToken = {
          abandoned: false,
        };

        this.searchText = textAfter;
        this.currentMentionChar = mentionChar;

        this.showMentionList();

        return;
      }
    }

    this.searchText = null;
    this.currentMentionChar = null;

    if (this.existingSourceExecutionToken) {
      this.existingSourceExecutionToken.abandoned = true;
    }
    this.hideMentionList();
  }

  showMentionList() {
    this.onOpenPopup?.(this.searchText, this.currentMentionChar);
  }

  hideMentionList() {
    this.onClosePopup?.();
  }
}
