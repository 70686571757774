import axios from "axios";


class GooglePlaceAutocomplete {
  constructor(config) {
    this.config = config;
  }

  find(input) {
    return new Promise((resolve, reject) => {
      debug("URL", this.config.url);
      axios.get(this.config.url, {
        params: {
          input,
          language: "NL_nl",
          key: this.config.key
        }
      }).then(res => {
        resolve(res.data.predictions);
      }).catch(reject)
    });
  }
}

export default {
  install(app, config) {
    let googlePlaceAutocomplete = new GooglePlaceAutocomplete(config);
    app.config.globalProperties.$placeAutocomplete = googlePlaceAutocomplete;
  },
};
