export default {
  data() { return {
    loaded: false
  }},

  methods: {
    endLoader() {
      setTimeout(() => {
        this.loaded = false;
      }, 500);
    },

    startLoader() {
      this.loaded = true;
    }
  }
};
