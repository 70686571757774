import moment from 'moment'

class Model {
  constructor(data = {}) {
    this.canDelete = Boolean(data.can_delete)
    this.canEdit = Boolean(data.can_edit)
    this.canView = Boolean(data.can_view)

    this.isDeleted = Boolean(data.deleted_at)
  }

  fixData(data, fallback = null) {
    const momentDate = moment.utc(data);

    if (momentDate.isValid()) {
      return momentDate.toDate();
    }

    return fallback;
  }

  toMomentDate(value) {
    const momentDate = moment.utc(value);
    momentDate.local();
    return momentDate;
  }

  toMomentTime(value) {
    return value ? moment(value,'HH:mm') : value;
  }

  formatPrice(data) {
    return data.toFixed(2).replace('.', ',')
  }

  messageNotificationToMomentDate(value) {
    if (value) {
      value = moment(value);
      if (value.year() === 2001) {
        value.set('year', moment().year());
      }

      return value.format(value.isSame(moment(), 'year') ? 'DD MMMM HH:mm' : 'DD MMMM YYYY HH:mm');
    }

    return null;
  }

  /**
   * @param {unknown} maybeArray
   * @returns {number[]}
   */
  static numberArray(maybeArray) {
    return Array.isArray(maybeArray)
      ? maybeArray.reduce((numbers, value) => {
          const number = Number(value);
          if (!Number.isNaN(value)) numbers.push(number);
          return numbers;
        }, [])
      : [];
  }
}

export default Model
