import { mapActions } from 'vuex'

export default {
  methods: {
    ...mapActions('Projects', ['updatePosition']),

    ...mapActions('CompanyProjectStatus', ['updatePositionStatus']),

    ...mapActions('Checklists', [
      'updatePositionChecklistItem',
    ]),
  },

  mounted() {
    this.$dragg.emitter.on(
      'dragg:dragg-checklist-item',
      ({ id, position, newParent, oldParent }) => {
        debug(`[+] Dragg checklist item ${id} -> ${position}`)

        this.updatePositionChecklistItem({
          itemId: id,
          newChecklistId: newParent,
          oldChecklistId: oldParent,
          position: position,
        })
      }
    )

    this.$dragg.emitter.on('dragg:dragg-statuses', ({ id, position }) => {
      debug(`[+] Dragg project status ${id} -> ${position - 1}`)

      this.updatePositionStatus({
        id,
        position: position - 1,
      })
    })

    this.$dragg.emitter.on(
      'dragg:dragg-cards',
      ({ id, newParent, position, oldParent }) => {
        debug(`[+] Dragg project cards ${id} -> ${position}`)

        this.updatePosition({
          projectId: id,
          statusId: newParent,
          position: position,
        }).catch(error => {
          this.$notify.error(this.$utils.response(error))
        })
      }
    )

    this.$dragg.emitter.on(
      'dragg:dragg-group-items-planning-modal',
      ({ id, position }) => {
        debug(
          `[+] Dragg group items in planning modal ${id} -> ${position - 1}`
        )
        this.$events.emit('dragg:dragg-group-items-planning-modal', {
          id,
          position,
        })
      }
    )
  },
}
