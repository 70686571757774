import * as TYPES from '@/store/types'
import RegistrationTypesGroupsProvider from '@provider/registrationTypesGroups'

const state = {
  registrationTypesGroups: [],
  materialsFilterGroupId: null
}

const getters = {
  getRegistrationTypesGroupById: (state) => (registrationTypesGroupId) => {
    return state.registrationTypesGroups.find(({ id }) => id === registrationTypesGroupId)
  },
}

const actions = {
  addRegistrationTypesGroup({ commit }, { name, position }) {
    return new Promise((resolve, reject) => {
      RegistrationTypesGroupsProvider.addRegistrationTypesGroup({ name, position })
        .then(registrationType => {
          commit(
            TYPES.REGISTRATION_TYPES_GROUPS.INIT_REGISTRATION_TYPES_GROUPS,
            registrationType
          )
          resolve(registrationType)
        })
        .catch(reject)
    })
  },

  getRegistrationTypesGroups({ commit }) {
    return new Promise((resolve, reject) => {
      RegistrationTypesGroupsProvider.getRegistrationTypesGroups()
        .then(registrationTypes => {
          commit(
            TYPES.REGISTRATION_TYPES_GROUPS.INIT_REGISTRATION_TYPES_GROUPS,
            registrationTypes
          )
          resolve(registrationTypes)
        })
        .catch(reject)
    })
  },

  updateRegistrationTypesGroup({ commit }, { id, name, position }) {
    return new Promise((resolve, reject) => {
      RegistrationTypesGroupsProvider.updateRegistrationTypesGroup({ id, name, position })
        .then(registrationType => {
          commit(TYPES.REGISTRATION_TYPES_GROUPS.DELETE_REGISTRATION_TYPES_GROUP, id)
          commit(
            TYPES.REGISTRATION_TYPES_GROUPS.INIT_REGISTRATION_TYPES_GROUPS,
            registrationType
          )
          resolve(registrationType)
        })
        .catch(reject)
    })
  },

  deleteRegistrationTypesGroup({ commit }, { id }) {
    return new Promise((resolve, reject) => {
      RegistrationTypesGroupsProvider.deleteRegistrationTypesGroup({ id })
        .then(() => {
          commit(TYPES.REGISTRATION_TYPES_GROUPS.DELETE_REGISTRATION_TYPES_GROUP, id)
          resolve()
        })
        .catch(reject)
    })
  },

  setMaterialsFilterGroupId({ commit }, { groupId }) {
    commit(TYPES.REGISTRATION_TYPES_GROUPS.SET_MATERIALS_FILTER_GROUP_ID, groupId)
  }
}

const mutations = {
  [TYPES.REGISTRATION_TYPES_GROUPS.INIT_REGISTRATION_TYPES_GROUPS](state, data) {
    let list = Array.isArray(data) ? data : [data],
      ids = list.map(e => e.id)

    state.registrationTypesGroups = [
      ...state.registrationTypesGroups.filter(e => !ids.includes(e.id)),
      ...list.filter(e => e.canView),
    ].sort((a, b) => a.name.localeCompare(b.name))
  },

  [TYPES.REGISTRATION_TYPES_GROUPS.DELETE_REGISTRATION_TYPES_GROUP](state, id) {
    state.registrationTypesGroups = state.registrationTypesGroups.filter(e => e.id !== id)
  },

  [TYPES.REGISTRATION_TYPES_GROUPS.SET_MATERIALS_FILTER_GROUP_ID](state, groupId) {
    state.materialsFilterGroupId = groupId
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}