import * as TYPES from '@/store/types'
import LocalStorageWrapper from '@/plugins/localStorageWrapper'

import PlanningResourceGroupsProvider from '@provider/planningResourcesGroups'

const KEYS = {
  HIDDEN_PLANNING_RESOURCE_GROUP: 'HIDDEN_PLANNING_RESOURCE_GROUP',
}

// initial state
const state = {
  groups: [],
  hiddenGroups: LocalStorageWrapper.getItem(
    KEYS.HIDDEN_PLANNING_RESOURCE_GROUP,
    {
      deff: [],
      parse: true,
    }
  ),
}

// getters
const getters = {
  planningGroups: state => state.groups,
  hiddenPlanningGroups: state => state.hiddenGroups,
  hiddenPlanningGroup: state => id => state.hiddenGroups.includes(id),
}

// actions
const actions = {
  toggleViewPlanningResourceGroup({ state, commit }, { id, value }) {
    commit(TYPES.PLANNING_RESOURCE_GROUPS.TOGGLE_VIEW_GROUP, { id, value })

    LocalStorageWrapper.setItem(
      KEYS.HIDDEN_PLANNING_RESOURCE_GROUP,
      state.hiddenGroups
    )
  },

  addPlanningResourceGroup({ commit }, form) {
    return new Promise((resolve, reject) => {
      PlanningResourceGroupsProvider.addGroup(form)
        .then(group => {
          commit(TYPES.PLANNING_RESOURCE_GROUPS.INIT_GROUPS, group)
          resolve(group)
        })
        .catch(reject)
    })
  },

  getPlanningResourceGroup({ commit }, { id }) {
    return new Promise((resolve, reject) => {
      PlanningResourceGroupsProvider.getGroup({ id })
        .then(group => {
          commit(TYPES.PLANNING_RESOURCE_GROUPS.INIT_GROUPS, group)
          resolve(group)
        })
        .catch(reject)
    })
  },

  getPlanningResourceGroups({ commit }) {
    return new Promise((resolve, reject) => {
      PlanningResourceGroupsProvider.getGroups()
        .then(groups => {
          commit(TYPES.PLANNING_RESOURCE_GROUPS.INIT_GROUPS, groups)
          resolve(groups)
        })
        .catch(reject)
    })
  },

  updatePlanningResourceGroup({ commit }, form) {
    return new Promise((resolve, reject) => {
      PlanningResourceGroupsProvider.updateGroup(form)
        .then(group => {
          commit(TYPES.PLANNING_RESOURCE_GROUPS.INIT_GROUPS, group)
          resolve(group)
        })
        .catch(reject)
    })
  },

  deletePlanningResourceGroup({ commit }, { id }) {
    return new Promise((resolve, reject) => {
      PlanningResourceGroupsProvider.deleteGroup({ id })
        .then(() => {
          commit(TYPES.PLANNING_RESOURCE_GROUPS.DELETE_GROUPS, id)
          resolve()
        })
        .catch(reject)
    })
  },
}

// mutations
const mutations = {
  [TYPES.PLANNING_RESOURCE_GROUPS.INIT_GROUPS](state, data) {
    let list = Array.isArray(data) ? data : [data],
      ids = list.map(e => e.id)

    state.groups = [...state.groups.filter(e => !ids.includes(e.id)), ...list]
  },

  [TYPES.PLANNING_RESOURCE_GROUPS.DELETE_GROUPS](state, data) {
    const ids = Array.isArray(data) ? data : [data]

    state.groups = [...state.groups.filter(e => !ids.includes(e.id))]
  },

  [TYPES.PLANNING_RESOURCE_GROUPS.TOGGLE_VIEW_GROUP](state, { id, value }) {
    if (value) state.hiddenGroups.push(id)
    else state.hiddenGroups = state.hiddenGroups.filter(e => e != id)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
