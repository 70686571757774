import { createRouter, createWebHistory } from 'vue-router';

import TitleBuilder from '@/plugins/titleBuilder'

import MetaPlugin from './plugins/meta'
import { MiddlewarePlugin } from './plugins/middleware'

import auth from '@/routes/auth'
import user from '@/routes/user'
import other from '@/routes/other'
import mobile from '@/routes/mobile'

// const originalPush = Router.prototype.push
// Router.prototype.push = function push(location, onResolve, onReject) {
//   return onResolve || onReject
//     ? originalPush.call(this, location, onResolve, onReject)
//     : originalPush.call(this, location).catch(err => err)
// }

let routes = [...auth, ...user, ...other, ...mobile]

const history = createWebHistory(process.env.BASE_URL);
const router = createRouter({
  history,
  routes,
});

router.beforeEach(MetaPlugin)
router.beforeEach((to, from, next) => MiddlewarePlugin(to, from, next, router));
router.$title = TitleBuilder

export default router
