import { chunk } from 'lodash';

export const iterateGroupActions = async (list, action, size, timeout = 0) => {
  const slices = chunk(list, size);

  for (let i = 0; i < slices.length; i++) {
    const slice = slices[i];

    await Promise.allSettled(slice.map(action));

    await new Promise(resolve => setTimeout(resolve, timeout));
  }
};
