import { nextTick } from 'vue';

const SHADOW_CLASS = 'virtual-scroll-shadow';

function updateShadow(el, vnode) {
  nextTick(() => {
    const shadowEl = el.querySelector(`.${SHADOW_CLASS}`),
      wrapperEl = el.querySelector('.vue-recycle-scroller__item-wrapper');

    if (!shadowEl || !wrapperEl) return;

    const height = Math.floor(parseFloat(window.getComputedStyle(wrapperEl).height));

    shadowEl.style.top = height + 'px';
    shadowEl.style.width = null;

    nextTick(() => {
      shadowEl.style.width = wrapperEl.scrollWidth + 'px';

      setTimeout(() => {
        shadowEl.style.width = wrapperEl.scrollWidth + 'px';
      });
    });
  });
}

const bindCellPointerHandlers = (/** @type {HTMLElement} */ element) => {
  const virtualListElement = element.closest('.vue-recycle-scroller');
  const shadowElement = virtualListElement.querySelector(`.${SHADOW_CLASS}`);
  const virtualListWrapperElement = virtualListElement.querySelector(
    '.vue-recycle-scroller__item-wrapper'
  );

  if (!virtualListElement || !virtualListWrapperElement || !shadowElement) {
    console.warn('[v-virtual-scroll-shadow-cell-hover] Not all elements exist');
    return;
  }

  const pointerenterHandler = () => {
    requestAnimationFrame(() => {
      if (virtualListElement.scrollHeight > virtualListWrapperElement.clientHeight) {
        shadowElement.style.height =
          virtualListElement.scrollHeight - virtualListWrapperElement.clientHeight + 'px';
      }
    });
  };

  const pointerleaveHandler = () => {
    shadowElement.style.height = null;
  };

  element.addEventListener('pointerenter', pointerenterHandler);
  element.addEventListener('pointerleave', pointerleaveHandler);

  return () => {
    element.removeEventListener('pointerenter', pointerenterHandler);
    element.removeEventListener('pointerleave', pointerleaveHandler);
  };
};

export const installVirtualScrollShadowDirective = app => {
  app.directive('virtual-scroll-shadow', {
    created(el, _, vnode) {
      const shadowEl = document.createElement('div');
      shadowEl.classList.add(SHADOW_CLASS);
      el.appendChild(shadowEl);

      updateShadow(el, vnode);
    },

    beforeUpdate(el, _, vnode) {
      updateShadow(el, vnode);
    },
  });

  app.directive('virtual-scroll-shadow-cell-hover', {
    mounted(el) {
      el.__vssch_cleanup = bindCellPointerHandlers(el);
    },

    unmounted(el) {
      el.__vssch_cleanup?.();
    },
  });
};
