import Model from './Model.js';

export class PdfImportTemplate {
  constructor(data) {
    this.id = data.id;
    this.file = data.file;
    this.entity = data.entity;

    this.createdAt = data.created_at
      ? Model.prototype.toMomentDate.call(this, data.created_at).toDate()
      : null;

    this.updatedAt = data.updated_at
      ? Model.prototype.toMomentDate.call(this, data.updated_at).toDate()
      : null;
  }
}

export default PdfImportTemplate;
