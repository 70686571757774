import Provider from '@provider'
import UsersProvider from '@provider/user'
import PingProvider from '@provider/ping'
import * as TYPES from '@/store/types'

// initial state
const state = {
  user: null,
  auth: !!Provider.getToken(),
  users: [],
  permissions: {}
}

// getters
const getters = {
  me: state => state.user,
  auth: state => state.auth,
  users: state => state.users,
  permissions: state => state.user?.permissions,
}

// actions
const actions = {
  ping() {
    return PingProvider.ping()
  },

  initIntercom: ({ state }, { company }) => {
    window.app.config.globalProperties.$intercom.boot({
      userId: state.user.id,
      name: state.user.fullName,
      'First name': state.user.firstName,
      email: state.user.email,
      company,
    })
  },

  uploadAvatar: ({ state, commit }, form) => {
    return new Promise((resolve, reject) => {
      UsersProvider.uploadAvatar({
        userId: state.user.id,
        form,
      })
        .then(user => {
          commit(TYPES.USERS.INIT_USER, user)
          resolve(user)
        })
        .catch(reject)
    })
  },

  register: ({ commit }, form) => {
    return new Promise((resolve, reject) => {
      UsersProvider.register(form)
        .then(({ tocken }) => {
          if (tocken) {
            commit(TYPES.USERS.UPDATE_AUTH)
          }

          resolve({ tocken })
        })
        .catch(reject)
    })
  },

  login: ({ commit }, form) => {
    return new Promise((resolve, reject) => {
      UsersProvider.login(form)
        .then(res => {
          resolve(res)
          commit(TYPES.USERS.UPDATE_AUTH)
        })
        .catch(reject)
    })
  },

  confirmEmail: ({ commit }, hash) => {
    return new Promise((resolve, reject) => {
      UsersProvider.confirmEmail(hash)
        .then(res => {
          commit(TYPES.USERS.UPDATE_AUTH)
          resolve(res)
        })
        .catch(reject)
    })
  },

  forgotPassword: ({}, { email }) => {
    return UsersProvider.forgotPassword({ email })
  },

  resetPassword: ({ commit }, form) => {
    return new Promise((resolve, reject) => {
      UsersProvider.resetPassword(form)
        .then(res => {
          commit(TYPES.USERS.UPDATE_AUTH)
          resolve(res)
        })
        .catch(reject)
    })
  },

  deleteUser: ({}, id) => {
    return UsersProvider.deleteUser(id)
  },

  clearUser: ({ commit }, id) => {
    return UsersProvider.clearUser(id)
      .then((updatedUser) => {
        commit(TYPES.USERS.INIT_USER, updatedUser)
      })
  },

  signOut: ({ commit }) => {
    return new Promise((resolve, reject) => {
      UsersProvider.signOut()
        .then(() => {
          commit(TYPES.USERS.UPDATE_AUTH)
          resolve()
        })
        .catch(reject)
    })
  },

  getCurrentUser: ({ commit }) => {
    return new Promise((resolve, reject) => {
      UsersProvider.getCurrentUser()
        .then(user => {
          debug('[+] Get current user')

          commit(TYPES.USERS.INIT_USER, user)
          resolve(user)
        })
        .catch(reject)
    })
  },

  getUsers: ({ commit }) => {
    UsersProvider.getUsers().then(users => {
      commit(TYPES.USERS.INIT_USERS, users)
    })
  },

  getContacts: ({}, { search }) => {
    return UsersProvider.getContacts({ search })
  },

  updateAccount: ({ state, commit }, form) => {
    return new Promise((resolve, reject) => {
      UsersProvider.updateUsers(state.user.id, form)
        .then(updated => {
          commit(TYPES.USERS.INIT_USER, updated)
          resolve()
        })
        .catch(error => reject(error))
    })
  },

  updateAnotherAccount: ({ state, commit }, form) => {
    return new Promise((resolve, reject) => {
      UsersProvider.updateUsers(form.id, form)
        .then(updated => {
          store
            .dispatch('Companies/getCompanyMembers', {
              companyId: state.user.company_id,
            })
            .then(members => {
              // commit(TYPES.USERS.INIT_ME_CONPANY_MEMBERS, members);
            })
          resolve()
        })
        .catch(error => reject(error))
    })
  },

  userRegisterInvitation: ({ commit }, { form, hash, referral }) => {
    return new Promise((resolve, reject) => {
      UsersProvider.userRegisterInvitation({ form, hash, referral })
        .then(res => {
          commit(TYPES.USERS.UPDATE_AUTH)
          resolve(res)
        })
        .catch(reject)
    })
  },

  getOutlookOauthUrl: (_, { email }) => {
    return UsersProvider.getOutlookOauthUrl({ email });
  },

  getGoogleOauthUrl: (_, { email }) => {
    return UsersProvider.getGoogleOauthUrl({ email });
  },

  getUpvotyToken: () => {
    return new Promise((resolve, reject) => {
      UsersProvider.getUpvotyToken()
        .then(token => {
          console.log('Module', token);
          resolve(token.data);
        })
        .catch(reject);
    });
  },
}

// mutations
const mutations = {
  [TYPES.USERS.UPDATE_AUTH](state) {
    state.auth = !!Provider.getToken()
  },

  [TYPES.USERS.INIT_USER](state, user) {
    state.user = user
    state.permissions = user.permissions
  },

  [TYPES.USERS.INIT_USERS](state, users) {
    state.users = users
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
