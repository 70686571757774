import Model from '@/models/Model'
import UserModel from '@/models/User'

class PublicDescriptionHistoryModel extends Model {
  constructor(data) {
    super(data)

    this.id = Number(data.id)
    this.projectId = Number(data.project_id)

    this.text = data.public_description
    this.user = data.user ? new UserModel(data.user) : null

    this.updatedAt = this.toMomentDate(data.updated_at_utc)
    this.createdAt = this.toMomentDate(data.created_at_utc)
  }
}

export default PublicDescriptionHistoryModel
