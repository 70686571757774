import Quill from 'quill';

const
  Embed = Quill.import('blots/embed');


class QuillEmoji extends Embed {
  static blotName = 'emoji';
  static className = 'ql-emoji';
  static tagName = 'span';

  static create(emoji) {
    let node = super.create(emoji);
    node.setAttribute('contenteditable', true);
    node.setAttribute('data-name', emoji);

    let p = document.createElement("span");
    
    debug(emoji);
    node.innerHTML = emoji;

    node.appendChild(p);

    return node;
  }

  static value(node) {
    return node.getAttribute("data-name");
  }
}


Quill.register({"formats/emoji": QuillEmoji});