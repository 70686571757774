import Model from '@/models/Model'

class UserInvitedModel extends Model {
  constructor(data) {
    super(data)

    this.id = data.id
    this.chatId = data.chat_id
    this.projectId = data.project_id
    this.companyId = data.company_id
    this.email = data.email
    this.usedAt = data.used_at
    this.roleId = data.role_id
    this.token = data.token
    this.isGuest = data.is_guest

    this.firstName = data.first_name || ''
    this.middleName = data.middle_name || ''
    this.lastName = data.last_name || ''
    this.fullName = `${this.firstName} ${this.lastName}`

    this.isInvited = true
  }
}

export default UserInvitedModel
