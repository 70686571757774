import { isFunction } from 'lodash';
const RESIZE_HANDLER_KEY = '__roHandler__';

let observer = null;

const createObserver = () => {
  return new ResizeObserver((entries) => {
    for (let entry of entries) {
      entry.target?.[RESIZE_HANDLER_KEY]?.(entry);
    }
  });
}

export const unobserve = (element) => {
  if (!observer) {
    console.warn('[helper][resize-observer] Resize observer does not exist');
    return;
  }

  delete element[RESIZE_HANDLER_KEY];
  observer.unobserve(element);
};

export const observe = (
  /** @type {HTMLElement} */ element,
  /** @type {(entry: ResizeObserverEntry): void} */ handler
) => {
  if (!isFunction(handler)) {
    console.error('[helper][resize-observer] Passed handler is not a function');
    return;
  }

  if (!observer) {
    observer = createObserver();
  }

  observer.observe(element);
  element[RESIZE_HANDLER_KEY] = handler;

  return () => {
    unobserve(element);
  };
};
