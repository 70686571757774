import { mapActions, mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['useNewLayout']),
    ...mapGetters('User', ['me', 'permissions']),
    canChangeAccess() {
      return this.permissions.project.canViewManagementInfo && this.$route.name == 'projectInfo';
    },
    hasMenuButton() {
      return (
        this.menuItems.length &&
        (this.canChangeAccess || this.checklist.items.length > 0 || this.checklist.canDelete)
      );
    },

    menuItems() {
      let list = []

      if (!this.useNewLayout && this.checklist.canEdit) {
        if (this.checklist.favourite) {
          list.push({
            placeholder: this.$t('checklists.contextMenu.removeAsFavourite'),
            callback: () => this.updateChecklistFavourite({
              id: this.checklist.id,
              favourite: false,
            }),
          })
        } else {
          list.push({
            placeholder: this.$t('checklists.contextMenu.addAsFavourite'),
            callback: () => this.updateChecklistFavourite({
              id: this.checklist.id,
              favourite: true,
            }),
          })
        }
      }

      if (this.$route.name !== 'teamChat' && this.me.isAdmin) {
        list.push(
          this.checklist.access
            ? {
                placeholder: this.$t('checklists.contextMenu.unlock'),
                callback: () => {
                  this.updateChecklistAccess({
                    ...this.checklist,
                    access: false,
                  })
                },
              }
            : {
                placeholder: this.$t('checklists.contextMenu.lock'),
                callback: () => {
                  this.updateChecklistAccess({
                    ...this.checklist,
                    access: true,
                  })
                },
              }
        )
      }

      if (this.checklist.permissions?.canExport && this.checklist.items.length > 0) {
        list = [
          ...list,
          ...[
            {
              placeholder: this.$t('checklists.contextMenu.downloadAllFiles'),
              callback: () => {
                this.openModal({
                  name: 'PrepareDownloadFile',
                  params: {
                    description: this.$t('modals.prepareDownloadFile.export'),
                    queryPromise: this.downloadChecklistFiles({
                      id: this.checklist.id,
                      fileName: `${this.checklist.name}.zip`,
                    }),
                  },
                })
              },
            },
            {
              placeholder: this.$t('checklists.contextMenu.downloadPDF'),
              callback: () => {
                this.openModal({
                  name: 'ChecklistExport',
                  params: {
                    id: this.checklist.id,
                  },
                })
              },
            },
          ],
        ]
      }

      if (this.checklist.canDelete) {
        list.push({
          placeholder: this.$t('checklists.contextMenu.remove'),
          callback: () => this.handleRemoveChecklist(),
          class: 'danger',
        });
      }

      return list;
    },
  },

  methods: {
    handleContextMenu(e) {
      this.openModal({
        name: 'ContextMenu',
        params: {
          scroll: 'page',
          el: e.target,
          position: 'top left',
          list: this.menuItems.slice(),
        },
      })
    },

    handleRemoveChecklist() {
      this.openModal({
        name: 'ApproveRemove',
        params: {
          title: this.$t('sidebar.checklists.removeChecklist.title'),
          description: this.$t(
            'sidebar.checklists.removeChecklist.description'
          ),
          button: this.$t('sidebar.checklists.removeChecklist.submit'),
          callback: () => {
            this.deleteChecklist({ id: this.checklist.id }).catch(error =>
              this.$notify.error(this.$utils.response(error))
            )
          },
          el: this.$refs.refApproveRemoveModal,
          scroll: 'page',
          top: true,
        },
      })
    },

    ...mapActions('Checklists', [
      'downloadChecklistFiles',
      'deleteChecklist',
      'updateChecklistAccess',
      'updateChecklistFavourite',
    ]),
  },
}
