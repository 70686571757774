import axios from 'axios'
import moment from 'moment'
import lodash from 'lodash'
import TRANSACTION_ID from '../../plugins/transactionId'
import { BASE_URL } from '@/config'
import { getDevicePlatform } from '@/helpers/platform.js';

class API {
  constructor() {
    this.VERSION = '0.0.1'
    this.BASE_URL = BASE_URL
    this.TOKEN_KEY = 'br_token'

    this.api = this.getInstance()
    window.AXIOS = this.api
  }

  getInstance() {
    let ax = axios.create({
      baseURL: this.BASE_URL,
      headers: {
        'Content-Type': 'application/json',
        'Woub-Client': 'desktop',
        'Woub-Platform': getDevicePlatform(),
        'Woub-Version': process.env.VUE_APP_VERSION,
        timezone: moment().utcOffset(),
        transaction_id: TRANSACTION_ID,
      },
    })

    // Set the AUTH token for any request
    ax.interceptors.request.use(
      config => {
        const token = window.localStorage.getItem(this.TOKEN_KEY)
        config.headers.Authorization = token ? `Bearer ${token}` : ''
        return config
      },
      error => Promise.reject(error)
    )

    ax.interceptors.response.use(
      response => {
        const newtoken = lodash.get(response, 'headers.authorization')
        if (newtoken) this.setBearer(newtoken)
        return response
      },
      error => {
        // Do something with response error
        if (error && error.response && error.response.status) {
          switch (error.response.status) {
            case 401:
              const ignoreAuthError = Boolean(error.response.config?.ignoreAuthError);
              if (ignoreAuthError) break;

              let code = error.response?.data.error

              app.config.globalProperties.$events.emit('clearIntervals')
              window.localStorage.clear()
              window.location = '/auth?error=401' + (code ? `&code=${ code }` : '')
              break
          }

          return Promise.reject(error.response)
        } else {
          switch (error.constructor.name) {
            case 'Cancel':
              debug('Axios cancel query')
              break

            default:
              //app.$notify.error(app.$utils.response("error"));
              break
          }
        }

        return Promise.reject(error)
      }
    )

    return ax
  }

  getToken() {
    return window.localStorage.getItem(this.TOKEN_KEY)
  }

  setBearer(newToken) {
    window.localStorage.setItem(this.TOKEN_KEY, newToken)
    this.api = this.getInstance()
  }
}

//const api = new API();

export default new API()
