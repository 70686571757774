import Provider from "@provider";

export default {
  ping: () => {
    return new Promise((resolve, reject) => {
      Provider.api.get("/ping")
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
    });
  }
}