class TYPE_S {
  constructor(obj) {
    for (const key in obj) {
      this[key] = obj[key]
    }
  }

  get MOD() {
    let res = {}
    for (let key in this) {
      if (key == 'MOD') continue
      res[key] = `${this.MODULE}/${this[key]}`
    }

    return res
  }
}

export const PROJECTS = new TYPE_S({
  MODULE: 'Projects',

  getProjectById: 'getProjectById',
  hasUnreadMessage: 'hasUnreadMessage',
  projects: 'projects',
  allProjects: 'allProjects',
  loadedProjects: 'loadedProjects'
})