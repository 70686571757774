export const constructNumber = (string) => {
  if (typeof string !== 'string') return null;

  const parts = string.split(/[,.]/g).filter(p => Boolean(p));
  const fractional = parts.pop();

  const number = parts.length
    ? `${parts.join('')}.${fractional}`
    : fractional;

  return Number(number);
}

export const extractNumberValue = (value) => {
  const filteredValue = value.replace(/[^\d-.,]/g, "");
  const isValidFormat = /^(-)?([0-9]+[.,]?)*$/.test(filteredValue);
  if (!isValidFormat) {
    return null;
  }

  const string = filteredValue === '-' ? '-0' : filteredValue;
  return constructNumber(string);
}

export const extractNumberValueFromFormula = (value) => {
  if (!value) return null;

  const isNegative = /^-/.test(value);
  let nextValue = value.replace(/[^\d,.]+/g, '');

  if (isNegative) nextValue = `-${nextValue}`;
  return constructNumber(nextValue);
}

export const checkNumberValidity = number => /([\d]+|[\.]|[\,])/.test(number);
