import DraggEmitter from './emitter'

const SCROLL_SPEED = 10

class DraggScroll {
  constructor({ el, id, namespace }) {
    this.el = el
    this.id = id
    this.namespace = namespace

    this.interval = null

    el.setAttribute('data-dragg-scroll', id)
    el.classList.add('dragg-scroll')

    const clearScrollInterval = () => {
      if (this.interval) {
        clearInterval(this.interval)
        this.interval = null
      }
    }

    const startInterval = () => {
      if (this.interval) {
        return null
      }

      this.interval = setInterval(() => {
        let scrollElRect = this.el.getBoundingClientRect(),
          yOfScroll = window.mouse.y - scrollElRect.top,
          xOfScroll = window.mouse.x - scrollElRect.left

        if (this.el.scrollHeight > this.el.clientHeight) {
          if (yOfScroll > this.el.clientHeight - 100) {
            this.el.scrollTop += SCROLL_SPEED
          } else if (yOfScroll < 100) {
            this.el.scrollTop -= SCROLL_SPEED
          }
        } else if (this.el.scrollWidth > this.el.clientWidth) {
          if (xOfScroll > this.el.clientWidth - 100) {
            this.el.scrollLeft += SCROLL_SPEED
          } else if (xOfScroll < 100) {
            this.el.scrollLeft -= SCROLL_SPEED
          }
        } else {
          clearScrollInterval()
        }
      }, 20)
    }

    DraggEmitter.on('move', (draggingItem) => {
      if (
        this.isMuseCollide(this.el)
        && (!namespace || namespace === draggingItem.namespace)
      ) {
        startInterval()
      } else {
        clearScrollInterval()
      }
    })

    DraggEmitter.on('drop', () => {
      clearScrollInterval()
    })
  }

  isMuseCollide(element) {
    const rect = element.getBoundingClientRect(),
      x = event.clientX,
      y = event.clientY

    return !(
      y < rect.top ||
      y > rect.top + rect.height ||
      x < rect.left ||
      x > rect.left + rect.width
    )
  }

  throttled(delay, fn) {
    let lastCall = 0
    return function(...args) {
      const now = new Date().getTime()
      if (now - lastCall < delay) {
        return
      }
      lastCall = now
      return fn(...args)
    }
  }
}

export default DraggScroll
