import Model from '@/models/Model'

export class RegistrationTypesGroupModel extends Model {
  constructor(data) {
    super(data);

    this.id = Number(data.id)

    this.name = data.name
    this.position = data.position > 0 ? data.position : 1
  }
}

export default RegistrationTypesGroupModel
