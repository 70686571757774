import Model from '@/models/Model'

class ProjectStatusModel extends Model {
  constructor(data) {
    super(data)

    this.canMove = data.can_move

    for (let key in data) {
      this[key] = data[key]
    }

    this.id = data.id
    this.name = data.name
    this.companyId = data.company_id

    this.updatedAt = this.fixData(data.updated_at_utc)

    this.isDeleted = Boolean(data.deleted_at_utc)

    this.position = Number(data.position)
  }
}

export default ProjectStatusModel
