import { DATE_PERIODS } from '@/constants.js';
import { isUndefined } from 'lodash';

export const throwPeriodError = (values = Object.values(DATE_PERIODS)) => {
  throw new Error(
    `Passed period must be one from the values: ${values}`
  );
}

export const throwPeriodErrorIfValueIsInvalid = (value, periods) => {
  if (isUndefined(value)) {
    throwPeriodError(periods);
  }
}
