import { CancelToken, isCancel } from 'axios'
import * as TYPES from '@/store/types'
import PlanningResourcePlanItemsProvider from '@provider/planningResourcePlanItems'
import Scheduler from '@/plugins/utils/scheduler'

/**
 * @typedef PlanningItemsPeriod
 * @property {import('moment').Moment} startAt
 * @property {import('moment').Moment} endAt
 */

/**
 * Get not filled period
 * @param {PlanningItemsPeriod} filledPeriod
 * @param {PlanningItemsPeriod} nextPeriod
 * @returns {PlanningItemsPeriod}
 */
const getUnfilledPeriod = (filledPeriod, nextPeriod) => {
  let { startAt, endAt } = nextPeriod;

  if (filledPeriod.startAt && filledPeriod.endAt) {
    if (filledPeriod.startAt.isBefore(startAt) && filledPeriod.endAt.isAfter(startAt) && filledPeriod.endAt.isBefore(endAt)) {
      startAt = filledPeriod.endAt.clone().add(1, 'day');
    } else if (filledPeriod.startAt.isBefore(endAt) && filledPeriod.endAt.isAfter(endAt) && filledPeriod.startAt.isAfter(startAt)) {
      endAt = state.startAt.clone().subtract(1, 'day');
    }
  }

  return { startAt, endAt };
}

// initial state
const state = {
  searchPlanItemId: null,
  planItems: [],
  startAt: null,
  endAt: null,
  loading: false,
  cancellation: null,
  lastUpdateRequests: {}
}

// getters
const getters = {
  planningItems: state => state.planItems,
  planningItemsByResourceIds: state => {
    const planningItems = {};

    state.planItems.forEach(e => {
      e.resources.forEach(r => {
        if (planningItems.hasOwnProperty(r.id)) planningItems[r.id].push(e)
        else planningItems[r.id] = [e]
      })
    });

    return planningItems;
  },
  getPlanningItemsByRange: state => ({ startAt, endAt }) =>
    state.planItems.filter(e => e.startAt >= startAt && e.endAt <= endAt),

  getPlanningItemByFilter: state => ({ startAt, endAt, resourceId }) =>
    state.planItems.find(e => {
      // Filter by resource id
      if (resourceId && !e.resources.find(f => f.id == resourceId)) return false

      // Filter by range dates
      if (
        startAt &&
        endAt &&
        (startAt.isBefore(e.startAt) || e.endAt.isBefore(endAt))
      )
        return false

      return true
    }),
}

// actions
const actions = {
  addPlanItem({ commit }, form) {
    return new Promise((resolve, reject) => {
      PlanningResourcePlanItemsProvider.addPlanItem(form)
        .then(resource => {
          commit(
            TYPES.PLANNING_RESOURCE_PLAN_ITEMS.SET_RESOURCE_PLAN_ITEM,
            resource
          )
          resolve(resource)
        })
        .catch(reject)
    })
  },

  getPlanItem({ commit }, { id }) {
    return new Promise((resolve, reject) => {
      PlanningResourcePlanItemsProvider.getPlanItem({ id })
        .then(resource => {
          commit(
            TYPES.PLANNING_RESOURCE_PLAN_ITEMS.SET_RESOURCE_PLAN_ITEM,
            resource
          )
          resolve(resource)
        })
        .catch(reject)
    })
  },

  getPlanItems({ state, commit }, form = {}) {
    let { startAt, endAt } = getUnfilledPeriod(state, form);
    startAt = startAt.format('YYYY-MM-DD');
    endAt = endAt.format('YYYY-MM-DD');

    if (state.cancellation) {
      state.cancellation();
    }

    return Scheduler.flushPromises().then(() => {
      const cancelSource = CancelToken.source();
      commit(TYPES.PLANNING_RESOURCE_PLAN_ITEMS.SET_CANCELLATION, cancelSource.cancel);
      commit(TYPES.PLANNING_RESOURCE_PLAN_ITEMS.SET_LOADING, true);

      return PlanningResourcePlanItemsProvider.getPlanItems({ startAt, endAt, cancelToken: cancelSource.token })
        .then(resources => {
          commit(TYPES.PLANNING_RESOURCE_PLAN_ITEMS.INIT_RESOURCE_PLAN_ITEMS, {
            data: resources,
            startAt: form.startAt,
            endAt: form.endAt,
          });
        })
        .catch((reason) => {
          if (!isCancel(reason)) throw reason;
        })
        .finally(() => {
          commit(TYPES.PLANNING_RESOURCE_PLAN_ITEMS.SET_LOADING, false);
        });
    });
  },

  updatePlanItem({ state, commit }, form) {
    const { id } = form;
    const currentRequest = Number(state.lastUpdateRequests[id] ?? 0) + 1;
    commit(TYPES.PLANNING_RESOURCE_PLAN_ITEMS.SET_LAST_UPDATE_REQUEST, { id, requestNumber: currentRequest });

    return new Promise((resolve, reject) => {
      PlanningResourcePlanItemsProvider.updatePlanItem(form)
        .then(resource => {
          const lastRequest = Number(state.lastUpdateRequests[id]);
          if (currentRequest === lastRequest) {
            commit(
              TYPES.PLANNING_RESOURCE_PLAN_ITEMS.SET_RESOURCE_PLAN_ITEM,
              resource
            )
          }
          resolve(resource)
        })
        .catch(reject)
    })
  },

  deletePlanItem({ commit }, { id }) {
    return new Promise((resolve, reject) => {
      PlanningResourcePlanItemsProvider.deletePlanItem({ id })
        .then(() => {
          commit(
            TYPES.PLANNING_RESOURCE_PLAN_ITEMS.DELETE_RESOURCE_PLAN_ITEMS,
            id
          )
          resolve()
        })
        .catch(reject)
    })
  },

  addPlanItemResources({ commit }, form) {
    return new Promise((resolve, reject) => {
      PlanningResourcePlanItemsProvider.addPlanItemResources(form)
        .then(() => {
          // commit(TYPES.PLANNING_RESOURCE_PLAN_ITEMS.DELETE_RESOURCE_PLAN_ITEMS, id)
          resolve()
        })
        .catch(reject)
    })
  },

  getPlanItemResources({ commit }, form) {
    return new Promise((resolve, reject) => {
      PlanningResourcePlanItemsProvider.getPlanItemResources(form)
        .then(() => {
          // commit(TYPES.PLANNING_RESOURCE_PLAN_ITEMS.DELETE_RESOURCE_PLAN_ITEMS, id)
          resolve()
        })
        .catch(reject)
    })
  },

  deletePlanItemResource({ commit }, form) {
    return new Promise((resolve, reject) => {
      PlanningResourcePlanItemsProvider.deletePlanItemResource(form)
        .then(() => {
          // commit(TYPES.PLANNING_RESOURCE_PLAN_ITEMS.DELETE_RESOURCE_PLAN_ITEMS, id)
          resolve()
        })
        .catch(reject)
    })
  },

  updateLocalDuedatePlanItem({ commit }, { id, startAt, endAt, isResize, isDragging }) {
    commit(TYPES.PLANNING_RESOURCE_PLAN_ITEMS.UPDATE_TIME_RESOURCE_PLAN_ITEM, {
      id,
      startAt,
      endAt,
      isResize,
      isDragging,
    })
  },

  duplicateItem({ commit }, id) {
    return new Promise((resolve, reject) => {
      PlanningResourcePlanItemsProvider.duplicateItem(id)
        .then(item => {
          commit(
            TYPES.PLANNING_RESOURCE_PLAN_ITEMS.SET_RESOURCE_PLAN_ITEM,
            item
          )
          resolve(item)
        })
        .catch(reject)
    })
  },

  updateLocalPlanItemResources({ commit }, { id, resources }) {
    commit(TYPES.PLANNING_RESOURCE_PLAN_ITEMS.UPDATE_PLAN_ITEM_RESOURCES, {
      id,
      resources
    });
  },

  updateSearchPlanItem({ commit }, itemId) {
    commit(TYPES.PLANNING_RESOURCE_PLAN_ITEMS.SET_SEARCH_PLAN_ITEM_ID, itemId);
  },
}

// mutations
const mutations = {
  [TYPES.PLANNING_RESOURCE_PLAN_ITEMS.INIT_RESOURCE_PLAN_ITEMS](state, { data, startAt, endAt }) {
    const list = Array.isArray(data) ? data : [data]

    const hashTable = list.reduce((table, item) => {
      table[item.id] = item
      return table
    }, {})

    state.planItems.forEach((observableItem) => {
      const isItemIncluded = observableItem.endAt.isSameOrAfter(startAt, 'day')
        && observableItem.startAt.isSameOrBefore(endAt, 'day')
      if (isItemIncluded || Object.prototype.hasOwnProperty.call(hashTable, observableItem.id)) {
        hashTable[observableItem.id] = observableItem
      }
    })

    const { searchPlanItemId } = state
    if (searchPlanItemId && hashTable[searchPlanItemId]) {
      hashTable[searchPlanItemId].isSearch = true 
    }

    state.planItems = Object.values(hashTable)
    state.startAt = startAt
    state.endAt = endAt
  },

  [TYPES.PLANNING_RESOURCE_PLAN_ITEMS.SET_RESOURCE_PLAN_ITEM](state, data) {
    const itemIndex = state.planItems.findIndex(i => i.id === data.id);

    const { searchPlanItemId } = state
    if (searchPlanItemId && data.id === searchPlanItemId) {
      data.isSearch = true
    }

    if (itemIndex !== -1) {
      const planItem = state.planItems[itemIndex];
      state.planItems.splice(itemIndex, 1, data)
      // if (planItem.updatedAt <= data.updatedAt) {
      //   state.planItems.splice(itemIndex, 1, data)
      // }
    } else {
      const isIncludeStartAt = data.startAt.isBetween(state.startAt, state.endAt, undefined, '[]')
      const isIncludeEndAt = data.endAt.isBetween(state.startAt, state.endAt, undefined, '[]')

      if (isIncludeStartAt || isIncludeEndAt) {
        state.planItems.push(data)
      }
    }
  },

  [TYPES.PLANNING_RESOURCE_PLAN_ITEMS.UPDATE_TIME_RESOURCE_PLAN_ITEM](
    state,
    {
      id,
      startAt,
      endAt,
      isResize = false,
      isDragging = false,
    },
  ) {
    let item = state.planItems.find(e => e.id == id)
    if (!item) return
    if (startAt) item.startAt = startAt
    if (endAt) item.endAt = endAt

    item.isResize = isResize;
    item.isDragging = isDragging;
  },

  [TYPES.PLANNING_RESOURCE_PLAN_ITEMS.DELETE_RESOURCE_PLAN_ITEMS](state, data) {
    const ids = Array.isArray(data) ? data : [data]

    state.planItems = [...state.planItems.filter(e => !ids.includes(e.id))]
  },

  [TYPES.PLANNING_RESOURCE_PLAN_ITEMS.SET_CANCELLATION](state, cancellation) {
    state.cancellation = cancellation;
  },

  [TYPES.PLANNING_RESOURCE_PLAN_ITEMS.SET_LOADING](state, loading) {
    state.loading = loading;
  },

  [TYPES.PLANNING_RESOURCE_PLAN_ITEMS.SET_LAST_UPDATE_REQUEST](state, { id, requestNumber }) {
    if (!['string', 'number'].includes(typeof id)) return;
    state.lastUpdateRequests[id] = requestNumber;
  },

  [TYPES.PLANNING_RESOURCE_PLAN_ITEMS.UPDATE_PLAN_ITEM_RESOURCES](state, {
    id,
    resources,
  }) {
    let item = state.planItems.find(e => e.id == id)
    if (!item) return
    if (Array.isArray(resources)) item.resources = resources
  },

  [TYPES.PLANNING_RESOURCE_PLAN_ITEMS.SET_SEARCH_PLAN_ITEM_ID](state, itemId) {
    const { searchPlanItemId } = state

    if (searchPlanItemId && itemId !== searchPlanItemId) {
      const planItem = state.planItems.find(item => item.id === searchPlanItemId)
      if (planItem) {
        planItem.isSearch = false
      }
    }

    if (itemId && itemId !== searchPlanItemId) {
      const planItem = state.planItems.find(item => item.id === itemId)
      if (planItem) {
        planItem.isSearch = true
      }
    }

    state.searchPlanItemId = itemId;
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
