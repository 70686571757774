import Model from '@/models/Model'

class PlanningResourceGroupModel extends Model {
  constructor(data) {
    super(data)

    this.id = Number(data.id)
    this.name = data.name
    this.position = Number(data.position)
  }
}

export default PlanningResourceGroupModel
