import * as TYPES from '@/store/types'
import RegistrationTypesProvider from '@provider/registrationTypes'

// initial state
const state = {
  registrationTypes: [],
}

// getters
const getters = {
  registrationTypes: state => state.registrationTypes,
  registrationTypeHoursProject: (state, getters, rootState) => {
    const { language: locale } = rootState.App;
    return state.registrationTypes
      .filter(e => e.isHoursProject)
      .sort((a, b) =>
        a.name === b.name
          ? a.createdAtM - b.createdAtM
          : a.name.localeCompare(b.name, locale)
      );
  },

  registrationTypeHoursGenerally: (state, getters, rootState) => {
    const { language: locale } = rootState.App;
    return state.registrationTypes
      .filter(e => e.isHoursGenerally)
      .sort((a, b) =>
          a.name === b.name
            ? a.createdAtM - b.createdAtM
            : a.name.localeCompare(b.name, locale)
      );
  },

  registrationTypeMaterials: (state, getters, rootState) => {
    const { language: locale } = rootState.App;
    return state.registrationTypes
      .filter(e => e.isMaterial)
      .sort((a, b) =>
          a.name === b.name
            ? a.createdAtM - b.createdAtM
            : a.name.localeCompare(b.name, locale)
      );
  },
}

// actions
const actions = {
  addRegistrationType({ commit }, { name, price, type, unit, groupId }) {
    return new Promise((resolve, reject) => {
      RegistrationTypesProvider.addRegistrationType({ name, price, type, unit, groupId })
        .then(registrationType => {
          commit(
            TYPES.REGISTRATION_TYPES.INIT_REGISTRATION_TYPES,
            registrationType
          )
          resolve(registrationType)
        })
        .catch(reject)
    })
  },

  getRegistrationTypes({ commit }) {
    return new Promise((resolve, reject) => {
      RegistrationTypesProvider.getRegistrationTypes()
        .then(registrationTypes => {
          commit(
            TYPES.REGISTRATION_TYPES.INIT_REGISTRATION_TYPES,
            registrationTypes
          )
          resolve(registrationTypes)
        })
        .catch(reject)
    })
  },

  getRegistrationType({ commit }, { id }) {
    return new Promise((resolve, reject) => {
      RegistrationTypesProvider.getRegistrationType({ id })
        .then(registrationType => {
          commit(
            TYPES.REGISTRATION_TYPES.INIT_REGISTRATION_TYPES,
            registrationType
          )
          resolve(registrationType)
        })
        .catch(reject)
    })
  },

  updateRegistrationType({ commit }, { id, name, price, type, unit, groupId }) {
    return new Promise((resolve, reject) => {
      RegistrationTypesProvider.updateRegistrationType({
        id,
        name,
        price,
        type,
        unit,
        groupId
      })
        .then(registrationType => {
          commit(TYPES.REGISTRATION_TYPES.DELETE_REGISTRATION_TYPE, id)
          commit(
            TYPES.REGISTRATION_TYPES.INIT_REGISTRATION_TYPES,
            registrationType
          )
          resolve(registrationType)
        })
        .catch(reject)
    })
  },

  deleteRegistrationType({ commit }, { id }) {
    return new Promise((resolve, reject) => {
      RegistrationTypesProvider.deleteRegistrationType({ id })
        .then(() => {
          commit(TYPES.REGISTRATION_TYPES.DELETE_REGISTRATION_TYPE, id)
          resolve()
        })
        .catch(reject)
    })
  },
}

// mutations
const mutations = {
  [TYPES.REGISTRATION_TYPES.INIT_REGISTRATION_TYPES](state, data) {
    let list = Array.isArray(data) ? data : [data],
      ids = list.map(e => e.id)

    state.registrationTypes = [
      ...state.registrationTypes.filter(e => !ids.includes(e.id)),
      ...list.filter(e => e.canView),
    ]
  },

  [TYPES.REGISTRATION_TYPES.DELETE_REGISTRATION_TYPE](state, id) {
    state.registrationTypes = state.registrationTypes.filter(e => e.id != id)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
