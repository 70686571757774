import { isNil } from 'lodash';
import Model from '@/models/Model'
import PlanningResourceModel from '@/models/PlanningResource'
import ChecklistModel from '@/models/Checklist'

class PlanningResourcePlanItemModel extends Model {
  constructor(data) {
    super(data)

    this.id = Number(data.id)
    this.projectId = data.project_id ? Number(data.project_id) : undefined
    this.checklistItemId = data.checklist_item_id
      ? Number(data.checklist_item_id)
      : undefined

    this.name = data.name
    this.concept = data.concept
    this.color = data.color
    this.description = data.description

    // TODO: remove `isResize` property
    this.isResize = false
    this.isSearch = false

    this.startAt = data.start_at_utc ? this.toMomentDate(data.start_at_utc) : null
    this.endAt = data.end_at_utc ? this.toMomentDate(data.end_at_utc) : null

    this.updatedAt = this.toMomentDate(data.updated_at_utc)
    this.createdAt = this.toMomentDate(data.created_at_utc)

    this.resources = data.resources.map(e => new PlanningResourceModel(e))
    this.checklist = data.checklist
      ? new ChecklistModel(data.checklist)
      : undefined

    this.checklistId = data.checklist ? Number(data.checklist.id) : undefined

    if (this.checklist) {
      this.projectId = this.checklist.projectId
    }

    this.registrationId = !isNil(data.registration_id) ? Number(data.registration_id) : null;
  }
}

export default PlanningResourcePlanItemModel
