export default {
  props: {
    name: {
      type: String,
      required: false,
    },

    saveBtn: {
      type: String,
      default: null,
    },

    cancelBtn: {
      type: String,
      default: null,
    },

    required: {
      type: Boolean,
      default: false,
    },

    modelValue: {
      type: String,
      default: '',
    },

    placeholder: {
      type: String,
      default: '',
    },

    editable: {
      type: Boolean,
      default: false,
    },

    clickable: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['cancel', 'update', 'softUpdate'],

  data() {
    return {
      edit: false,
      model: '',
    }
  },

  watch: {
    modelValue() {
      this.model = this.modelValue;
    },
  },

  methods: {
    focus() {
      if (this.onEdit()) {
        this.$refs.uiInput.focus()
      }
    },

    onEdit() {
      if (this.editable) {
        this.edit = true
        return true
      }

      return false
    },

    cancel(e) {
      this.edit = false
      this.model = this.modelValue;
      this.$emit('cancel', e)
    },

    save(e) {
      if (this.disabled) {
        return null
      }

      this.edit = false
      if (this.modelValue !== this.model) {
        this.$emit('update', this.model)
      } else {
        this.$emit('softUpdate', this.model)
      }

      this.lodash.get(this, 'this.$refs.uiInput.blur', () => {})()
    },
  },

  computed: {
    disabled() {
      return this.required ? this.model.length === 0 : false
    },
    empty() {
      return !this.model || this.model.length === 0
    },
  },

  mounted() {
    this.model = this.modelValue;
  },
}
