import Provider from '@provider';
import ReferralModel from '@/models/Referral';

export default {
  getReferrals({ cancelToken }) {
    return Provider.api
      .get('/referrals', { cancelToken })
      .then(({ data }) => data.map(referral => new ReferralModel(referral)));
  },

  updatePaymentType({ id, paymentType }) {
    return Provider.api
      .put(`/referrals/${id}/choose-payment-method`, { payment_type: paymentType })
      .then(({ data }) => new ReferralModel(data));
  },

  createReferral({ email }) {
    return Provider.api
      .post(`/referrals/referral`, { email })
      .then(({ data }) => new ReferralModel(data));
  },

  getReferralLink({ cancelToken }) {
    return Provider.api
      .get(`/referrals/generate-link`, { cancelToken })
      .then(({ data }) => data.referral_link);
  },
};
