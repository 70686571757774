import Model from '@/models/Model';

const generateGoogleMapsLink = (latitude, longitude) => {
  return `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`;
}

class RegistrationSessionModel extends Model {
  constructor(data) {
    super(data);

    this.id = Number(data.id);
    this.userId = Number(data.user_id);
    this.registrationTypeId = Number(data.registration_type_id);
    this.projectId = Number(data.project_id);

    this.startAt = data.start_at;
    this.startLatitude = Number(data.start_latitude);
    this.startLongitude = Number(data.start_longitude);
    this.startGoogleMapsLink = generateGoogleMapsLink(data.start_latitude, data.start_longitude);

    this.endAt = data.end_at;
    this.endLatitude = Number(data.end_latitude);
    this.endLongitude = Number(data.end_longitude);
    this.endGoogleMapsLink = generateGoogleMapsLink(data.end_latitude, data.end_longitude);
    
    this.createdAt = data.created_at;
    this.updatedAt = data.updated_at;
    this.deletedAt = data.deleted_at;
  }
}

export default RegistrationSessionModel;
