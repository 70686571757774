import { isValid } from 'date-fns';
import {
  middlewareGroup,
  logMid,
  desktopMid,
  authMid,
  projectExistMid,
  rememberProjectChatMid,
  projectsViewMid,
  checklistItemExistMid,
  integrationExistMid,
  checklistExistMid,
} from './plugins/middleware'
import { ROUTES } from '@/constants.js';
import { parseMonthDate } from '@/helpers/dates/parseMonthDate.js';
import { isIntegerArrayParameter, parseIntegerArrayParameter } from '@/helpers/router';

const defineTaskRouteForChat = name => ({
  path: 'task/:checklistId/:checklistItemId',
  name,
  props: ({ params }) => ({
    checklistId: Number(params.checklistId) || undefined,
    checklistItemId: Number(params.checklistItemId) || undefined,
  }),
  meta: {
    middleware: [checklistExistMid, checklistItemExistMid],
  },
  component: () => import('@/views/ChatTask.vue'),
});

export default middlewareGroup(
  [logMid, desktopMid, authMid],
  [
    {
      component: () => import('@/layouts/app'),
      path: '/',
      redirect: { name: 'dashboard' },
      children: [
        {
          component: () => import('@/views/Home'),
          path: '/',
          redirect: { name: 'dashboard' },
          name: 'home',
          children: [
            {
              path: '/aandeslag',
              name: 'onboarding',
              component: () => import('@/views/Home/Kanban'),
            },
            {
              path: '/projects',
              alias: '/projectlists',
              name: 'dashboard',

              meta: {
                title: 'pages.home.metaTitle',
                middleware: [projectsViewMid],
              },
            },
            {
              path: '/projects/kanban',
              name: 'projectsKanban',
              component: () => import('../views/Home/Kanban'),
              meta: {
                title: 'pages.home.metaTitle',
              },
            },
            {
              path: '/projectlists/list',
              name: 'projectsList',
              component: () => import('../views/Home/List/Projects'),
              meta: {
                title: 'pages.home.metaTitle',
              },
            },
          ],
        },
        {
          path: '/tasks',
          name: 'tasks',
          component: () => import('../views/Home/List/Checklists'),
          meta: {
            title: 'pages.home.metaTitle',
          },
          children: [
            {
              path: ':checklistId/:checklistItemId',
              name: 'task',
              props: ({ params }) => ({
                checklistId: Number(params.checklistId) || undefined,
                checklistItemId: Number(params.checklistItemId) || undefined,
              }),
              meta: {
                middleware: [checklistItemExistMid],
              },
              component: () => import('../views/Home/List/Checklists/Task'),
            },
          ],
        },
        {
          path: '/oude-planning',
          name: 'PlanningOld',
          component: () => import('@/views/Planning'),
          meta: {
            // title: 'pages.home.metaTitle',
          },
        },
        {
          path: '/planning',
          name: 'Planning',
          component: () => import('@/views/Planning/next.vue'),
          meta: {
            title: 'pages.planning.metaTitle',
          },
        },
        {
          path: '/account',
          name: 'account',
          component: () => import('@/views/Account/Index.vue'),
          meta: {
            title: 'pages.account.metaTitle',
          },
        },
        {
          path: '/employees',
          name: 'employees',
          component: () => import('@/views/employees/Index.vue'),
          meta: {
            title: 'pages.employees.metaTitle',
          },
        },

        {
          path: '/teamchats/:chatId',
          name: 'teamChat',
          component: () => import('@/views/chat/TeamChat.vue'),
          props: ({ params }) => ({
            chatId: Number(params.chatId) || undefined,
          }),
          children: [defineTaskRouteForChat('teamChatTask')],
        },

        {
          path: '/project/:projectId/info',
          name: ROUTES.PROJECT_INFO,
          meta: {
            middleware: [projectExistMid],
          },
          component: () => import('@/views/ProjectInfo'),
        },

        {
          path: '/project/:projectId/management',
          name: 'projectManagement',
          meta: {
            middleware: [projectExistMid],
          },
          component: () => import('@/views/ProjectManagement'),
        },

        {
          path: '/project/:projectId/planning',
          name: 'projectPlanning',
          meta: {
            middleware: [projectExistMid],
          },
          props: ({ params }) => ({
            projectId: Number(params.projectId)
          }),
          component: () => import('@/views/ProjectPlanning'),
        },

        {
          path: '/project/:projectId/chats',
          component: () => import('@/views/chats/Index.vue'),
          props: ({ params }) => ({
            chatId: Number(params.chatId) || undefined
          }),
          meta: {
            middleware: [projectExistMid],
          },

          children: [
            {
              path: '',
              name: 'chats',
              component: () => import('@/views/chats/Default.vue'),
            },
            {
              path: ':chatId',
              name: 'projectChat',
              meta: {
                middleware: [rememberProjectChatMid],
              },
              component: () => import('@/views/chats/Chat.vue'),
              children: [defineTaskRouteForChat('projectChatTask')],
            },
          ],
        },

        /* MEDIA AND DOCUMENTS */

        ...middlewareGroup(
          [projectExistMid],
          [
            {
              path: '/project/:projectId/media-and-documents',
              name: 'projectMedia',
              component: () => import('@/views/MediaAndDocuments/Project'),
            },
            {
              path: '/project/:projectId/media-and-documents/secure',
              name: 'secureMedia',
              component: () => import('@/views/MediaAndDocuments/Secure'),
            },
            {
              path: '/project/:projectId/chats/:chatId/media-and-documents',
              name: 'chatMedia',
              component: () => import('@/views/MediaAndDocuments/Chat'),
            },
          ]
        ),

        {
          path: '/teamchats/:chatId/media-and-documents',
          name: 'teamChatMedia',
          component: () => import('@/views/MediaAndDocuments/TeamChat.vue'),
        },

        /* END MEDIA AND DOCUMENTS */

        /* MEMBERS MANAGER */

        {
          path: '/project/:projectId/chats/:chatId/members',
          name: 'projectChatMembersManager',
          meta: {
            middleware: [projectExistMid],
          },
          component: () => import('@/views/MembersManager/ProjectChat.vue'),
        },
        {
          path: '/teamchats/:chatId/members',
          name: 'teamChatMembersManager',
          component: () => import('@/views/MembersManager/TeamChat.vue'),
        },

        /* END MEMBERS MANAGER */

        /* REGISTRATION TYPES */

        {
          path: '/instellingen',
          name: 'registrationTypes',
          redirect: { name: 'companyDetails' },
          component: () => import('@/views/RegistrationTypes'),

          children: [
            {
              path: 'bedrijfsgegevens',
              name: 'companyDetails',
              component: () =>
                import('@/views/RegistrationTypes/tabs/CompanyDetails/Index'),
            },
            {
              path: 'uren-project',
              name: 'registrationTypesHoursProject',
              component: () =>
                import('@/views/RegistrationTypes/tabs/HoursProject/Index.vue'),
            },
            {
              path: 'uren-algemeen',
              name: 'registrationTypesHoursGenerally',
              component: () =>
                import('@/views/RegistrationTypes/tabs/HoursGenerally/Index.vue'),
            },
            {
              path: 'materiaal',
              name: 'registrationTypesMaterial',
              component: () =>
                import('@/views/RegistrationTypes/tabs/Material/Index.vue'),
            },
            {
              path: 'planning',
              name: 'registrationTypesPlanning',
              component: () =>
                import('@/views/RegistrationTypes/tabs/Planning.vue'),
            },
            {
              path: '/importeren-projecten',
              name: 'projectsImport',
              component: () =>
                import('@/views/UserSettings/ProjectsImport.vue')
            },
            {
              path: '/import-materials',
              name: 'materialsImport',
              component: () =>
                import('@/views/UserSettings/MaterialsImport.vue')
            },
            {
              path: '/import-tasks',
              name: 'tasksImport',
              component: () =>
                import('@/views/UserSettings/TasksImport.vue')
            },

            {
              path: '/forms',
              name: 'forms',
              component: () => import('@/views/Forms/Index.vue'),
            },

            {
              path: '/pdf-imports',
              name: 'pdfImports',
              component: () => import('@/views/PdfImports/Index.vue'),
            },
          ],
        },

        {
          path: '/forms/create',
          name: 'createForm',
          component: () => import('@/views/Forms/Editor.vue'),
          props: () => ({
            formId: null,
          }),
        },
        {
          path: '/forms/:formId/edit',
          name: 'editForm',
          component: () => import('@/views/Forms/Editor.vue'),
          props: ({ params }) => ({
            formId: Number(params.formId),
          }),
        },

        {
          path: '/registration-logs',
          name: 'registrationLogs',
          component: () => import('@/views/RegistrationLogs'),
        },

        {
          path: '/marketplace',
          name: 'marketplace',
          component: () => import('@/views/Marketplace/Index.vue'),
        },
        {
          path: '/marketplace/integrations/:integrationSlug',
          name: 'integration',
          meta: {
            middleware: [integrationExistMid]
          },
          props: ({ params }) => ({
            integrationSlug: params.integrationSlug || undefined
          }),
          component: () => import('@/views/Marketplace/Integration/Index.vue'),
        },

        {
          path: '/referral',
          name: 'referrals',
          component: () => import('@/views/Referrals/Index.vue'),
        },
      ],
    },

    {
      component: () => import('@/layouts/fullscreen/index.vue'),
      path: '/',
      props: (route) => ({
        disableTransitions: Boolean(route.meta.disableTransitions),
      }),
      children: [
        {
          path: '/project/:projectId/checklist/:checklistId/print',
          name: 'projectPlanningPrint',
          meta: {
            middleware: [projectExistMid, checklistExistMid],
            disableTransitions: true,
          },
          props: ({ params, query }) => ({
            projectId: Number(params.projectId),
            checklistId: Number(params.checklistId),
            onlyPlannedItems: query.only_planned_items
              ? /true|1/i.test(query.only_planned_items)
              : undefined,
            view: query.view,
            orientation: query.orientation,
          }),
          component: () => import('@/views/ProjectPlanning/ChecklistPrint.vue'),
        },
        {
          path: '/planning/print',
          name: 'PlanningPrint',
          meta: {
            disableTransitions: true,
          },
          props: ({ query }) => ({
            groups: query.groups && isIntegerArrayParameter(query.groups)
              ? parseIntegerArrayParameter(query.groups)
              : [],
            view: query.view,
            orientation: query.orientation,
            from: isValid(parseMonthDate(query.from)) ? parseMonthDate(query.from) : new Date(),
            till: isValid(parseMonthDate(query.till)) ? parseMonthDate(query.till) : new Date(),
          }),
          component: () => import('@/views/Planning/Print.vue'),
        },
        {
          path: '/form/:formId/response/print',
          name: 'FormResponsePrint',
          meta: {
            disableTransitions: true,
          },
          props: ({ params }) => ({
            formId: Number(params.formId),
          }),
          component: () =>
            import(/* webpackChunkName: 'form-response-print' */ '@/views/Forms/ResponsePrint.vue'),
        },
      ],
    },
  ]
)
