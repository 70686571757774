import Model from '@/models/Model'
import UserModel from '@/models/User'
import MediaFileModel from '@/models/MediaFile'

class CommentModel extends Model {
  constructor(data) {
    super(data)

    this.id = Number(data.id)
    this.projectId = Number(data.project_id)
    this.checklistItemId = Number(data.checklist_item_id)

    this.comment = data.file ? '' : data.comment
    this.file = data.file ? new MediaFileModel(data.file) : null

    this.user = data.user ? new UserModel(data.user) : null

    this.updatedAt = this.fixData(data.updated_at_utc)
    this.updatedAtM = this.toMomentDate(data.updated_at_utc)
    this.createdAtM = this.toMomentDate(data.created_at_utc)

    if (this.comment == 'file' && !this.file) {
      this.canView = false
    }
  }
}

export default CommentModel
