import { computePosition, flip, autoPlacement } from '@floating-ui/dom';
import { isUndefined, isObject } from 'lodash';

import { getScrollableParent } from './getScrollableParent';

const WOUB_DROP_KEY = '__woub_drop__';

const normalizeMiddlewareOptions = options => isObject(options) ? options : undefined;

class WoubDrop {
  constructor({
    floatingElement,
    referenceElement,
    computeStyle,
    placement,
    strategy,
    flip = false, // boolean or object
    autoPlacement = false, // boolean or object
  } = {}) {
    this.floatingElement = floatingElement;
    this.referenceElement = referenceElement;
    this.placement = placement ?? 'bottom';
    this.strategy = strategy ?? 'fixed';
    this.scrollableElement = getScrollableParent(referenceElement);

    this.computeStyle = computeStyle;

    this.isAutoUpdating = false;

    this.subscriptions = [];
    this.resizeObserver = null;

    this.flip = flip;
    this.autoPlacement = autoPlacement;

    floatingElement[WOUB_DROP_KEY] = this;
  }

  computePosition() {
    const {
      floatingElement,
      referenceElement,
      placement,
      strategy,
    } = this;

    const middleware = [];

    if (this.autoPlacement) {
      middleware.push(autoPlacement(normalizeMiddlewareOptions(this.autoPlacement)));
    } else if (this.flip) {
      middleware.push(flip(normalizeMiddlewareOptions(this.flip)));
    }

    return computePosition(referenceElement, floatingElement, {
      placement,
      strategy,
      middleware,
    });
  }

  update(options) {
    Object.entries(options).forEach(([option, value]) => {
      if (!isUndefined(value)) {
        this[option] = value;
      }
    });
  }

  applyPosition({ x, y, placement, strategy, middlewareData }) {
    const { floatingElement, computeStyle } = this;

    let resultStyle = {
      transform: `translate3d(${x}px, ${y}px, 0px)`
    };

    if (computeStyle) {
      resultStyle = computeStyle(resultStyle, { x, y, placement, strategy, middlewareData });
    }

    Object.assign(floatingElement.style, resultStyle);
  }

  async updatePosition() {
    this.applyPosition(await this.computePosition());
  }

  enableAutoUpdatingPosition() {
    if (this.isAutoUpdating) {
      return;
    }

    const { scrollableElement } = this;
    const updatePositionWithContext = this.updatePosition.bind(this);

    scrollableElement.addEventListener('scroll', updatePositionWithContext);
    this.subscriptions.push(() => {
      scrollableElement.removeEventListener('scroll', updatePositionWithContext);
    });

    this.resizeObserver = new ResizeObserver(() => {
      updatePositionWithContext();
    });
    this.resizeObserver.observe(this.referenceElement);

    this.isAutoUpdating =  true;
  }

  disableAutoUpdatingPosition() {
    if (!this.isAutoUpdating) {
      return;
    }

    this.subscriptions.forEach((unsubscribe) => unsubscribe());
    this.subscriptions = [];

    this.resizeObserver.disconnect();
    this.resizeObserver = null;

    this.isAutoUpdating = false;
  }

  destroy() {
    const {
      floatingElement,
      isAutoUpdating,
    } = this;

    if (isAutoUpdating) {
      this.disableAutoUpdatingPosition();
    }

    if (floatingElement && WOUB_DROP_KEY in floatingElement) {
      this.floatingElement = null;
      this.referenceElement = null;
      this.scrollableElement = null;
      this.styles = {};
      delete floatingElement[WOUB_DROP_KEY];
    }
  }
}

export default {
  install(app) {
    app.directive('drop', {
      async mounted(element, binding) {
        const { value: options } = binding;

        const woubDrop = new WoubDrop({
          ...options,
          floatingElement: element,
        });

        woubDrop.updatePosition();
        woubDrop.enableAutoUpdatingPosition();
      },

      updated(element, binding) {
        const { value: options } = binding;

        element[WOUB_DROP_KEY]?.update(options);
        element[WOUB_DROP_KEY]?.updatePosition();
      },

      unmounted(element) {
        element[WOUB_DROP_KEY].destroy();
      },
    });
  },
};
