import { cloneDeep } from 'lodash';
import { isProduction } from '@/config.js';
import { FORM_FIELD_OPTION_TYPES } from '@/constants';
import {
  cloneFormField,
  defineFieldConfig,
  defineEmptyFormField,
  UNIQUE_KEY,
  defineEmptyOption,
  defineOptionForAttachments,
  defineOptionForOther,
} from '@/provider/v1/formEditor.js';
import * as TYPES from '@/store/types';

const isValidIndex = index => index !== null && index >= 0;

const warnFormIsNotInitialized = () => {
  console.warn('The form is not initialized');
};

const findIndexOfFormField = (form, { id, [UNIQUE_KEY]: uid }) => {
  if (form) {
    const predicate = id ? field => field.id === id : field => field[UNIQUE_KEY] === uid;
    return form.fields.findIndex(predicate);
  } else if (!isProduction) {
    warnFormIsNotInitialized();
  }
  return null;
};

const findIndexOfFormFieldOption = (options, { id, [UNIQUE_KEY]: uid }) => {
  if (!options) {
    !isProduction && console.warn('The field options are not initialized');
    return null;
  }

  const predicate = id ? option => option.id === id : option => option[UNIQUE_KEY] === uid;
  return options.findIndex(predicate);
};

// initial state
const state = {
  hasChanges: false,
  initialForm: null,
  form: null,
};

// getters
const getters = {};

// actions
const actions = {
  startFormEditing({ commit }, form) {
    commit(TYPES.FORM_EDITOR.SET_INITIAL_FORM, cloneDeep(form));
    commit(TYPES.FORM_EDITOR.SET_FORM, form);
    commit(TYPES.FORM_EDITOR.SET_HAS_CHANGES, false);
  },
  finishFormEditing({ commit }) {
    commit(TYPES.FORM_EDITOR.SET_INITIAL_FORM, null);
    commit(TYPES.FORM_EDITOR.SET_FORM, null);
    commit(TYPES.FORM_EDITOR.SET_HAS_CHANGES, false);
  },
  resetForm({ state, commit }) {
    const { initialForm } = state;
    if (!initialForm) return;

    commit(TYPES.FORM_EDITOR.SET_FORM, cloneDeep(initialForm));
    commit(TYPES.FORM_EDITOR.SET_HAS_CHANGES, false);
  },
  setForm({ commit }, form) {
    commit(TYPES.FORM_EDITOR.SET_FORM, form);
    commit(TYPES.FORM_EDITOR.SET_HAS_CHANGES, true);
  },
  setFormName({ commit }, name) {
    commit(TYPES.FORM_EDITOR.SET_FORM_NAME, name);
    commit(TYPES.FORM_EDITOR.SET_HAS_CHANGES, true);
  },
  setFormDescription({ commit }, description) {
    commit(TYPES.FORM_EDITOR.SET_FORM_DESCRIPTION, description);
    commit(TYPES.FORM_EDITOR.SET_HAS_CHANGES, true);
  },
  addEmptyFormField({ commit }) {
    commit(TYPES.FORM_EDITOR.ADD_FIELD, defineEmptyFormField());
    commit(TYPES.FORM_EDITOR.SET_HAS_CHANGES, true);
  },
  removeFormField({ commit }, payload) {
    commit(TYPES.FORM_EDITOR.REMOVE_FIELD, payload);
    commit(TYPES.FORM_EDITOR.SET_HAS_CHANGES, true);
  },
  copyFormField({ commit }, payload) {
    commit(TYPES.FORM_EDITOR.COPY_FIELD, payload);
    commit(TYPES.FORM_EDITOR.SET_HAS_CHANGES, true);
  },
  setFormFieldName({ commit }, payload) {
    commit(TYPES.FORM_EDITOR.SET_FIELD_NAME, payload);
    commit(TYPES.FORM_EDITOR.SET_HAS_CHANGES, true);
  },
  setFormFieldType({ commit }, payload) {
    commit(TYPES.FORM_EDITOR.SET_FIELD_TYPE, payload);
    commit(TYPES.FORM_EDITOR.SET_HAS_CHANGES, true);
  },
  setFormFieldRequired({ commit }, payload) {
    commit(TYPES.FORM_EDITOR.SET_FIELD_REQUIRED, payload);
    commit(TYPES.FORM_EDITOR.SET_HAS_CHANGES, true);
  },
  addEmptyFormFieldOption({ state, commit }, payload) {
    const index = findIndexOfFormField(state.form, payload);
    if (!isValidIndex(index)) return;

    const field = state.form.fields[index];
    const counter = field.config.options.filter(
      option => option.type === FORM_FIELD_OPTION_TYPES.DEFAULT
    ).length;

    commit(TYPES.FORM_EDITOR.ADD_FIELD_OPTION, {
      field: payload,
      option: defineEmptyOption(counter + 1),
    });
    commit(TYPES.FORM_EDITOR.SET_HAS_CHANGES, true);
  },
  removeFormFieldOption({ commit }, payload) {
    commit(TYPES.FORM_EDITOR.REMOVE_FIELD_OPTION, payload);
    commit(TYPES.FORM_EDITOR.SET_HAS_CHANGES, true);
  },
  setFormFieldOptionName({ commit }, payload) {
    commit(TYPES.FORM_EDITOR.SET_FIELD_OPTION_NAME, payload);
    commit(TYPES.FORM_EDITOR.SET_HAS_CHANGES, true);
  },
  addFormFieldOptionForOther({ commit }, payload) {
    commit(TYPES.FORM_EDITOR.ADD_FIELD_OPTION, {
      field: payload,
      option: defineOptionForOther(),
    });
    commit(TYPES.FORM_EDITOR.SET_HAS_CHANGES, true);
  },
  addFormFieldOptionForAttachments({ commit }, payload) {
    commit(TYPES.FORM_EDITOR.ADD_FIELD_OPTION, {
      field: payload,
      option: defineOptionForAttachments(),
    });
    commit(TYPES.FORM_EDITOR.SET_HAS_CHANGES, true);
  },
  addFormFieldAttachments({ commit }, payload) {
    commit(TYPES.FORM_EDITOR.SET_FIELD_ATTACHMENTS, {
      field: payload,
      hasAttachments: true,
    });
    commit(TYPES.FORM_EDITOR.SET_HAS_CHANGES, true);
  },
  removeFormFieldAttachments({ commit }, payload) {
    commit(TYPES.FORM_EDITOR.SET_FIELD_ATTACHMENTS, {
      field: payload,
      hasAttachments: false,
    });
    commit(TYPES.FORM_EDITOR.SET_HAS_CHANGES, true);
  },
  setFormFieldPositions({ commit }, fields) {
    commit(TYPES.FORM_EDITOR.SET_FIELD_POSITIONS, fields);
    commit(TYPES.FORM_EDITOR.SET_HAS_CHANGES, true);
  },
};

// mutations
const mutations = {
  [TYPES.FORM_EDITOR.SET_HAS_CHANGES](state, hasChanges) {
    state.hasChanges = hasChanges;
  },
  [TYPES.FORM_EDITOR.SET_INITIAL_FORM](state, initialForm) {
    state.initialForm = initialForm;
  },
  [TYPES.FORM_EDITOR.SET_FORM](state, form) {
    state.form = form;
  },
  [TYPES.FORM_EDITOR.SET_FORM_NAME](state, name) {
    if (state.form) {
      state.form.name = name;
    } else if (!isProduction) {
      warnFormIsNotInitialized();
    }
  },
  [TYPES.FORM_EDITOR.SET_FORM_DESCRIPTION](state, description) {
    if (state.form) {
      state.form.description = description;
    } else if (!isProduction) {
      warnFormIsNotInitialized();
    }
  },
  [TYPES.FORM_EDITOR.ADD_FIELD](state, field) {
    if (state.form) {
      state.form.fields.push(field);
    } else if (!isProduction) {
      warnFormIsNotInitialized();
    }
  },
  [TYPES.FORM_EDITOR.REMOVE_FIELD](state, payload) {
    const index = findIndexOfFormField(state.form, payload);

    if (!isValidIndex(index)) return;

    state.form.fields.splice(index, 1);
  },
  [TYPES.FORM_EDITOR.COPY_FIELD](state, payload) {
    const index = findIndexOfFormField(state.form, payload);

    if (!isValidIndex(index)) return;

    const { fields } = state.form;
    const clonedField = cloneFormField(fields[index]);
    fields.splice(index + 1, 0, clonedField);
  },
  [TYPES.FORM_EDITOR.SET_FIELD_NAME](state, payload) {
    const index = findIndexOfFormField(state.form, payload);

    if (!isValidIndex(index)) return;

    const { fields } = state.form;
    fields[index].name = payload.name;
  },
  [TYPES.FORM_EDITOR.SET_FIELD_TYPE](state, payload) {
    const index = findIndexOfFormField(state.form, payload);

    if (!isValidIndex(index)) return;

    const { fields } = state.form;
    const field = fields[index];
    const { type } = payload;
    const config = defineFieldConfig(type, field.config);

    if (field.type === type) return;

    field.type = type;
    field.config = config;
  },
  [TYPES.FORM_EDITOR.SET_FIELD_REQUIRED](state, payload) {
    const index = findIndexOfFormField(state.form, payload);

    if (!isValidIndex(index)) return;

    const { fields } = state.form;
    fields[index].required = payload.required;
  },
  [TYPES.FORM_EDITOR.ADD_FIELD_OPTION](state, { field, option }) {
    const index = findIndexOfFormField(state.form, field);

    if (!isValidIndex(index)) return;

    const { fields } = state.form;
    fields[index].config.options.push(option);
  },
  [TYPES.FORM_EDITOR.REMOVE_FIELD_OPTION](state, { field, option }) {
    const index = findIndexOfFormField(state.form, field);

    if (!isValidIndex(index)) return;

    const { options } = state.form.fields[index].config;
    const optionIndex = findIndexOfFormFieldOption(options, option);

    if (!isValidIndex(optionIndex)) return;

    options.splice(optionIndex, 1);
  },
  [TYPES.FORM_EDITOR.SET_FIELD_OPTION_NAME](state, { field, option }) {
    const index = findIndexOfFormField(state.form, field);

    if (!isValidIndex(index)) return;

    const { options } = state.form.fields[index].config;
    const optionIndex = findIndexOfFormFieldOption(options, option);

    if (!isValidIndex(optionIndex)) return;

    options[optionIndex].name = option.name;
  },
  [TYPES.FORM_EDITOR.SET_FIELD_ATTACHMENTS](state, { field, hasAttachments }) {
    const index = findIndexOfFormField(state.form, field);

    if (!isValidIndex(index)) return;

    const { fields } = state.form;
    fields[index].config.hasAttachments = hasAttachments;
  },
  [TYPES.FORM_EDITOR.SET_FIELD_POSITIONS](state, fields) {
    if (state.form) {
      state.form.fields = fields;
    } else if (!isProduction) {
      warnFormIsNotInitialized();
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
