import _ from 'lodash';

const TooltipSizes = Object.freeze({
  SMALL: 'small',
});

const getValidTooltipSize = size => {
  if (size && !Object.values(TooltipSizes).includes(size)) {
    console.warn('The size of a tooltip is not available:', size);
    return null;
  }
  return size;
};

/**
 * Tooltip Manager
 */

class TooltipManager {
  constructor() {
    this.el = document.createElement('div');
    this.el.classList.add('tooltip-m');

    this.DEF_STYLE = {
      maxWidth: '20em',
      left: '',
      right: '',
      bottom: '',
      top: '',
    };

    document.body.appendChild(this.el);
  }

  hide() {
    this.el.classList.add('empty');
    this.el.innerHTML = '';
    this.el.style.top = '-1000px';
    this.el.style.left = '-1000px';
  }

  update({ html, el, pos }, { position, style, size }) {
    size = getValidTooltipSize(size);

    if (!html) {
      this.hide();
      return;
    } else this.el.classList.remove('empty');

    this.el.innerHTML = html;

    const XY_OFFSET = 20,
      positions = position.split(' '),
      rectTooltip = this.el.getBoundingClientRect();

    /**
     * Set styles to tooltip
     */
    const mergeStyles = _.merge({ ...this.DEF_STYLE }, style);
    for (const key in mergeStyles) this.el.style[key] = mergeStyles[key];

    /**
     * Position bottom
     */
    if (positions.includes('bottom')) {
      // Check bounding with bottom document line
      if (pos.y + rectTooltip.height + XY_OFFSET > document.body.clientHeight) {
        // this.el.style.top = `${pos.y - rectTooltip.height - XY_OFFSET}px`
        this.el.style.top = `${document.body.clientHeight - rectTooltip.height - XY_OFFSET}px`;
      } else {
        this.el.style.top = `${pos.y + XY_OFFSET}px`;
      }
    } else if (positions.includes('top')) {
      // Check bounding with top document line
      if (pos.y - rectTooltip.height < XY_OFFSET) {
        this.el.style.top = `${XY_OFFSET}px`;
      } else {
        this.el.style.top = `${pos.y - rectTooltip.height - XY_OFFSET}px`;
      }
    }

    /**
     * Position left
     */
    if (positions.includes('left')) {
      // Check bounding with right document line
      if (this.el.clientWidth + pos.x + XY_OFFSET >= document.body.clientWidth) {
        let left = document.body.clientWidth - this.el.clientWidth - XY_OFFSET;

        this.el.style.left = `${left < 0 ? XY_OFFSET : left}px`;
      } else {
        this.el.style.left = `${pos.x}px`;
      }
    }

    /**
     * Check overflow text
     */
    if (this.el.scrollHeight > this.el.clientHeight) this.el.classList.add('tx-overflow');
    else this.el.classList.remove('tx-overflow');

    if (!size && this.el.getAttribute('size')) {
      this.el.removeAttribute('size');
    } else {
      this.el.setAttribute('size', size);
    }
  }
}

const tooltipManager = new TooltipManager();

/**
 * Tooltip class
 */

class Tooltip {
  /**
   *
   * @param {HTMLElement} el - the target element
   * @param {Object} params - parameters of a tooltip behavior
   * @param {Boolean} [params.textOverflow=] - show a tooltip when the target element has a text overflow
   * @param {String} [param.textOverflowNestedElements] - a selector to check nested elements  for overflow
   * @param {String} [params.position=] - a string with 'bottom' and/or 'left' values that are separated
   * by whitespace character, valid values: 'left', 'bottom', 'left bottom' or 'bottom left'
   * @param {Object} [params.style=] - a style of a tooltip element
   * @param {String} [params.size=] - a size of a tooltip element
   * @param {String|() => string} [params.html] - a tooltip content or getter for content
   */
  constructor(el, params) {
    this.element = el;
    this.position = {
      x: 0,
      y: 0,
    };

    this.focus = false;

    el.classList.add('tooltip-p');

    this.init(params);

    let onMouseMove = event => {
      this.focus = true;
      this.position.x = event.x;
      this.position.y = event.y;

      this.update();
    };

    let onMouseLeave = () => {
      tooltipManager.hide();
      this.focus = false;
    };

    el.addEventListener('mousemove', onMouseMove);
    el.addEventListener('mouseleave', onMouseLeave);

    this.unbind = () => {
      el.removeEventListener('mousemove', onMouseMove);
      el.removeEventListener('mouseleave', onMouseLeave);

      if (this.focus) tooltipManager.hide();
    };
  }

  update() {
    const { element: el } = this;

    if (this.params.textOverflow) {
      const { textOverflowNestedElements } = this.params;
      const elements = textOverflowNestedElements
        ? [...el.querySelectorAll(textOverflowNestedElements)]
        : [el];

      if (elements.every(element => element.clientWidth >= element.scrollWidth)) return;
    }

    tooltipManager.update(
      {
        html: this.getHtml(),
        el,
        pos: this.position,
      },
      this.params
    );
  }

  init(params) {
    const { html } = params;
    this.params = params;
    this.html = html;

    if (this.focus) this.update();
  }

  getHtml() {
    return _.isFunction(this.html) ? this.html() : this.html;
  }
}

/**
 * Parse binding params
 */
function parseOption(binding) {
  return {
    html: _.get(binding, 'value.text'),
    position: _.get(binding, 'value.position', 'bottom left'),
    textOverflow: _.get(binding, 'value.textOverflow', false),
    textOverflowNestedElements: _.get(binding, 'value.textOverflowNestedElements', null),
    style: _.get(binding, 'value.style', {}),
    size: _.get(binding, 'value.size', null),
  };
}

/**
 * Derictive
 */

export const installTooltipPlugin = app => {
  app.directive('tooltip', {
    created: function (el, binding) {
      el.tooltip = new Tooltip(el, parseOption(binding));
    },

    beforeUpdate: function (el, binding) {
      el.tooltip.init(parseOption(binding));
    },

    unmounted: function (el) {
      el.tooltip.unbind();
    },
  });
};
