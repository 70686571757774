import { nextTick } from 'vue';
import { mapGetters } from 'vuex';
import { SCROLL_RESERVED_PERIODS, SCROLL_RESERVED_WIDTH, SCROLLBAR_WIDTH } from '../common';

export default {
  __unobserveResizing__: null,

  props: {
    zoomPlan: {
      require: true,
    },
  },

  inject: ['planningData'],

  emits: ['input'],

  data() {
    return {
      oneSecondWidth: 0,
      onePeriodWidth: 0,
    };
  },

  computed: {
    ...mapGetters('Companies', ['meCompany']),

    periodDuration() {
      return this.periodsRange.endAt - this.periodsRange.startAt;
    },

    periodsRange() {
      const { startAt, endAt, periods, delimiter } = this.view;

      const middleTimestamp = Math.round((startAt.valueOf() + endAt.valueOf()) / 2);
      const middleDay = this.$moment(middleTimestamp);
    
      const startPeriods = Math.ceil((periods - 1) / 2);
      const endPeriods = Math.floor((periods - 1) / 2);
    
      const newStartAt = middleDay
        .clone()
        .subtract(startPeriods, delimiter)
        .startOf(delimiter);
      const newEndAt = middleDay
        .clone()
        .add(endPeriods, delimiter)
        .endOf(delimiter);
    
      return {
        startAt: newStartAt,
        endAt: newEndAt,
      };
    },
  },

  mounted() {
    // render one period and calculate how much can display
    const { zoomList, zoomItems } = this.$refs;

    if (!zoomList || !zoomItems?.length) return;

    const periodWidth = zoomItems.reduce((width, zoomItem) => width + zoomItem.clientWidth, 0);
    this.onePeriodWidth = periodWidth;
    this.oneSecondWidth = periodWidth * 1000 / this.periodDuration;

    this.setPeriods(zoomList.clientWidth);

    const resizeObserver = new ResizeObserver(() => {
      this.setPeriods(zoomList.clientWidth);
    });

    resizeObserver.observe(zoomList);
    this.__unobserveResizing__ = () => {
      resizeObserver.disconnect();
    };
  },

  beforeUnmount() {
    this.__unobserveResizing__?.();
  },

  methods: {
    setPeriods(zoomClientWidth) {
      const conditionByView = ['month', 'quarters'].includes(this.view.delimiter);
      const conditionByCompany = this.meCompany.id === 1608;

      const zoomWidth = conditionByView && conditionByCompany
        ? zoomClientWidth + SCROLLBAR_WIDTH
        : zoomClientWidth * 2 + SCROLLBAR_WIDTH;

      const visiblePeriods = Math.ceil(zoomWidth / this.onePeriodWidth);
      const periods = Math.ceil(visiblePeriods + SCROLL_RESERVED_PERIODS * 2);

      this.view.periods = periods;
    },

    getZoomWidth() {
      const zoomList = this.lodash.get(this, '$refs.zoomList');
      if (!zoomList) return 1

      return zoomList.scrollWidth;
    },

    fetchData() {
      nextTick(() => {
        this.$emit('update:zoomPlan', {
          ...this.view,

          startAt: this.firstItem,
          endAt: this.lastItem,

          items: this.items,
          zoomWidth: this.getZoomWidth(),

          zmSetCenter: data => {
            const { delimiter } = this.view;

            this.view.startAt = this.shiftZoomDateBack(data, delimiter).startOf(delimiter);
            this.view.endAt = this.shiftZoomDateForward(data, delimiter).endOf(delimiter);

            this.fetchData()
          },

          zmPrev: () => {
            const { delimiter } = this.view;

            this.view.startAt = this.shiftZoomDateBack(this.view.startAt, delimiter);
            this.view.endAt = this.shiftZoomDateBack(this.view.endAt, delimiter);

            this.fetchData()

            return {
              startAt: this.view.startAt.clone(),
              endAt: this.view.endAt.clone()
            }
          },

          zmNext: () => {
            const { delimiter } = this.view;

            this.view.startAt = this.shiftZoomDateForward(this.view.startAt, delimiter);
            this.view.endAt = this.shiftZoomDateForward(this.view.endAt, delimiter);

            this.fetchData()

            return {
              startAt: this.view.startAt.clone(),
              endAt: this.view.endAt.clone()
            }
          },
        })
      })
    },

    shiftZoomDateBack(momentDate, delimiter) {
      const halfPeriods = Math.floor(this.view.periods / 2);
      return momentDate.clone().subtract(halfPeriods, delimiter);
    },

    shiftZoomDateForward(momentDate, delimiter) {
      const halfPeriods = Math.floor(this.view.periods / 2);
      return momentDate.clone().add(halfPeriods, delimiter);
    },

    /**
     * Handlers click on dates
     */

    handleClickOnMonth(m) {
      this.planningData.timeView = m
        .clone()
        .startOf('month')
        .valueOf()

      this.planningData.zoomView = 2
      this.planningData.save()
    },

    handleClickOnWeek(w) {
      this.planningData.timeView = w
        .clone()
        .startOf('week')
        .valueOf()

      this.planningData.zoomView = 1
      this.planningData.save()
    },

    handleClickOnDay(d) {
      this.planningData.timeView = d
        .clone()
        .set({
          hour: 5,
          minute: 0,
        })
        .valueOf()

      this.planningData.zoomView = 0
      this.planningData.save()
    },
  },

  events: {
    'planning:prev-zoom'() {
      this.view.startAt = this.view.startAt.clone().add(-1, this.view.delimiter)
      this.fetchData()
    },

    'planning:next-zoom'() {
      this.view.endAt = this.view.endAt.clone().add(1, this.view.delimiter)
      this.fetchData()
    },
  }
};
