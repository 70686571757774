import * as TYPES from '@/store/types'
import LocalStorageWrapper from '@/plugins/localStorageWrapper'
import { LANGUAGES } from '@/constants'

const KEYS = {
  SHOW_CHAT_SIDEBAR: 'SHOW_CHAT_SIDEBAR',
  SHOW_MEDIA_STYLE: 'SHOW_MEDIA_STYLE',
  SORT_MEDIA_BY: 'SORT_MEDIA_BY',
  VIEW_LIST_PROJECTS: 'VIEW_LIST_PROJECTS',
  LOCALE: 'LOCALE',
}

// initial state
const state = {
  network: false,
  showChatSidebar: LocalStorageWrapper.getItem(KEYS.SHOW_CHAT_SIDEBAR, {
    deff: true,
  }),
  showMediaStyle: LocalStorageWrapper.getItem(KEYS.SHOW_MEDIA_STYLE, {
    deff: 'block',
  }),
  sortMediaBy: LocalStorageWrapper.getItem(KEYS.SORT_MEDIA_BY, {
    deff: 'dateOldNew',
  }),
  viewListProjects: LocalStorageWrapper.getObj(
    KEYS.VIEW_LIST_PROJECTS,
    'kanban'
  ),
  language: LocalStorageWrapper.getItem(KEYS.LOCALE, {
    deff: process.env.VUE_APP_I18N_LOCALE || LANGUAGES.NETHERLANDS,
    parse: true,
  }),
}

// getters
const getters = {
  network: state => state.network,
  showChatSidebar: state => state.showChatSidebar,
  showMediaStyle: state => state.showMediaStyle,
  sortMediaBy: state => state.sortMediaBy,
  viewListProjects: state => state.viewListProjects,
}

// actions
const actions = {
  updateShowChatSidebar({ commit }, value) {
    commit(TYPES.APP.UPDATE_SHOW_CHAT_SIDEBAR, value)
  },

  updateShowMediaStyle({ commit }, value) {
    commit(TYPES.APP.UPDATE_SHOW_MEDIA_STYLE, value)
    localStorage.setItem(KEYS.SHOW_MEDIA_STYLE, value)
  },

  updateSortMediaBy({ commit }, value) {
    commit(TYPES.APP.UPDATE_SORT_MEDIA_BY, value)
  },

  updateLanguage({ commit }, language) {
    commit(TYPES.APP.UPDATE_LANGUAGE, language)
    LocalStorageWrapper.setItem(KEYS.LOCALE, language)
  },
}

// mutations
const mutations = {
  [TYPES.APP.UPDATE_SORT_MEDIA_BY](state, value) {
    localStorage.setItem(KEYS.SORT_MEDIA_BY, value)
    state.sortMediaBy = value
  },

  [TYPES.APP.UPDATE_SHOW_MEDIA_STYLE](state, value) {
    state.showMediaStyle = value
  },

  [TYPES.APP.CHANGE_NETWORK](state, status) {
    state.network = status
  },

  [TYPES.APP.UPDATE_SHOW_CHAT_SIDEBAR](state, value) {
    state.showChatSidebar = value
    LocalStorageWrapper.setItem(KEYS.SHOW_CHAT_SIDEBAR, value)
  },

  [TYPES.APP.UPDATE_VIEW_LIST_PROJECTS](state, value) {
    state.viewListProjects = value
    LocalStorageWrapper.setItem(KEYS.VIEW_LIST_PROJECTS, value)
  },

  [TYPES.APP.UPDATE_LANGUAGE](state, language) {
    state.language = language;
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
