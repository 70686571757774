class EventEmitter {
  constructor() {
    this.events = {};
  }

  unsubscribe(eventName, fn) {
    const event = this.events[eventName];
    if (event) {
      this.events[eventName] = this.events[eventName].filter(e => e!==fn);
    }
  }

  emit(eventName, data) {
    const event = this.events[eventName];
    if (event) {
      event.forEach(fn => {
        fn.call(null, data);
      });
    }
  }

  on(eventName, fn) {
    if (!this.events[eventName]) {
      this.events[eventName] = [];
    }

    this.events[eventName].push(fn);
    return () => {
      this.events[eventName] = this.events[eventName].filter(eventFn => fn !== eventFn);
    }
  }
}

export default EventEmitter;
