class UtilsKeys {
  constructor() {
    this.shift = false;

    const handleShiftKey = (event) => {
      const { key, shiftKey } = event;
      if (key === 'Shift') {
        this.shift = shiftKey;
      }
    };

    const reset = () => {
      this.shift = false;
    };

    window.addEventListener('keydown', handleShiftKey);
    window.addEventListener('keyup', handleShiftKey);

    window.addEventListener('blur', reset, { passive: true });
    window.addEventListener('focus', reset, { passive: true });  
  }
}

export default new UtilsKeys()
