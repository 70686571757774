import Provider from "@provider";
import {
  ProjectCalculation
} from "@/models";

export default {
  addProjectCalculation: ({projectId, name, type, amount, formula, hasInvoice}) => {
    return new Promise((resolve, reject) => {
      Provider.api.post(`/projectcalculations/${projectId}`, {
        name, type, formula,
        amount: Number(amount),
        has_invoice: Number(hasInvoice)
      })
        .then(response => resolve(new ProjectCalculation(response.data)))
        .catch(reject);
    });
  },

  getProjectCalculation: ({id}) => {
    return new Promise((resolve, reject) => {
      Provider.api.get(`/projectcalculations/${id}`)
        .then(response => resolve(new ProjectCalculation(response.data)))
        .catch(reject);
    });
  },

  getProjectCalculations: ({projectId}) => {
    return new Promise((resolve, reject) => {
      Provider.api.get(`/projectcalculations/project/${projectId}`, {
        project_id: projectId
      })
        .then(response => resolve(response.data.map(e => new ProjectCalculation(e))))
        .catch(reject);
    });
  },

  updateProjectCalculations: ({id, name, amount, formula, hasInvoice}) => {
    return new Promise((resolve, reject) => {
      Provider.api.put(`/projectcalculations/${id}`, {
        amount: Number(amount),
        name, formula,
        has_invoice: Number(hasInvoice)
      })
        .then(response => resolve(new ProjectCalculation(response.data)))
        .catch(reject);
    });
  },

  calculateFormula({formula, cancelToken}) {
    return new Promise((resolve, reject) => {
      Provider.api.post('projectcalculations/calculate', {
        formula: formula
      }, { cancelToken })
        .then(response => response.data?.error
          ? reject(new Error('The entered formula is not valid'))
          : resolve(response.data)
        )
        .catch(reject);
    });
  },

  deleteProjectCalculations: ({id}) => {
    return Provider.api.delete(`/projectcalculations/${id}`);
  }
};