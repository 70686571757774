import { isBoolean } from 'lodash';
import Provider from '@provider';
import FormModel from '@/models/Form.js';
import FormLogModel from '@/models/FormLog.js';
import FormFieldResponseModel from '@/models/FormFieldResponse.js';

export default {
  search({ search, onlyTemplates, cancelToken }) {
    return Provider.api
      .get('/form', {
        params: {
          title: search || null,
          only_templates: isBoolean(onlyTemplates) ? Number(onlyTemplates) : null,
        },
        cancelToken,
      })
      .then(response =>
        response.data?.data ? response.data.data.map(item => new FormModel(item)) : []
      );
  },

  fetchForms({ cancelToken, checklistItemId } = {}) {
    return Provider.api
      .get('/form', { cancelToken, params: { checklist_item_id: checklistItemId } })
      .then(response =>
        response.data?.data ? response.data.data.map(item => new FormModel(item)) : []
      );
  },

  fetchForm({ formId, cancelToken }) {
    return Provider.api
      .get(`/form/${formId}`, { cancelToken })
      .then(response => (response.data?.data ? new FormModel(response.data.data) : null));
  },

  addForm(data) {
    return Provider.api
      .post('/form', data)
      .then(response => (response.data?.data ? new FormModel(response.data.data) : null));
  },

  updateForm({ id: formId, ...data } = {}) {
    return Provider.api
      .put(`/form/${formId}`, data)
      .then(response => (response.data?.data ? new FormModel(response.data.data) : null));
  },

  removeForm(formId) {
    return Provider.api.delete(`/form/${formId}`);
  },

  duplicateForm({ formId, checklistItemId }) {
    return Provider.api
      .post(`/form/${formId}/duplicate`, { checklist_item_id: checklistItemId })
      .then(response => (response.data?.data ? new FormModel(response.data.data) : null));
  },

  fetchFormLogs(formId) {
    return Provider.api
      .get(`/form/${formId}/logs`)
      .then(response =>
        response.data?.data ? response.data.data.map(item => new FormLogModel(item)) : []
      );
  },

  fetchFormResponse({ formId, cancelToken }) {
    return Provider.api
      .get(`/form/${formId}/response`, { cancelToken })
      .then(r =>
        r.data?.data?.field_responses
          ? r.data.data.field_responses.map(item => new FormFieldResponseModel(item))
          : []
      );
  },

  updateFormResponse({ formId, response }) {
    return Provider.api
      .post(`/form/${formId}/response`, { ...response, form_id: formId })
      .then(r =>
        r.data?.data?.field_responses
          ? r.data.data.field_responses.map(item => new FormFieldResponseModel(item))
          : []
      );
  },

  addFieldResponse({ formId, response }) {
    return Provider.api
      .post(`/form/${formId}/field-response`, {
        ...response,
        form_id: formId,
      })
      .then(r => (r.data?.data ? new FormFieldResponseModel(r.data.data) : null));
  },
};
