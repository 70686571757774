import { addSeconds, differenceInSeconds, isAfter, isBefore, subSeconds, toDate } from "date-fns";
import { endOfBusinessDay, getNextBusinessDay, getPreviousBusinessDay, isBusinessDay, startOfBusinessDay } from "./days";
import { createIntervalGenerator, getIntervalEnd, getIntervalStart } from "./intervals";

export const addBusinessSeconds = (date, seconds, timeOptions) => {
  if (!isBusinessDay(date, timeOptions)) {
    date = startOfBusinessDay(getNextBusinessDay(date, timeOptions), timeOptions);
  }

  const intervalsGenerator = createIntervalGenerator(date, timeOptions);
  let restSeconds = seconds;
  let nextDate = toDate(date);

  while (restSeconds > 0) {
    const { value: interval } = intervalsGenerator.next();
    const intervalStart = getIntervalStart(interval);
    const intervalEnd = getIntervalEnd(interval);

    if (isBefore(nextDate, intervalStart)) {
      nextDate = toDate(intervalStart);
    }

    nextDate = addSeconds(nextDate, restSeconds);
    const difference = differenceInSeconds(nextDate, intervalEnd);

    if (difference > 0) {
      nextDate = toDate(intervalEnd);
      restSeconds = difference;
    } else {
      break;
    }
  }

  return nextDate;
}

export const subBusinessSeconds = (date, seconds, timeOptions) => {
  if (!isBusinessDay(date, timeOptions)) {
    date = endOfBusinessDay(getPreviousBusinessDay(date, timeOptions), timeOptions);
  }

  const intervalsGenerator = createIntervalGenerator(date, timeOptions, true);
  let restSeconds = seconds;
  let nextDate = toDate(date);

  while (restSeconds > 0) {
    const { value: interval } = intervalsGenerator.next();
    const intervalStart = getIntervalStart(interval);
    const intervalEnd = getIntervalEnd(interval);

    if (isAfter(nextDate, intervalEnd)) {
      nextDate = toDate(intervalEnd);
    }

    nextDate = subSeconds(nextDate, restSeconds);
    const difference = differenceInSeconds(intervalStart, nextDate);

    if (difference > 0) {
      nextDate = toDate(intervalStart);
      restSeconds = difference;
    } else {
      break;
    }
  }

  return nextDate;
}

/**
 * @param {[Date, Date]} interval - The interval
 * @param {Object} timeOptions - The time options
 * @returns {Number} Count of business seconds in the interval
 */
export const getBusinessIntervalDuration = (interval, timeOptions) => {
  const intervalsGenerator = createIntervalGenerator(getIntervalStart(interval), timeOptions);

  let duration = 0;
  let startDate = getIntervalStart(interval);
  let endDate = getIntervalEnd(interval);

  while (isBefore(startDate, endDate)) {
    const { value: interval } = intervalsGenerator.next();
    const intervalStart = getIntervalStart(interval);
    const intervalEnd = getIntervalEnd(interval);

    if (isBefore(startDate, intervalStart)) {
      startDate = toDate(intervalStart);
    }

    if (isBefore(intervalEnd, endDate)) {
      const difference = differenceInSeconds(intervalEnd, startDate);
      duration += difference;
    } else if (isAfter(endDate, intervalStart)) {
      const difference = differenceInSeconds(endDate, startDate);
      duration += difference;
      break;
    } else {
      break;
    }
  }

  return duration;
};
