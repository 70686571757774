import Provider from "@provider";
import {
  RegistrationTypeModel
} from "@/models";

export default {
  addRegistrationType: ({name, price, type, unit, groupId}) => {
    return new Promise((resolve, reject) => {
      Provider.api.post(`/registrationtypes/`, {
        name,
        type,
        price,
        unit,
        group_id: groupId
      })
        .then(response => resolve(new RegistrationTypeModel(response.data)))
        .catch(reject);
    });
  },

  getRegistrationTypes: () => {
    return new Promise((resolve, reject) => {
      Provider.api.get(`/registrationtypes/`)
        .then(response => resolve(response.data.map(e => new RegistrationTypeModel(e))))
        .catch(reject);
    });
  },

  getRegistrationType: ({id}) => {
    return new Promise((resolve, reject) => {
      Provider.api.get(`/registrationtypes/${id}`)
        .then(response => resolve(new RegistrationTypeModel(response.data)))
        .catch(reject);
    });
  },

  updateRegistrationType: ({id, name, price, type, unit, groupId}) => {
    return new Promise((resolve, reject) => {
      Provider.api.put(`/registrationtypes/${id}`, {
        name,
        type,
        unit,
        price,
        group_id: groupId
      })
        .then(response => resolve(new RegistrationTypeModel(response.data)))
        .catch(reject);
    });
  },

  deleteRegistrationType: ({id}) => {
    return new Promise((resolve, reject) => {
      Provider.api.delete(`/registrationtypes/${id}`)
        .then(response => resolve(response.data))
        .catch(reject);
    });
  },
}