import Model from '@/models/Model'
import CalculationIntegrationMappingModel from './CalculationIntegrationMapping'

const CALCULATION_TYPES = {
  INITIAL: 'INITIAL',
  CORRECTION: 'CORRECTION',
  EXPENSES: 'EXPENSES',
  REMOTE_INVOICE: 'REMOTE_INVOICE'
}

class ProjectCalculation extends Model {
  constructor(data) {
    super(data)

    this.id = Number(data.id)
    this.projectId = Number(data.project_id)

    this.name = data.name
    this.amount = data.amount
    this.formula = data.formula
    this.hasInvoice = data.has_invoice

    this.type = data.type
    this.isInitial = data.type == CALCULATION_TYPES.INITIAL
    this.isCorrection = data.type == CALCULATION_TYPES.CORRECTION
    this.isExpenses = data.type == CALCULATION_TYPES.EXPENSES

    this.createdAt = this.fixData(data.created_at_utc)

    this.integrationMapping = data.integration_mapping
      ? new CalculationIntegrationMappingModel(data.integration_mapping)
      : null

    this.constituents = data.constituents
  }
}

export { ProjectCalculation, CALCULATION_TYPES }
