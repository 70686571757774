import MaterialsImportsProvider from '@provider/materialsImports'
import * as TYPES from '@/store/types'
import { MaterialsImportFileModel, MaterialImportModel } from '@/models/MaterialsImport'
import { IMPORT_FILE_STATUS, MATERIAL_IMPORT_STATUS } from '@/constants'

const state = {
  imports: [],
  files: []
}

const getters = {
  getFileImports: (state) => (fileId) => state.imports
    .filter((materialImport) => materialImport.fileId === fileId),
  loadingFile: (state) => state.files
    .find(({ status }) => status === IMPORT_FILE_STATUS.PENDING) || null
}

const actions = {
  getMaterialImports({ commit }) {
    return new Promise((resolve, reject) => {
      MaterialsImportsProvider.getMaterialImports()
        .then(imports => {
          commit(TYPES.MATERIALS_IMPORTS.INIT_IMPORTS, imports)
          resolve(imports)
        })
        .catch(reject)
    })
  },
  rollbackFile(_, { fileId }) {
    return MaterialsImportsProvider.rollbackFile(fileId)
  },
  downloadFileById(_, { fileId, fileName }) {
    return MaterialsImportsProvider.downloadProjectImportsFile(fileId, fileName)
  },
  getImportedFiles({ commit }) {
    return new Promise((resolve, reject) => {
      MaterialsImportsProvider.getImportedFiles()
        .then(files => {
          commit(TYPES.MATERIALS_IMPORTS.INIT_FILES, files.reverse())
          resolve(files)
        })
        .catch(reject)
    })
  },
  addMaterialImportsFile({ rootGetters, commit }, { form }) {
    return new Promise((resolve, reject) => {
      MaterialsImportsProvider.addMaterialImportsFile(form)
        .then(file => {
          commit(
            TYPES.MATERIALS_IMPORTS.ADD_FILE,
            new MaterialsImportFileModel(Object.assign(file, {
              company: rootGetters['Companies/meCompany'],
              status: 0,
              user: rootGetters['User/me']
            }))
          )
          resolve(file)
        })
        .catch(reject)
    })
  },
  processImportCsvSocketEvent({ state, commit, dispatch }, materialImport) {
    if (materialImport.status === MATERIAL_IMPORT_STATUS.DELETED) {
      dispatch('removeMaterialImport', materialImport)
    } else if (state.imports.some(({ id }) => id === materialImport.id)) {
      commit(TYPES.MATERIALS_IMPORTS.UPDATE_IMPORT, materialImport)
    } else {
      commit(TYPES.MATERIALS_IMPORTS.ADD_IMPORT, materialImport)
    }
  },
  removeMaterialImport({ getters, commit }, materialImport) {
    commit(TYPES.MATERIALS_IMPORTS.REMOVE_IMPORT, materialImport.id)

    if (getters.getFileImports(materialImport.fileId).length === 0) {
      commit(TYPES.MATERIALS_IMPORTS.REMOVE_FILE, materialImport.fileId)
    }
  }
}

const mutations = {
  [TYPES.MATERIALS_IMPORTS.INIT_IMPORTS](state, imports) {
    state.imports = imports
  },
  [TYPES.MATERIALS_IMPORTS.ADD_IMPORT](state, materialImport) {
    state.imports.push(materialImport)
  },
  [TYPES.MATERIALS_IMPORTS.UPDATE_IMPORT](state, materialImport) {
    state.imports = [
      ...state.imports.filter(({ id }) => id !== materialImport.id),
      materialImport
    ]
  },
  [TYPES.MATERIALS_IMPORTS.REMOVE_IMPORT](state, materialImportId) {
    state.imports = state.imports.filter(({ id }) => id !== materialImportId)
  },
  [TYPES.MATERIALS_IMPORTS.REMOVE_IMPORT_BY_REGISTRATION_TYPE_ID](state, registrationTypeId) {
    state.imports = state.imports.filter((materialImport) => materialImport.registrationTypeId !== registrationTypeId)
  },
  [TYPES.MATERIALS_IMPORTS.INIT_FILES](state, files) {
    state.files = files
  },
  [TYPES.MATERIALS_IMPORTS.ADD_FILE](state, file) {
    state.files.unshift(file)
  },
  [TYPES.MATERIALS_IMPORTS.REMOVE_FILE](state, fileId) {
    state.files = state.files.filter(({ id }) => id !== fileId)
  },
  [TYPES.MATERIALS_IMPORTS.SET_FILE_STATUS](state, { fileId, status }) {
    let file = state.files.find(({ id }) => id === fileId)

    if (file) {
      file.status = status
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
