class UtilsScheduler {
  constructor() {}

  /**
   * Flush microtask queue
   * @returns {Promise}
   */
  flushPromises() {
    return new Promise(resolve => {
      setTimeout(resolve, 0);
    });
  }
}

export default new UtilsScheduler()
