import { nextTick, reactive } from 'vue';
import { SCROLL_RESERVED_WIDTH, SCROLLBAR_WIDTH } from '../common';

const observableDataPlanningScrollbar = reactive({ width: 0, height: 0 });

const sleep = (delay) => new Promise(resolve => setTimeout(resolve, delay));

export default {
  provide: {
    planningScrollbar: observableDataPlanningScrollbar,
  },

  data() {
    return {
      lastCallScrollZoomTime: 0,
      blockScroll: false,
    }
  },

  watch: {
    zoomPlan: {
      deep: true,
      handler() {
        this.initBindScrolls()
      },
    },

    'planningData.zoomView'() {
      this.scrollToTime(this.$moment(this.planningData.timeView))
    },
  },

  methods: {
    getCorrectScrollPosition(position) {
      const { scrollWidth, clientWidth } = this.$refs.planningDataScrollbar;

      const maxPosition = Math.floor(scrollWidth - clientWidth - SCROLL_RESERVED_WIDTH - SCROLLBAR_WIDTH);
      const minPosition = SCROLL_RESERVED_WIDTH;

      const resultPosition = Math.max(
        Math.min(position, maxPosition),
        minPosition
      );

      return resultPosition;
    },

    scrollToTime(val) {
      setTimeout(() => {
        let time = val ? val : this.$moment()
        this.zoomPlan.zmSetCenter(time)

        setTimeout(() => {
          debug('[:] scrollToTime', time.format('DD MMMM YYYY HH:mm'))

          if (!this.$refs.planningDataScrollbar) return;

          const offset = val
            ? 0
            : (this.$refs.planningDataScrollbar.clientWidth / 100) * 15

          let px =
            ((time - this.zoomPlan.startAt) / 1000) * this.dataGenLines.pxTime -
            offset

          px = this.getCorrectScrollPosition(px);

          this.lodash.set(this, '$refs.planningDataScrollbar.scrollLeft', px)
          this.lodash.set(this, '$refs.zoomComponent.$el.scrollLeft', px)

          nextTick(() => {
            this.loadNewData({
              startAt: this.zoomPlan.startAt,
              endAt: this.zoomPlan.endAt,
            })
          })
        }, 200)
      }, 200)
    },

    initBindScrolls() {
      return sleep(125).then(() => {
        observableDataPlanningScrollbar.height = 0
        // observableDataPlanningScrollbar.width = 0

        nextTick(() => {
          observableDataPlanningScrollbar.height = this.lodash.get(
            this,
            '$refs.planningSidebarScrollbar.scrollHeight',
            0
          )
          observableDataPlanningScrollbar.width = this.lodash.get(
            this,
            '$refs.zoomComponent.$el.scrollWidth',
            0
          )
        })
      });
    },

    syncAllScrollPositions() {
      const planning = this.lodash.get(this, '$refs.planningDataScrollbar', null);
      if (planning) {
        this.lodash.set(this, '$refs.planningDataScrollbar.scrollTop', planning.scrollTop)
        this.lodash.set(this, '$refs.zoomComponent.$el.scrollLeft', planning.scrollLeft)
      }
    },

    handlePlanningResize() {
      this.initBindScrolls()
        .then(() => this.syncAllScrollPositions());
    },

    /**
     * On scroll planning sidebar
     */
    handleScrollOnPlanningSidebar(_, el) {
      if (!this.$utils.$mouse.isCollide(el)) return
      this.lodash.set(this, '$refs.planningDataScrollbar.scrollTop', el.scrollTop)
    },

    /**
     * On scroll planning zoom
     */
    handleScrollOnPlanningZoom(_, el) {
      if (!this.$utils.$mouse.isCollide(el)) return
      this.lodash.set(this, '$refs.planningDataScrollbar.scrollLeft', el.scrollLeft)
    },

    scrollSaver() {
      const saveTime = this.dataGenLines.startAt
        .clone()
        .add(
          this.lodash.get(this, '$refs.planningDataScrollbar.scrollLeft') /
            this.dataGenLines.pxTime,
          'seconds'
        )

      return {
        goBack: () => {
          let px =
            ((saveTime - this.dataGenLines.startAt) / 1000) *
            this.dataGenLines.pxTime

          px = this.getCorrectScrollPosition(px);

          this.lodash.set(this, '$refs.planningDataScrollbar.scrollLeft', px)
          this.lodash.set(this, '$refs.zoomComponent.$el.scrollLeft', px)
        },
      }
    },

    handleMouseUpOnPlanningData(event) {
      switch (this.blockScroll) {
        case 'right':
          this.moveGreedNext()
          break

        case 'left':
          this.moveGreedPrev()
          break
      }

      this.blockScroll = false
    },

    /**
     * On scroll planning data
     */
    handleScrollOnPlanningData(_, el) {
      const widthDetect = SCROLL_RESERVED_WIDTH

      // Handle scroll to left
      if (el.scrollLeft < widthDetect) {
        if (this.$utils.$mouse.isPressed) this.blockScroll = 'left'
        else this.moveGreedPrev()
      }

      // Handle scroll to right
      if (el.scrollLeft + el.clientWidth + widthDetect > el.scrollWidth) {
        if (this.$utils.$mouse.isPressed) this.blockScroll = 'right'
        else this.moveGreedNext()
      }

      this.lodash.set(this, '$refs.planningSidebarScrollbar.scrollTop', el.scrollTop);
      this.lodash.set(this, '$refs.zoomComponent.$el.scrollLeft', el.scrollLeft);

      // Handle scroll default
      if (this.$utils.$mouse.isCollide(el)) {
        this.planningData.timeView = this.dataGenLines.startAt
          .clone()
          .add(
            (this.lodash.get(this, '$refs.planningDataScrollbar.scrollLeft') -
              (this.lodash.get(this, '$refs.planningDataScrollbar.clientWidth') /
                100) *
                15) /
              this.dataGenLines.pxTime,
            'seconds'
          )
          .valueOf()

        this.planningData.save()
      }
    },

    moveGreedPrev() {
      let scrollSaver = this.scrollSaver()
      let el = this.lodash.get(this, '$refs.planningDataScrollbar')
      el.style.overflow = 'hidden'

      this.zoomPlan.zmPrev()

      nextTick(() => {
        scrollSaver.goBack()
        el.style.overflow = 'auto'
        const { startAt, endAt } = this.zoomPlan
        this.loadNewData({ startAt, endAt })
      })
    },

    moveGreedNext() {
      let scrollSaver = this.scrollSaver()
      let el = this.lodash.get(this, '$refs.planningDataScrollbar')
      el.style.overflow = 'hidden'

      this.zoomPlan.zmNext()

      nextTick(() => {
        scrollSaver.goBack()
        el.style.overflow = 'auto'
        const { startAt, endAt } = this.zoomPlan
        this.loadNewData({ startAt, endAt })
      })
    },
  },

  mounted() {
    this.initBindScrolls()
    this.scrollToTime(this.$moment(this.planningData.timeView))
  },

  events: {
    'planning:scroll-today'() {
      this.scrollToTime()

      this.planningData.timeView = this.$moment().valueOf()
      this.planningData.save()
    },
    'planning:scroll-to'(momentDate) {
      this.planningData.timeView = momentDate.valueOf();
      this.planningData.save();

      this.scrollToTime(momentDate);
    },
  },
}
