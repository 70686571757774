import Provider from "@provider";
import {UserInvitedModel} from "@/models";

export default {
  resendInvite: ({token}) => {
    return new Promise((resolve, reject) => {
      Provider.api.post(`/invitemembers/${token}/resend`)
        .then(response => resolve(new UserInvitedModel(response.data)))
        .catch(reject);
    });
  },

  getInvitesChat: ({chatId, projectId}) => {
    return new Promise((resolve, reject) => {
      Provider.api.get(`/invitemembers/chat/${chatId}/project/${projectId}`)
        .then(response => resolve(response.data.map(e => new UserInvitedModel(e))))
        .catch(reject);
    });
  },

  getInvitesCompany: ({companyId}) => {
    return new Promise((resolve, reject) => {
      Provider.api.get(`/invitemembers/company/${companyId}`)
        .then(response => resolve(response.data.map(e => new UserInvitedModel(e))))
        .catch(reject);
    });
  },

  deleteInvite: ({token}) => {
    return Provider.api.delete(`/invitemembers/${token}`);
  }
};