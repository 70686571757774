export const targetPreset = Object.fromEntries(
  Array.from({ length: 7 }, (_, index) => [
    index,
    [0,6].includes(index)
      ? null
      : ['06:00', '18:00']
  ])
);

export const fullTimePreset = Object.fromEntries(
  // if there is a need to set the end time of a day correctly
  // Array.from({ length: 7 }, (_, index) => [index, ['00:00:00', '23:59:59']]),

  Array.from({ length: 7 }, (_, index) => [index, ['00:00:00', '24:00:00']]),
);

// with breaks
export const differencePreset = {
  0: ['00:15', '03:20', '04:30',  '06:40'],
  1: null,
  2: null,
  3: ['00:30:00', '06:00:00'],
  4: ['00:30:00', '06:00:00'],
  5: ['00:30:00', '06:00:00'],
  6: ['00:30:00', '06:00:00'],
};
