import { format as _format } from "date-fns";
import Store from "@/store";
import { resolveDateFnsLocale } from "./_defaults";

// get a date-fns locale reactively
const getDateFnsLocale = () => {
  const { language } = Store.state.App;
  return resolveDateFnsLocale(language);
};

// format a date using the Vue reactive system
export const format = (date, format, options = {}) => _format(date, format, { locale: getDateFnsLocale(), ...options });
