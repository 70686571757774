import Quill from 'quill';
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'

import MagicUrl from 'quill-magic-url';

import './formats/emoji'

import { QuillMentionModule } from './mention/module';
import './mention/style.scss';

import QuillEditor from './QuillEditor.vue';

Quill.register('modules/mention', QuillMentionModule);
Quill.register('modules/magicUrl', MagicUrl);

import './fix-tooltip-position.js';

var toolbarOptions = {
  container: [
    ['bold', 'italic', 'underline', 'strike'],
    // ['blockquote', 'code-block'],

    [{ header: 1 }, { header: 2 }],
    [{ list: 'ordered' }, { list: 'bullet' }],
    // [{ script: 'sub' }, { script: 'super' }],
    // [{ indent: '-1' }, { indent: '+1' }],
    // [{ direction: 'rtl' }],

    // [{ size: ['small', false, 'large', 'huge'] }],
    // [{ header: [1, 2, 3, 4, 5, 6, false] }],

    // [{ color: [] }, { background: [] }],
    // [{ font: [] }],
    // [{ align: [] }],

    // ['clean'],
  ],

  handlers: {
    link: function(value) {
      if (value) {
        var href = prompt('Enter the URL')
        this.quill.format('link', href)
      } else {
        this.quill.format('link', false)
      }
    },
  },
};

export const modules = {
  toolbar: toolbarOptions,

  keyboard: {
    bindings: {
      // tab: false,
      handleEnter: {
        key: 13,
        handler: function() {},
      },

      shiftEnter: {
        key: 13,
        shiftKey: true,
        handler: function() {},
      },
    },
  },
};

const globalOptions = {
  // formats: ['emoji', 'break'],

  modules,
};

export const installQuillEditorPlugin = app => {
  QuillEditor.props.globalOptions.default = () => globalOptions;
  app.component(QuillEditor.name, QuillEditor);
};
