import Model from '@/models/Model'
import ChecklistItem from '@/models/ChecklistItem'
import ChatModel from '@/models/Chat'

class ChecklistModel extends Model {
  constructor(data) {
    super(data)
    this.canCreateItem = Boolean(data.can_create_item)
    this.canCreate = Boolean(data.can_create_item)

    this.id = Number(data.id)
    this.name = data.name
    this.projectId = Number(data.project_id)
    this.chatId = Number(data.chat_id)

    // the project to which this checklist relates
    this.mainProjectId = data.main_project_id ? Number(data.main_project_id) : null

    this.items = data.checklistitems
      ? data.checklistitems.map(e => new ChecklistItem(e))
      : []
    this.hiddenItems = false

    this.isClosed = Boolean(data.open_close_state);

    this.favourite = Boolean(data.favourite)
    this.access = Boolean(data.access)

    this.position = Number(data.position)
    this.permissions = {
      canDelete: Boolean(data.permissions?.can_delete),
      canEdit: Boolean(data.permissions?.can_edit),
      canExport: Boolean(data.permissions?.can_export),
      canSaveDescription: Boolean(data.permissions?.can_save_description),
      canView: Boolean(data.permissions?.can_view),
    }

    this.chat = data.chat ? new ChatModel(data.chat) : null
    this.project = data.project ? new ChatModel(data.project) : null

    this.signature = data.signature
    this.signatureUrl = data.signature_url
    this.signatureUserId = data.signature_user_id
    this.clientSignature = data.client_signature
    this.clientSignatureUrl = data.client_signature_url
    this.clientSignatureUser = data.client_signature_user

    this.exportCoverImageUrl = data.export_cover_image_url ?? null
  }
}

export default ChecklistModel
