export const getDefaultWorkingHours = () => Object.fromEntries(
  Array.from({ length: 7 }, (_, index) => [
    index,
    index > 0 && index < 6 ? ['00:00', '23:59'] : null,
  ]),
);

export const constructWorkingHours = rawData => Object.fromEntries(
  Array.from({ length: 7 }, (_, index) => [index, rawData[index]]),
);
