import { keyBy } from 'lodash'
import CompaniesProvider from '@provider/companies'
import * as TYPES from '@/store/types'
import LocalStorageWrapper from '@/plugins/localStorageWrapper'

const THEME_KEY = 'theme';

// initial state
const state = {
  company: LocalStorageWrapper.getObj(THEME_KEY, null),
  companyMembers: [],
}

// getters
const getters = {
  meCompanyMembers: state => state.companyMembers,

  meCompanyMembersMap: (_state, getters) => keyBy(getters.meCompanyMembers, ({ id }) => id),

  meCompany: state => state.company,

  getCompanyMembersByIds: (_state, getters) =>
    userIds => userIds.reduce(
      (users, userId) => {
        const user = getters.meCompanyMembersMap[userId];

        user && users.push(user);

        return users;
      },
      [],
    ),
}

// actions
const actions = {
  updateLogo: ({ state, dispatch }, { form }) => {
    return new Promise((resolve, reject) => {
      CompaniesProvider.updateLogo({
        companyId: state.company.id,
        form,
      })
        .then(company => {
          dispatch('setCompany', company);
          resolve(company)
        })
        .catch(reject)
    })
  },

  updateCompany({ dispatch }, form) {
    return new Promise((resolve, reject) => {
      CompaniesProvider.updateCompany(form)
        .then(company => {
          dispatch('setCompany', company);
          resolve(company)
        })
        .catch(reject)
    })
  },

  getCompany({ dispatch }, companyId) {
    return new Promise((resolve, reject) => {
      CompaniesProvider.getCompany(companyId)
        .then(company => {
          dispatch('setCompany', company);
          resolve(company)
        })
        .catch(reject)
    })
  },

  getCompanyMembers({ commit }, { companyId, search }) {
    return new Promise((resolve, reject) => {
      CompaniesProvider.getCompanyMembers({ companyId, search })
        .then(members => {
          if (!search) {
            commit(TYPES.COMPANIES.INIT_COMPANY_MEMBERS, members)
          }

          resolve(members)
        })
        .catch(reject)
    })
  },

  companyInviteExternalUser({ commit }, { companyId, form }) {
    return CompaniesProvider.companyInviteExternalUser({ companyId, form })
      .then(invite => {
        // if a user can invite an user, he also can delete it or resend invite for it
        invite.canDelete = true;
        invite.canEdit = true;
        commit(TYPES.INVITEMEMBERS.MOD.INIT_INVITES, invite, { root: true });
      });
  },

  deleteCompanyMember({ commit }, { companyId, memberId }) {
    return CompaniesProvider.deleteCompanyMember({ companyId, memberId })
      .then(() => {
        commit(TYPES.COMPANIES.DELETE_COMPANY_MEMBER, memberId);
      });
  },

  updatePlanning({ dispatch }, form) {
    return new Promise((resolve, reject) => {
      CompaniesProvider.updatePlanning(form)
        .then(company => {
          dispatch('setCompany', company);
          resolve(company)
        })
        .catch(reject)
    })
  },

  setCompany({ commit }, company) {
    const {
      customProjectsLogo,
      customColorHoverColor,
      customColorLogoProjects,
      customColorTeamchatsColor,
      customColorProjectsChatsSearch,
    } = company;

    LocalStorageWrapper.setObj(THEME_KEY, {
      customProjectsLogo,
      customColorHoverColor,
      customColorLogoProjects,
      customColorTeamchatsColor,
      customColorProjectsChatsSearch,
    });

    commit(TYPES.COMPANIES.INIT_COMPANY, company)
  },

  updateWorkingHours({ state, dispatch }, { workingHours }) {
    return CompaniesProvider.updateWorkingHours({
      id: state.company.id,
      workingHours,
    })
      .then(company => {
        dispatch('setCompany', company);
      });
  },
}

// mutations
const mutations = {
  [TYPES.COMPANIES.INIT_COMPANY](state, company) {
    state.company = company
  },

  [TYPES.COMPANIES.INIT_COMPANY_MEMBERS](state, data) {
    state.companyMembers = data
  },

  [TYPES.COMPANIES.DELETE_COMPANY_MEMBER](state, memberId) {
    state.companyMembers = state.companyMembers.filter(user => user.id !== memberId);
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
