import * as TYPES from '@/store/types'
import FilesProvider from '@provider/files'

// initial state
const state = {
  files: [],
  cancellations: [],
}

// getters
const getters = {
  getterFilesByChatId: state => ({ id, limit = Infinity }) => {
    return state.files
      .filter(e => e.chatId == id)
      .sort((a, b) => b.id - a.id)
      .slice(0, limit)
  },

  getterFilesByProjectId: state => id => {
    return state.files
      .filter(e => e.projectId == id && !e.chatId)
      .sort((a, b) => b.id - a.id)
  },
}

// actions
const actions = {
  cancelFile({ state, commit }, id) {
    let cancelQ = state.cancellations.find(e => e.id == id)

    if (cancelQ) {
      cancelQ.cancel()
      commit('REMOVE_CANCEL', id)
    }
  },

  addFile({ state, commit }, { form, progress, cancelToken }) {
    return new Promise((resolve, reject) => {
      let query = FilesProvider.addFile({ form, progress })

      query.callback(
        file => {
          commit('REMOVE_CANCEL', cancelQ)
          commit('INIT_FILES', file)
          resolve(file)
        },
        error => {
          commit('REMOVE_CANCEL', cancelQ)
          reject(error)
        }
      )

      const cancelQ = cancelToken
        ? { id: cancelToken, cancel: query.cancel }
        : null

      commit('ADD_CANCEL', cancelQ)
    })
  },

  addBulkFile({ state, commit }, { form, progress, cancelToken }) {
    return new Promise((resolve, reject) => {
      let query = FilesProvider.addBulkFile({ form, progress })

      query.callback(
        files => {
          commit('REMOVE_CANCEL', cancelQ)
          commit('INIT_FILES', files)
          resolve(files)
        },
        error => {
          commit('REMOVE_CANCEL', cancelQ)
          reject(error)
        }
      )

      const cancelQ = cancelToken
        ? { id: cancelToken, cancel: query.cancel }
        : null

      commit('ADD_CANCEL', cancelQ)
    })
  },

  deleteFile({ commit }, fileId) {
    return FilesProvider.deleteFile(fileId)
      .then(() => {
        commit(TYPES.FILES.DELETE_FILES, fileId);
      });
  },

  downloadFileById({ state, commit }, fileId) {
    return new Promise((resolve, reject) => {
      const file = state.files.find(e => e.id == fileId)
      if (!file) reject()

      FilesProvider.downloadFile({
        file,
        progress: percent => {
          commit(TYPES.FILES.UPDATE_PERCENT_DOWNLOAD, {
            fileId,
            percent,
          })
        },
      })
        .then(resolve)
        .catch(reject)
    })
  },

  downloadFile({}, { file, progress }) {
    return new Promise((resolve, reject) => {
      FilesProvider.downloadFile({ file, progress })
        .then(resolve)
        .catch(reject)
    })
  },

  updateFile({ commit }, { id, folderId, fileName, access }) {
    return new Promise((resolve, reject) => {
      FilesProvider.updateFile({ id, folderId, fileName, access })
        .then(file => {
          commit('INIT_FILES', file)
          resolve(file)
        })
        .catch(reject)
    })
  },

  addFileLink({ commit }, form) {
    return new Promise((resolve, reject) => {
      FilesProvider.addFileLink(form)
        .then(file => {
          commit('INIT_FILES', file)
          resolve(file)
        })
        .catch(reject)
    })
  },

  updateFileLink({ commit }, { id, form }) {
    return new Promise((resolve, reject) => {
      FilesProvider.updateFileLink(id, form)
        .then(file => {
          commit('INIT_FILES', file)
          resolve(file)
        })
        .catch(reject)
    })
  },
}

// mutations
const mutations = {
  [TYPES.FILES.INIT_FILES](state, data) {
    let files = Array.isArray(data) ? data : [data],
      ids = files.map(e => e.id)

    state.files = [
      ...state.files.filter(e => !ids.includes(e.id)),
      ...files.filter(e => e.canView).filter(e => !e.isDeleted),
    ]
  },

  [TYPES.FILES.DELETE_FILES](state, data) {
    const deletedFileIds = Array.isArray(data) ? data : [data];
    state.files = state.files.filter(file => !deletedFileIds.includes(file.id));
  },

  ADD_CANCEL(state, cancelQ) {
    if (cancelQ) {
      state.cancellations.push(cancelQ)
    }
  },
  REMOVE_CANCEL(state, id) {
    if (id) {
      state.cancellations = state.cancellations.filter(e => e !== id)
    }
  },

  [TYPES.FILES.UPDATE_PERCENT_DOWNLOAD](state, { fileId, percent }) {
    let file = state.files.find(e => e.id == fileId)
    if (!file) return

    file.percentDownload = percent
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
