const getParentNodes = (node) => {
  const parentNodes = [];

  let parentNode = node.parentNode;

  while (parentNode !== null && parentNode !== window.document.body) {
    parentNodes.push(parentNode);
    parentNode = parentNode.parentNode;
  }

  return parentNodes;
};

const getStyleByProperty = (node, property) => getComputedStyle(node, null).getPropertyValue(property);

const getOverflowValues = node => [
  getStyleByProperty(node, 'overflow'),
  getStyleByProperty(node, 'overflow-y'),
  getStyleByProperty(node, 'overflow-x'),
];

const checkScrollable = (node, withHidden) => {
  const overflowValues = getOverflowValues(node);
  const regexp = withHidden ? /(auto|scroll|hidden)/ : /(auto|scroll)/;
  return regexp.test(overflowValues.join(''));
};

export const getScrollableParent = (node, withHidden = false) => {
  if (!(node instanceof HTMLElement || node instanceof SVGElement)) {
    return;
  }

  const parentNodes = getParentNodes(node);
  const scrollableParent = parentNodes.find((node) => checkScrollable(node, withHidden));
  return scrollableParent ?? window.document.scrollingElement ?? window.document.documentElement;
};
