import * as Sentry from '@sentry/vue'
import TRANSACTION_ID from './transactionId'

class SentryManager {
  constructor(props) {
    this.config = {
      dsn: props.dsn,
      app: props.app,
    }

    this.active = this.config.dsn && this.config.dsn?.trim();
  }

  boot() {
    if (!this.active) return null

    Sentry.init({
      app: this.config.app,
      dsn: this.config.dsn,
      integrations: [Sentry.browserTracingIntegration()],
      // Performance Monitoring
      tracesSampleRate: 1.0, //  Capture 100% of the transactions
    });
  }

  bindEmail(email = 'anonymous') {
    if (!this.active) return null

    Sentry.setUser({ email });
    Sentry.setTag('transaction_id', TRANSACTION_ID);
    debug(`[+] Bind sentry user email: ${email}`);
  }
}

export default {
  install(app, config) {
    const sentryManager = new SentryManager({ ...config, app });
    sentryManager.boot();

    app.config.globalProperties.$sentry = sentryManager;
  },
}
