import OtherProvider from '@provider/other'

// initial state
const state = {}

// getters
const getters = {}

// actions
const actions = {
  getOnboardingQuestions() {
    return OtherProvider.getOnboardingQuestions()
  },

  search({}, search) {
    return OtherProvider.search({ search })
  },
}

// mutations
const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
