<template>
  <content-placeholders
    class="checklist-placeholder"
    :class="{ 'checklist-placeholder--compact': props.compact }"
    :kind="props.compact ? undefined : 'info'"
  >
    <content-placeholders-img class="checklist-placeholder__image" />
    <content-placeholders-text :lines="1" class="checklist-placeholder__text" />
    <content-placeholders-img class="checklist-placeholder__menu" />
    <content-placeholders-text :lines="1" class="checklist-placeholder__button" />
  </content-placeholders>
</template>

<script setup>
defineOptions({
  name: 'ChecklistPlaceholder',
});

const props = defineProps({
  compact: Boolean,
});
</script>

<style lang="scss">
.checklist-placeholder {
  display: flex;
  flex-wrap: wrap;

  .checklist-placeholder__menu,
  .checklist-placeholder__image {
    width: 1em;
    height: 1em;
    font-size: 1.3em;
    margin: auto 0.3em auto 0em;
  }
  .checklist-placeholder__menu {
    height: 2.3em;
    width: 2.3em;
    font-size: 0.9em;
    border-radius: 10em;
    margin-left: auto;
    margin-right: 0;
  }
  .checklist-placeholder__text {
    flex: 0 1 30%;
    margin-top: 0px;

    .vue-content-placeholders-text__line {
      border-radius: 3px;
      height: 30px;
      margin-bottom: 0;
    }
  }
  .checklist-placeholder__button {
    flex: 0 1 100%;
    margin-top: 1em;

    .vue-content-placeholders-text__line {
      border-radius: 0.2rem;
      height: 2.6em;
      margin-bottom: 0;
      width: 130px;
      font-size: 0.8em;
      padding: 0.5em 1em;
    }
  }
}
.checklist-placeholder--compact {
  padding: 1.5em 2em 0em 2em;

  .checklist-placeholder__text {
    flex: 0 1 70%;
  }
}
</style>
