export const installClickOutsidePlugin = app => {
  app.directive('click-outside', {
    created: function (el, binding, vNode) {
      // Provided expression must evaluate to a function.
      if (typeof binding.value !== 'function') {
        const compName = vNode.context.name;
        let warn = `[Vue-click-outside:] provided expression "${binding.expression}" is not a function, but has to be`;
        if (compName) {
          warn += `Found in component "${compName}"`;
        }

        debug('[!]', warn);
      }

      // Define Handler and cache it on the element
      const bubble = binding.modifiers.bubble;
      const handler = e => {
        if (bubble || (!el.contains(e.target) && el !== e.target)) {
          binding.value(e);
        }
      };
      el.__vueClickOutside__ = handler;

      // add Event Listeners
      document.addEventListener('mousedown', handler);
    },

    unmounted: function (el, binding) {
      // Remove Event Listeners
      document.removeEventListener('mousedown', el.__vueClickOutside__);
      el.__vueClickOutside__ = null;
    },
  });

  app.directive('mousemove-outside', {
    created: function (el, binding, vNode) {
      // Provided expression must evaluate to a function.
      if (typeof binding.value !== 'function') {
        const compName = vNode.context.name;
        let warn = `[Vue-click-outside:] provided expression "${binding.expression}" is not a function, but has to be`;
        if (compName) {
          warn += `Found in component "${compName}"`;
        }

        debug('[!]', warn);
      }

      // Define Handler and cache it on the element
      const bubble = binding.modifiers.bubble;
      const handler = e => {
        if (bubble || (!el.contains(e.target) && el !== e.target)) {
          binding.value(e);
        }
      };
      el.__vueClickOutside__ = handler;

      // add Event Listeners
      document.addEventListener('mousemove', handler);
    },

    unmounted: function (el, binding) {
      // Remove Event Listeners
      document.removeEventListener('mousemove', el.__vueClickOutside__);
      el.__vueClickOutside__ = null;
    },
  });
};
