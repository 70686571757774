import CommentsProvider from '@provider/comments'
import ChecklistItemsProvider from '@provider/checklistItems'
import * as TYPES from '@/store/types'

// initial state
const state = {
  comments: [],
}

// getters
const getters = {
  getterCommentsByCheckListItemId: state => ({ id, limit = 3 }) => {
    return state.comments
      .filter(e => e.checklistItemId === id)
      .sort((a, b) => b.id - a.id)
      .slice(0, limit)
  },

  getterCommentsByProjectId: state => ({ id, limit = 3 }) => {
    return state.comments
      .filter(e => e.projectId === id)
      .sort((a, b) => b.id - a.id)
      .slice(0, limit)
  },

  getterCommentsCountByProjectId: state => projectId => {
    return state.comments.filter(e => e.projectId == projectId).length
  },
}

// actions
const actions = {
  getChecklistItemComments: (
    { commit },
    { offset, limit, checklistId, checklistItemId }
  ) => {
    return new Promise((resolve, reject) => {
      ChecklistItemsProvider.getChecklistItemComments({
        offset,
        limit,
        checklistId,
        checklistItemId,
      })
        .then(list => {
          commit(TYPES.COMMENTS.INIT_COMMENTS, list)
          resolve(list)
        })
        .catch(reject)
    })
  },

  addComment({ commit }, { projectId, comment, checklistItemId }) {
    return new Promise((resolve, reject) => {
      CommentsProvider.addComment({ projectId, comment, checklistItemId })
        .then(data => {
          commit(TYPES.COMMENTS.INIT_COMMENTS, data)
          resolve(data)
        })
        .catch(reject)
    })
  },

  getComment({ commit }, { id }) {
    return new Promise((resolve, reject) => {
      CommentsProvider.getComment({ id })
        .then(data => {
          commit(TYPES.COMMENTS.INIT_COMMENTS, data)
          resolve(data)
        })
        .catch(reject)
    })
  },

  updateComment({ commit }, { id, comment }) {
    return new Promise((resolve, reject) => {
      CommentsProvider.updateComment({ id, comment })
        .then(data => {
          commit(TYPES.COMMENTS.INIT_COMMENTS, data)
          resolve(data)
        })
        .catch(reject)
    })
  },

  deleteComment({ commit }, { id }) {
    return new Promise((resolve, reject) => {
      CommentsProvider.deleteComment({ id })
        .then(data => {
          commit(TYPES.COMMENTS.DELETE_COMMENTS, id)
          resolve(data)
        })
        .catch(reject)
    })
  },
}

// mutations
const mutations = {
  [TYPES.COMMENTS.INIT_COMMENTS](state, data) {
    let comments = Array.isArray(data) ? data : [data],
      ids = comments.map(e => e.id)

    state.comments = [
      ...state.comments.filter(e => !ids.includes(e.id)),
      ...comments.filter(e => e.canView),
    ]
  },

  [TYPES.COMMENTS.DELETE_COMMENTS](state, data) {
    let ids = Array.isArray(data) ? data : [data]

    state.comments = state.comments.filter(e => !ids.includes(e.id))
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
