import Provider from '@provider';

import PdfImportTemplateModel from '@/models/PdfImportTemplate.js';
import helpers from '@/helpers/index.js';

export default {
  fetchPdfTemplates({ cancelToken } = {}) {
    return Provider.api
      .get('/imports/pdf/templates', { cancelToken })
      .then(({ data }) =>
        Array.isArray(data) ? data.map(item => new PdfImportTemplateModel(item)) : []
      );
  },

  /**
   * @param {object} param
   * @param {Blob[]} param.files
   * @param {'project' | 'checklist'} param.type
   * @param {(...args: any) => any | undefined} param.onUploadProgress
   */
  uploadFiles({ files, type, onUploadProgress } = {}) {
    const data = helpers.createForm({ files });

    return Provider.api
      .post(`/imports/pdf/${type}`, data, { onUploadProgress })
      .then(({ data }) => data);
  },

  processProjectsImport({ fileIds, statusId, userIds, projectId, templateId } = {}) {
    return Provider.api.post('/imports/pdf/project/process', {
      import_file_ids: fileIds,
      options: {
        status_id: statusId,
        user_ids: userIds,
        project_id: projectId,
        template_id: templateId,
      },
    });
  },

  processChecklistsImport({ fileIds, projectId } = {}) {
    return Provider.api.post('/imports/pdf/checklist/process', {
      import_file_ids: fileIds,
      options: {
        project_id: projectId,
      },
    });
  },
};
