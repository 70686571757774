export const hasSignature = checklist => {
  return checklist.signature && checklist.clientSignature;
};

export const getChecklistDateRange = checklist => {
  return checklist.items.reduce(
    (result, checklistItem) => {
      const { startAt, endAt } = checklistItem;

      if (!result.startAt || (startAt && startAt < result.startAt)) {
        result.startAt = startAt?.toDate?.();
      }

      if (!result.endAt || (endAt && endAt > result.endAt)) {
        result.endAt = endAt?.toDate?.();
      }

      return result;
    },
    {
      startAt: null,
      endAt: null,
    }
  );
};

export const filterChecklists = checklists => checklists.filter(e => !e.access);
export const sortChecklists = checklists => checklists.sort((a, b) => a.position - b.position);
