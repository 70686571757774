import Model from '@/models/Model';

class ReferralModel extends Model {
  constructor(data) {
    super(data);

    this.id = Number(data.id);
    this.email = data.email;
    this.created_by = data.created_by;
    this.company_id = data.company_id;
    this.registered_user_id = data.registered_user_id;
    this.status = data.status;
    this.payment_type = data.payment_type;
    this.createdAt = this.fixData(data.created_at);
    this.updatedAt = this.fixData(data.updated_at);
    this.deletedAt = this.fixData(data.deleted_at);
  }
}

export default ReferralModel;
