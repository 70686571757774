import { chunk } from "lodash";
import { zonedTimeToUtc, utcToZonedTime } from "date-fns-tz";
import { differenceInMilliseconds, isAfter, isBefore, isEqual, setHours, setMilliseconds, setMinutes, setSeconds, toDate } from "date-fns";
import { normalizeTime } from './dto.js';
import { getNextBusinessDay, getPreviousBusinessDay, isBusinessDay } from "./days";
import { getBusinessHours } from "./hours";
import { DEFAULT_TIMEZONE } from "./_defaults";

export const getIntervalDate = (date, time, { timezone = DEFAULT_TIMEZONE } = {}) => {
  let [hours, minutes, seconds] = normalizeTime(time);
  let milliseconds = 0;

  // if there is a need to set the end time of a day correctly
  // if (hours === '23' && minutes === '59') {
  //   seconds = 59;
  //   milliseconds = 999;
  // }

  const zonedTime = utcToZonedTime(date, timezone);
  const zonedIntervalTime = setHours(setMinutes(setSeconds(setMilliseconds(zonedTime, milliseconds), seconds), minutes), hours);
  return zonedTimeToUtc(zonedIntervalTime, timezone);
};

export const getIntervals = (date, timeOptions) => {
  if (!isBusinessDay(date, timeOptions)) {
    return null;
  }

  const hoursData = getBusinessHours(date, timeOptions);
  const intervals = hoursData.map((time) => getIntervalDate(date, time, timeOptions));
  return chunk(intervals, 2);
};

export const getFirstInterval = (date, timeOptions) => {
  const intervals = getIntervals(date, timeOptions);
  return intervals?.[0];
};

export const getLastInterval = (date, timeOptions) => {
  const intervals = getIntervals(date, timeOptions);
  return intervals?.[intervals.length - 1];
};

export const getIntervalStart = (interval) => interval?.[0] ?? null;

export const getIntervalEnd = (interval) => interval?.[1] ?? null;

export const getIntervalsDuration = (intervals) => {
  return intervals.reduce((duration, [intervalStart, intervalEnd]) => {
    return duration + differenceInMilliseconds(intervalEnd, intervalStart);
  }, 0);
};

/**
 * Check the date in the interval
 * @param {Date} date
 * @param {Array} interval
 * @returns the date is in the interval
 */
export const checkDateInInterval = (date, interval) => (
  (isEqual(date, getIntervalStart(interval)) || isAfter(date, getIntervalStart(interval)))
  && (isEqual(date, getIntervalEnd(interval)) || isBefore(date, getIntervalEnd(interval)))
);

/**
 * Create the interval generator from the date
 * @param {Date} date - The date from which the intervals will be generated
 * @param {Object} timeOptions - The options of dates and time
 * @param {Boolean} [toLeft=] - If the value is set to `true', it generates from the present to the past
 * @param {Boolean} [skipCurrent=] - If the values is set to `true`, it generates from the next interval from the date
 * @return The interval generator
 */
export function* createIntervalGenerator (date, timeOptions, toLeft = false, skipCurrent = false) {
  let currentDate = toDate(date);

  const IntervalGeneratorDefaults = Object.freeze({
    left: {
      getNextDay: getPreviousBusinessDay,
      getNextInterval: (intervals) => intervals.pop(),
      filterCallback: skipCurrent
        ? interval => isAfter(currentDate, getIntervalEnd(interval))
        : interval => (
          isEqual(currentDate, getIntervalStart(interval))
          || isAfter(currentDate, getIntervalStart(interval))
        ),
    },
    right: {
      getNextDay: getNextBusinessDay,
      getNextInterval: (intervals) => intervals.shift(),
      filterCallback: skipCurrent
        ? interval => isBefore(currentDate, getIntervalStart(interval))
        : interval => (
          isEqual(currentDate, getIntervalEnd(interval))
          || isBefore(currentDate, getIntervalEnd(interval))
        ),
    },
  });

  const { getNextDay, getNextInterval, filterCallback } = toLeft
    ? IntervalGeneratorDefaults.left
    : IntervalGeneratorDefaults.right;

  if (!isBusinessDay(currentDate, timeOptions)) {
    currentDate = getNextDay(currentDate, timeOptions);
  }

  let intervals = getIntervals(currentDate, timeOptions).filter(filterCallback);
  let interval = null;

  while (true) {
    if (!intervals?.length) {
      currentDate = getNextDay(currentDate, timeOptions);
      intervals = getIntervals(currentDate, timeOptions);
    }

    do {
      interval = getNextInterval(intervals);
      yield interval;
    } while (checkDateInInterval(currentDate, interval) && intervals.length)
  }
};

export const getIntervalsDurationInSeconds = (intervals) => {
  const duration = getIntervalsDuration(intervals) / 1000;
  return duration;
};

export const getBusinessDayIntervalDetails = (date, timeOptions) => {
  const intervals = getIntervals(date, timeOptions);
  const duration = getIntervalsDurationInSeconds(intervals);
  const start = getIntervalStart(intervals[0]);
  const end = getIntervalEnd(intervals[intervals.length - 1]);

  return {
    intervals,
    duration,
    start,
    end,
  };
};
