import MessagesProvider from '@provider/messages'
import { MessageModel } from '@/models'

import * as TYPES from '@/store/types'

const storeLocalMessages = {
  STORE_KEY: 'failMessages',

  add(message) {
    try {
      let messages = this.getMessages()

      messages = [
        ...messages.filter(e => e.clientMsgId !== message.clientMsgId),
        message,
      ]

      localStorage.setItem(this.STORE_KEY, JSON.stringify(messages))
    } catch (error) {
      debug(error)
    }
  },
  getMessages() {
    return JSON.parse(localStorage.getItem(this.STORE_KEY) || '[]')
  },
  deleteMessage(clientMsgId) {
    try {
      let messages = this.getMessages()

      messages = messages.filter(e => e.clientMsgId !== clientMsgId)
      localStorage.setItem(this.STORE_KEY, JSON.stringify(messages))
    } catch (error) {
      debug(error)
    }
  },
}

// initial state
const state = {
  chats: [],
  messages: storeLocalMessages.getMessages(),
  draftMessages: [],
}

// getters
const getters = {
  chatMessages: state => id => {
    return state.messages
      .filter(e => e.chatId === Number(id))
      .sort((a, b) => a.id - b.id)
      .sort((a, b) => a.flagFailSend - b.flagFailSend)
  },

  getDraftMessageByChatId: state => chatId =>
    state.draftMessages.find(e => e.chatId == chatId),

  getChatMessage: (state, getters) => (chatId, messageId) => {
    return getters.chatMessages(chatId).find(({ id }) => id === messageId)
  }
}

// actions
const actions = {
  // Draft messages logic

  addDraftMessage({ commit }, { text, chatId, reply }) {
    commit(TYPES.MESSAGES.INIT_DRAFT_MESSAGE, {
      text,
      reply,
      chatId,
    })
  },

  deleteDraftMessage({ commit }, chatId) {
    commit(TYPES.MESSAGES.DELETE_DRAFT_MESSAGE, chatId)
  },

  // Fail message logic

  resendFailMessage({ commit }, { message }) {
    return new Promise((resolve, reject) => {
      MessagesProvider.addMessage(message)
        .then(data => {
          // commit(TYPES.MESSAGES.DELETE_MESSAGES, {clientMsgId: message.clientMsgId});
          commit(TYPES.MESSAGES.DELETE_FAIL_MESSAGE, message.clientMsgId)
          commit(TYPES.MESSAGES.INIT_MESSAGES, data)
          resolve(data)
        })
        .catch(reject)
    })
  },

  addFailMessage(
    { commit },
    { user, isText, message, chatId, clientMsgId, replyToId }
  ) {
    commit(TYPES.MESSAGES.INIT_FAIL_MESSAGE, {
      ...new MessageModel(),
      message,
      chatId: Number(chatId),
      user,
      isText,
      flagFailSend: true,
      text: message,
      canDelete: true,
      clientMsgId,
      replyToId,
    })
  },

  // Message logic

  deleteFailMessage({ commit }, clientMsgId) {
    commit(TYPES.MESSAGES.DELETE_MESSAGES, { clientMsgId })
  },

  addMessage({ commit }, { chatId, type, message, clientMsgId, replyToId }) {
    return new Promise((resolve, reject) => {
      MessagesProvider.addMessage({
        chatId,
        type,
        message,
        clientMsgId,
        replyToId,
      })
        .then(data => {
          commit(TYPES.MESSAGES.INIT_MESSAGES, data)
          resolve(data)
        })
        .catch(reject)
    })
  },

  addBulkMessage({ commit }, { chatIds, type, message, clientMsgId }) {
    return new Promise((resolve, reject) => {
      MessagesProvider.addBulkMessage({
        chatIds,
        type,
        message,
        clientMsgId,
      })
        .then(data => {
          commit(TYPES.MESSAGES.INIT_MESSAGES, data)
          resolve(data)
        })
        .catch(reject)
    })
  },

  deleteMessage({ commit }, messageId) {
    return new Promise((resolve, reject) => {
      commit(TYPES.MESSAGES.TOGGLE_MARK_DELETING, { id: messageId, flag: true })

      MessagesProvider.deleteMessage(messageId)
        .then(update => {
          resolve(update)
        })
        .catch(error => {
          commit(TYPES.MESSAGES.TOGGLE_MARK_DELETING, {
            id: messageId,
            flag: false,
          })
          reject(error)
        })
    })
  },

  getChatMessages({ commit }, { chatId, offset = null, onMessagesLoaded }) {
    return new Promise((resolve, reject) => {
      MessagesProvider.getChatMessages({ chatId, offset })
        .then(list => {
          if (onMessagesLoaded) onMessagesLoaded();
          commit(TYPES.MESSAGES.INIT_MESSAGES, list)
          resolve(list)
        })
        .catch(reject)
    })
  },
}

// mutations
const mutations = {
  // Draft messages logic

  [TYPES.MESSAGES.INIT_DRAFT_MESSAGE](state, message) {
    state.draftMessages = [
      ...state.draftMessages.filter(e => e.chatId != message.chatId),
      message,
    ]
  },

  [TYPES.MESSAGES.DELETE_DRAFT_MESSAGE](state, chatId) {
    state.draftMessages = state.draftMessages.filter(e => e.chatId != chatId)
  },

  // Fail message logic

  [TYPES.MESSAGES.INIT_FAIL_MESSAGE](state, message) {
    state.messages.push(message)
    storeLocalMessages.add(message)
  },

  [TYPES.MESSAGES.DELETE_FAIL_MESSAGE](state, clientMsgId) {
    storeLocalMessages.deleteMessage(clientMsgId)
    state.messages = state.messages.filter(e.clientMsgId != clientMsgId)
  },

  // Message logic

  [TYPES.MESSAGES.DELETE_MESSAGES](state, { clientMsgId }) {
    const idsClients = Array.isArray(clientMsgId)
      ? clientMsgId
      : clientMsgId
      ? [clientMsgId]
      : []

    if (idsClients.length > 0) {
      state.messages = state.messages.filter(
        e => !idsClients.includes(e.clientMsgId)
      )
    }
  },

  [TYPES.MESSAGES.INIT_MESSAGES](state, data) {
    const messages = Array.isArray(data) ? data : [data],
      idsClients = messages.map(e => {
        if (e.clientMsgId) {
          return e.clientMsgId
        }
      }),
      ids = messages.map(e => e.id)

    state.messages = [
      ...state.messages
        .filter(e => !ids.includes(e.id))
        .filter(e => !idsClients.includes(e.clientMsgId)),
      ...messages,
    ]
  },

  [TYPES.MESSAGES.TOGGLE_MARK_DELETING](state, { flag = true, id }) {
    let message = state.messages.find(e => e.id == id)

    if (message) {
      message.flagDeleting = flag
    }
  },

  [TYPES.MESSAGES.DELETE_MESSAGE](state, messageId) {
    let message = state.messages.find(e => e.id === messageId)

    if (message) {
      message.isDeleted = true
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
