import Model from './Model.js';
import MediaFileModel from './MediaFile.js';

export class FormFieldResponseModel {
  constructor(data) {
    this.id = data.id;

    this.createdAt = data.created_at
      ? Model.prototype.toMomentDate.call(this, data.created_at).toDate()
      : null;

    this.updatedAt = data.updated_at
      ? Model.prototype.toMomentDate.call(this, data.updated_at).toDate()
      : null;

    this.deletedAt = data.deleted_at
      ? Model.prototype.toMomentDate.call(this, data.deleted_at).toDate()
      : null;

    this.attachments = data.attachments?.map(attachment => new MediaFileModel(attachment)) ?? [];

    this.value = data.value;
    this.options =
      typeof data.options === 'string' && data.options.trim()
        ? data.options.split(',').map(id => Number(id))
        : null;

    this.formFieldId = data.form_field_id;
  }
}

export default FormFieldResponseModel;
