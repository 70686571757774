import _ from 'lodash';

export default {
  viewSettingForms: ({ $store, $router }) => {
    const formsEnabled = _.get($store, 'state.Companies.company.formsEnabled', false);

    if (formsEnabled) return;

    $router.push({ name: 'dashboard' });

    throw `Don't access to forms`;
  },

  canUseForms: ({ $store, $router }) => {
    const canUseForms = _.get($store, 'getters.canUseForms', false);

    if (canUseForms) return;

    $router.push({ name: 'dashboard' });

    throw `Can't use the forms feature`;
  },
};
