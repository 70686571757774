import { isString } from 'lodash';

export const parseIntegerArrayParameter = (value) => {
  return value.split(',').map(number => Number(number.trim()));
}

export const isIntegerArrayParameter = (value) => {
  try {
    return isString(value) && parseIntegerArrayParameter(value).every(number => Number.isInteger(number));
  } catch {
    return false;
  }
}

export const parseHash = hash => {
  if (hash[0] === '#') {
    hash = hash.substring(1);
  }

  const parametersParts = hash.split('&');
  const parametersEntries = parametersParts.map(part => {
    const [key, value] = part.split('=');
    return [key, value];
  });

  return Object.fromEntries(parametersEntries.filter(([_, value]) => value !== undefined));
};
