import Moment from 'moment'
import i18n from '@/i18n'

class Duedate {
  combineDate(val, defTime) {
    if (!val) return ''

    const { date, time } = val
    if (!date) return ''
    const strTime = time ? time.format('HH:mm') : defTime

    return date.format('YYYY-MM-DD') + ' ' + strTime
  }

  getRemindByDate(m1, m2) {
    const d = Moment.duration(m1.diff(m2))

    if (d.days()) return `${d.days()}d`
    if (d.hours()) return `${d.hours()}h`
    if (d.minutes()) return `${d.minutes()}min`
    if (d.seconds() == 0) return 'expiry'

    return null
  }

  toFormat(t, ignore = ['00:00', '23:59']) {
    ignore = Array.isArray(ignore) ? ignore : [ignore]

    let res = {
      short: '',
      full: '',
    }

    if (!t) return res

    let date = t.format('DD MMM'),
      year = Moment().year() != t.year() ? t.format('YYYY') : '',
      time = t.format('HH:mm')

    ignore.forEach(e => {
      time = time.replace(e, '')
    })

    time = time ? `${i18n.global.t('atTime')} ${time}` : time

    res.short = date
    res.full = [date, year, time].join(' ')

    return res
  }

  /**
   * Get css classes for entity duedate
   *
   * @param {Moment} completedAt
   * @param {Moment} endAt
   */
  getClasses({ completedAt, endAt, checked }) {
    return {
      completed: completedAt || checked,
      overdue: Moment() > endAt,
      warning: Moment()
        .add(24, 'h')
        .isAfter(endAt),
    }
  }

  get remindTimes() {
    return {
      null: () => null,
      expiry: val => val,
      '5min': val => val.subtract(5, 'm'),
      '10min': val => val.subtract(10, 'm'),
      '15min': val => val.subtract(15, 'm'),
      '1h': val => val.subtract(1, 'h'),
      '2h': val => val.subtract(2, 'h'),
      '1d': val => val.subtract(1, 'd'),
      '2d': val => val.subtract(2, 'd'),
    }
  }

  get listRemind() {
    return [
      null,
      'expiry',
      '5min',
      '10min',
      '15min',
      '1h',
      '2h',
      '1d',
      '2d',
    ].map(key => {
      return {
        id: key,
        placeholder: i18n.global.t(`remind.${key}`),
      }
    })
  }

  /**
   * @param {Object} param0
   * @param {import('moment').Moment} param0.startAt
   * @param {import('moment').Moment} param0.endAt
   * @param {Number} param0.minPeriodInSeconds
   * @returns {Boolean} true or false
   */
  validateDuedate({
    startAt,
    endAt,
    minPeriodInSeconds = 900
  }) {
    return endAt >= startAt.clone().add(minPeriodInSeconds, 'seconds');
  }

  /**
   * @param {Object} param0 - Invalid duedate parameters
   * @param {import('moment').Moment} param0.time - Current invalid start time
   * @param {import('moment').Moment} param0.date - Current invalid start date
   * @param {Object} [param0.minPeriod] - Parameters of a minimum difference period
   * @param {Number} [param0.minPeriod.duration=1] - Duration of units
   * @param {import('moment').unitOfTime.DurationAs} [param0.minPeriod.units="days"] - Units of period duration
   * @returns {import('moment').Moment} Valid start at datetime
   */
  getValidStartAt({
    time,
    date,
    minPeriod: {
      duration = 1,
      units = 'days'
    } = {}
  }) {
    const combinedDate = this.combineDate({
      date,
      time,
    }, '00:00');

    return Moment(combinedDate, 'YYYY-MM-DD HH:mm').subtract(duration, units);
  }

  /**
   * @param {Object} param0 - Invalid duedate parameters
   * @param {import('moment').Moment} param0.time - Current invalid end time
   * @param {import('moment').Moment} param0.date - Current invalid end date
   * @param {Object} [param0.minPeriod] - Parameters of a minimum difference period
   * @param {Number} [param0.minPeriod.duration=1] - Duration of units
   * @param {import('moment').unitOfTime.DurationAs.Base} [param0.minPeriod.units="days"] - Units of period duration
   * @returns {import('moment').Moment} Valid end at datetime
   */
  getValidEndAt({
    time,
    date,
    minPeriod: {
      duration = 1,
      units = 'days'
    } = {}
  }) {
    const combinedDate = this.combineDate({
      date,
      time,
    }, '23:59');

    return Moment(combinedDate, 'YYYY-MM-DD HH:mm').add(duration, units);
  }

  /**
   * Сhecks whether the period is valid
   * @param {String} [param0.format='YYYY-MM-DD HH:mm'] - The format of start and end dates
   * @param {String} param0.startAt - Start date in the format
   * @param {String} param0.endAt - End date in the format
   * @param {Number} param0.minPeriodInSeconds - Minimum period duration in seconds
   * @returns {Boolean} true or false
   */
   validateDuedateInFormat({
     format = 'YYYY-MM-DD HH:mm',
     startAt,
     endAt,
     minPeriodInSeconds
   }) {
    const startAtM = Moment(startAt, format);
    const endAtM = Moment(endAt, format);

    const isValidDuedate = this.validateDuedate({
      startAt: startAtM,
      endAt: endAtM,
      minPeriodInSeconds,
    });

    return isValidDuedate;
  }
}

export default new Duedate()
