import FormFieldOptionModel from './FormFieldOption.js';

export class FormFieldModel {
  constructor(data) {
    this.id = data.id;
    this.label = data.label;
    this.type = data.type;
    this.isMandatory = Boolean(data.is_mandatory);
    this.hasAttachment = Boolean(data.has_attachment);
    this.position = data.position;
    this.options = data.options.map(option => new FormFieldOptionModel(option));
  }
}

export default FormFieldModel;
