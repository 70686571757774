import Provider from "@provider";
import { ProjectModel } from "@/models";

export default {
  getProjectTemplates: ({
    companyId,
  }) => {
    return Provider.api
      .get(`/companies/${companyId}/templates`)
      .then(response => response.data.map(e => new ProjectModel(e)));
  },

  updateProjectTemplates: ({
    companyId,
    projectIds,
  }) => {
    return Provider.api
      .post(`/companies/${companyId}/templates`, undefined, {
        params: {
          project_id: projectIds,
        },
      })
      .then(response => response.data);
  },

  deleteProjectTemplate: ({
    companyId,
    projectId,
  }) => {
    return Provider.api
      .delete(`/companies/${companyId}/templates`, {
        params: {
          project_id: projectId,
        },
      })
      .then(response => response.data);
  },
};
