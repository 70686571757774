import { INTEGRATION_DETAIL_STATUS } from "@/constants";
import IntegrationDetailSettingModel from "./IntegrationDetailSettings";
import IntegrationDetailsAction from '@/models/IntegrationDetailsAction';

class IntegrationDetailModel {
  constructor(data) {
    this.id = Number(data.id);
    this.integrationId = data.integration_id;

    this.email = data.email;
    this.settings = data.settings
      ? new IntegrationDetailSettingModel(data.settings)
      : null;

    this.status = data.status ?? INTEGRATION_DETAIL_STATUS.NOT_INTEGRATED;

    this.lastCheckAt = data.last_check_at;
    this.lastReceiveAt = data.last_receive_at;

    this.actions = data.actions ? data.actions.map((action) => new IntegrationDetailsAction(action)) : [];
    this.getters = data.getters;
  }
}

export default IntegrationDetailModel
