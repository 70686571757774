import { app as toDesktopApp } from '@todesktop/client-core';
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters(['messagesCount']),
  },

  created() {
    this.$watch(
      () => this.messagesCount,
      count => {
        toDesktopApp.setBadgeCount(count).catch(() => {});
      },
      { immediate: true }
    );
  },
};
