import InviteMembersProvider from '@provider/inviteMembers'
import * as TYPES from '@/store/types'

// initial state
const state = {
  invites: [],
}

// getters
const getters = {
  getterInvitesChat: state => ({ chatId, projectId }) => {
    return state.invites.filter(e => {
      return e.chatId == chatId
    })
  },

  getterInvitesCompany: state => state.invites.filter(e => e.companyId),
}

// actions
const actions = {
  resendInvite({ state, commit }, { token }) {
    return new Promise((resolve, reject) => {
      InviteMembersProvider.resendInvite({ token })
        .then((invite) => {
          commit(TYPES.INVITEMEMBERS.DELETE_INVITE, token);

          // if a user can resend invite an user, he also can delete it or resend again invite for it
          invite.canDelete = true;
          invite.canEdit = true;
          commit(TYPES.INVITEMEMBERS.INIT_INVITES, invite);
          resolve()
        })
        .catch(reject)
    })
  },

  getInvitesChat({ commit }, { chatId, projectId }) {
    return new Promise((resolve, reject) => {
      InviteMembersProvider.getInvitesChat({ chatId, projectId })
        .then(list => {
          commit(TYPES.INVITEMEMBERS.INIT_INVITES, list)
          resolve(list)
        })
        .catch(reject)
    })
  },

  getInvitesCompany({ commit }, { companyId }) {
    return new Promise((resolve, reject) => {
      InviteMembersProvider.getInvitesCompany({ companyId })
        .then(list => {
          commit(TYPES.INVITEMEMBERS.INIT_INVITES, list)
          resolve(list)
        })
        .catch(reject)
    })
  },

  deleteInvite({ commit }, { token }) {
    return new Promise((resolve, reject) => {
      InviteMembersProvider.deleteInvite({ token })
        .then(() => {
          commit(TYPES.INVITEMEMBERS.DELETE_INVITE, token)
          resolve()
        })
        .catch(reject)
    })
  },
}

// mutations
const mutations = {
  [TYPES.INVITEMEMBERS.INIT_INVITES](state, data) {
    let invites = Array.isArray(data) ? data : [data],
      ids = invites.map(e => e.id)

    state.invites = [
      ...state.invites.filter(e => !ids.includes(e.id)),
      ...invites,
    ]
  },

  [TYPES.INVITEMEMBERS.DELETE_INVITE](state, token) {
    state.invites = state.invites.filter(e => e.token != token)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
