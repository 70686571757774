import User from '@/models/User'
import Company from '@/models/Company'
import { RegistrationTypeModel } from '@/models/RegistrationType'
import RegistrationTypesGroupModel from '@/models/RegistrationTypesGroup'
import { format, parse } from 'date-fns'
import { MATERIAL_IMPORT_STATUS } from '@/constants'

export const MATERIAL_IMPORT_ERROR_STATUSES = [
  MATERIAL_IMPORT_STATUS.NAME_REQUIRED,
  MATERIAL_IMPORT_STATUS.CANT_CREATE
]

export class MaterialsImportFileModel {
  constructor(data) {
    this.id = data.id
    this.filename = data.filename
    this.original_filename = data.original_filename
    this.path = data.path
    this.status = data.status
    this.type = data.type
    this.createdBy = data.created_by
    this.user = new User(data.user)

    this.company = new Company(data.company)
    this.companyId = data.company_id

    this.createdAt = parse(data.created_at, 'yyyy-MM-dd HH:mm:ss', new Date())
    this.createdAtLabel = format(this.createdAt, 'dd LLL. yyyy HH:mm').toLowerCase()
    this.createdAtUtc = data.created_at_utc
    this.updatedAt = data.updated_at
    this.updatedAtUtc = data.updated_at_utc
  }

  get materialImports() {
    return store.getters['MaterialsImports/getFileImports'](this.id)
  }
  get materialsImportsCurrentCount() {
    return this.materialImports.length || 0
  }
  get materialsImportsTotalCount() {
    return this.materialImports?.[0]?.totalRecords || 0
  }
  get isInProgress() {
    return this.materialsImportsCurrentCount !== this.materialsImportsTotalCount
  }
}

export class MaterialImportModel {
  constructor(data) {
    this.id = data.id
    this.name = data.name
    this.unit = data.unit
    this.price = data.price
    this.type = data.type
    this.groupname = data.groupname

    this.registrationTypeId = data.registration_type_id
    this.registrationType = data.registration_type ? new RegistrationTypeModel(data.registration_type) : null

    this.registrationTypeGroupId = data.registration_type_group_id
    this.registrationTypeGroup = data.registration_type_group ? new RegistrationTypesGroupModel(data.registration_type_group) : null

    this.fileId = data.file_id
    this.createdBy = data.created_by
    this.user = new User(data.user)

    this.companyId = data.company_id

    this.isError = MATERIAL_IMPORT_ERROR_STATUSES.includes(data.status)

    this.createdAt = parse(data.created_at, 'yyyy-MM-dd HH:mm:ss', new Date())
    this.createdAtLabel = format(this.createdAt, 'dd LLL. yyyy HH:mm').toLowerCase()

    this.createdAtUtc = data.created_at_utc

    this.updatedAt = data.updated_at
    this.updatedAtUtc = data.updated_at_utc

    this.currentRecord = data.current_record
    this.totalRecords = data.total_records

    this.status = data.status
    this.statusMessage = data.status_message
  }
}

export default {
  MaterialsImportFileModel,
  MaterialImportModel
}
