import {
  isSameYear,
  isSameQuarter,
  isSameMonth,
  isSameWeek,
  isSameDay,
  isSameHour,
  isSameMinute,
  isSameSecond,
} from 'date-fns';

import { DATE_PERIODS } from '@/constants.js';
import { throwPeriodErrorIfValueIsInvalid } from './errors.js';

export function isSame({ dateLeft, dateRight, period }) {
  const map = {
    [DATE_PERIODS.YEAR]: isSameYear,
    [DATE_PERIODS.QUARTER]: isSameQuarter,
    [DATE_PERIODS.MONTH]: isSameMonth,
    [DATE_PERIODS.WEEK]: isSameWeek,
    [DATE_PERIODS.DAY]: isSameDay,
    [DATE_PERIODS.HOUR]: isSameHour,
    [DATE_PERIODS.MINUTE]: isSameMinute,
    [DATE_PERIODS.SECOND]: isSameSecond,
  }

  const isSamePeriod = map[period];

  throwPeriodErrorIfValueIsInvalid(isSamePeriod, Object.keys(map));

  return isSamePeriod(dateLeft, dateRight);
}
