import i18n from '../i18n'

class TitleBuilder {
  constructor() {
    this.list = []
  }

  init() {
    this.list = []
    this.add(i18n.global.t('defaultMetaTitle'))
  }

  add(text) {
    if (text && !this.list.includes(text)) {
      this.list.push(text)
    }
  }

  apply(text) {
    this.add(text)
    this.compile()
  }

  compile() {
    document.title = this.list.join(' | ')
  }
}

export default new TitleBuilder()
