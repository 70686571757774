import { isEqual, isAfter, isBefore } from "date-fns";
import { isBusinessDay, getBusinessDay } from "./days";
import { getIntervals } from "./intervals";

export const getBusinessHours = (date, { businessTime, timezone }) => {
  const day = getBusinessDay(date, timezone);
  return businessTime[day];
};

export const isBusinessHour = (date, timeOptions) => {
  if (!isBusinessDay(date, timeOptions)) {
    return false;
  }

  const intervals = getIntervals(date, timeOptions);
  return intervals.some(([intervalStart, intervalEnd]) => (
    (isEqual(date, intervalStart) || isAfter(date, intervalStart))
    && (isEqual(date, intervalEnd) || isBefore(date, intervalEnd))
  ));
};
