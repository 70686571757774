import User from '@/models/User'
import Company from '@/models/Company'
import { parse } from 'date-fns'

export class ImportFileModel {
  constructor(data) {
    this.id = data.id
    this.filename = data.filename
    this.original_filename = data.original_filename
    this.path = data.path
    this.status = data.status
    this.type = Number(data.type)
    this.createdBy = data.created_by
    this.user = data.user ? new User(data.user) : null

    this.company = data.company ? new Company(data.company) : null
    this.companyId = data.company_id

    this.createdAt = parse(data.created_at, 'yyyy-MM-dd HH:mm:ss', new Date())
    this.createdAtUtc = data.created_at_utc
    this.updatedAt = data.updated_at
    this.updatedAtUtc = data.updated_at_utc

    this.projectId = data.properties?.project_id ? Number(data.properties.project_id) : null;
    this.chatId = data.properties?.chat_id ? Number(data.properties.chat_id) : null;
  }

}

export default {
  ImportFileModel
}