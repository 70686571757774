import FormsProvider from '@/provider/v1/forms.js';
import * as TYPES from '@/store/types';

const state = {
  forms: [],
  formLogs: [],
  responses: [],
  loading: false,
  lastCreatedForm: null,
  responseLoading: false,
  lastCreatedResponse: null,
};

const getters = {
  getFormById: state => formId => state.forms.find(form => form.id === formId),
  getFormByParams:
    state =>
    ({ checklistItemId }) =>
      state.forms.find(form => form.checklistItemId === checklistItemId),

  getFormLogsByFormId: state => formId =>
    state.formLogs.filter(formLog => formLog.formId === formId),
};

// actions
const actions = {
  fetchForms({ commit }, payload) {
    commit(TYPES.FORMS.SET_LOADING, true);

    return FormsProvider.fetchForms(payload)
      .then(forms => {
        commit(TYPES.FORMS.INIT_FORMS, forms);
      })
      .finally(() => {
        commit(TYPES.FORMS.SET_LOADING, false);
      });
  },
  fetchForm({ commit }, payload) {
    commit(TYPES.FORMS.SET_LOADING, true);

    return FormsProvider.fetchForm(payload)
      .then(form => {
        commit(TYPES.FORMS.INIT_FORMS, form);
      })
      .finally(() => {
        commit(TYPES.FORMS.SET_LOADING, false);
      });
  },
  saveForm({ dispatch }, payload) {
    if (payload.id) {
      return dispatch('updateForm', payload);
    }

    return dispatch('addForm', payload);
  },
  addForm({ commit }, payload) {
    commit(TYPES.FORMS.SET_LOADING, true);

    return FormsProvider.addForm(payload)
      .then(form => {
        commit(TYPES.FORMS.INIT_FORMS, form);
      })
      .finally(() => {
        commit(TYPES.FORMS.SET_LOADING, false);
      });
  },
  updateForm({ commit }, payload) {
    commit(TYPES.FORMS.SET_LOADING, true);

    return FormsProvider.updateForm(payload)
      .then(form => {
        commit(TYPES.FORMS.INIT_FORMS, form);
      })
      .finally(() => {
        commit(TYPES.FORMS.SET_LOADING, false);
      });
  },
  duplicateForm({ commit }, payload) {
    commit(TYPES.FORMS.SET_LOADING, true);

    return FormsProvider.duplicateForm(payload)
      .then(form => {
        commit(TYPES.FORMS.INIT_FORMS, form);
        commit(TYPES.FORMS.SET_LAST_CREATED_FORM, form.id);
      })
      .finally(() => {
        commit(TYPES.FORMS.SET_LOADING, false);
      });
  },
  removeForm({ commit }, formId) {
    commit(TYPES.FORMS.SET_LOADING, true);

    return FormsProvider.removeForm(formId)
      .then(() => {
        commit(TYPES.FORMS.REMOVE_FORM, formId);
      })
      .finally(() => {
        commit(TYPES.FORMS.SET_LOADING, false);
      });
  },
  // process socket events
  initForms({ commit }, formOrForms) {
    commit(TYPES.FORMS.INIT_FORMS, formOrForms);
  },
  removeFormLocally({ commit }, formId) {
    commit(TYPES.FORMS.REMOVE_FORM, formId);
  },
  fetchFormLogs({ commit }, formId) {
    commit(TYPES.FORMS.SET_LOADING, true);

    return FormsProvider.fetchFormLogs(formId)
      .then(formLogs => {
        commit(TYPES.FORMS.INIT_FORM_LOGS, formLogs);
      })
      .finally(() => {
        commit(TYPES.FORMS.SET_LOADING, false);
      });
  },
  addFormLogsLocally({ commit }, logOrLogs) {
    commit(TYPES.FORMS.INIT_FORM_LOGS, logOrLogs);
  },
  fetchFormResponse({ commit }, payload) {
    commit(TYPES.FORMS.SET_RESPONSE_LOADING, true);

    return FormsProvider.fetchFormResponse(payload)
      .then(responses => {
        commit(TYPES.FORMS.INIT_FORM_FIELD_RESPONSES, responses);
      })
      .finally(() => {
        commit(TYPES.FORMS.SET_RESPONSE_LOADING, false);
      });
  },
  updateFormResponse({ commit }, payload) {
    commit(TYPES.FORMS.SET_RESPONSE_LOADING, true);

    return FormsProvider.updateFormResponse(payload)
      .then(responses => {
        commit(TYPES.FORMS.INIT_FORM_FIELD_RESPONSES, responses);
      })
      .finally(() => {
        commit(TYPES.FORMS.SET_RESPONSE_LOADING, false);
      });
  },
  addFieldResponse({ commit }, payload) {
    commit(TYPES.FORMS.SET_RESPONSE_LOADING, true);

    return FormsProvider.addFieldResponse(payload)
      .then(response => {
        if (response) {
          commit(TYPES.FORMS.INIT_FORM_FIELD_RESPONSES, response);
          commit(TYPES.FORMS.SET_LAST_CREATED_RESPONSE, response);
        }
      })
      .finally(() => {
        commit(TYPES.FORMS.SET_RESPONSE_LOADING, false);
      });
  },
};

// mutations
const mutations = {
  [TYPES.FORMS.SET_LOADING](state, loading) {
    state.loading = loading;
  },
  [TYPES.FORMS.INIT_FORMS](state, formOrForms) {
    const forms = Array.isArray(formOrForms) ? formOrForms : [formOrForms];
    const ids = new Set(forms.map(f => f.id));

    state.forms = [...state.forms.filter(f => !ids.has(f.id)), ...forms].sort(
      (a, b) => b.createdAt - a.createdAt
    );
  },
  [TYPES.FORMS.REMOVE_FORM](state, formId) {
    const index = state.forms.findIndex(form => form.id === formId);
    if (index === -1) return;

    state.forms.splice(index, 1);
  },
  [TYPES.FORMS.SET_LAST_CREATED_FORM](state, formId) {
    state.lastCreatedForm = formId;
  },
  [TYPES.FORMS.INIT_FORM_LOGS](state, formLogOrFormLogs) {
    const formLogs = Array.isArray(formLogOrFormLogs) ? formLogOrFormLogs : [formLogOrFormLogs];
    const ids = new Set(formLogs.map(f => f.id));

    state.formLogs = [...state.formLogs.filter(f => !ids.has(f.id)), ...formLogs];
  },
  [TYPES.FORMS.SET_RESPONSE_LOADING](state, loading) {
    state.responseLoading = loading;
  },
  [TYPES.FORMS.SET_LAST_CREATED_RESPONSE](state, response) {
    state.lastCreatedResponse = response;
  },
  [TYPES.FORMS.INIT_FORM_FIELD_RESPONSES](state, responseOrResponses) {
    const responses = Array.isArray(responseOrResponses)
      ? responseOrResponses
      : [responseOrResponses];

    state.responses = [...responses];
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
