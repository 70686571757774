export default {
  props: {
    autofocus: {
      type: Boolean,
      default: false
    },
    tabindex: {
      default: null
    },
    placeholder: {
      type: String,
      default: null
    },
    modelValue: {
      require: false,
      default: null
    },
    readonly: {
      type: Boolean,
      require: false,
      default: false
    },
    name: {
      type: String,
      require: false,
      default: null
    }
  },

  emits: ['update:modelValue'],

  computed: {
    model: {
      get() {
        return this.modelValue;
      },

      set(newValue) {
        this.$emit('update:modelValue', newValue);
      }
    }
  },

  methods: {
    focus() {},
  },

  mounted() {
    if(this.autofocus) {
      this.focus();
    }
  },
};