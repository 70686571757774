// like in the platform hint: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Sec-CH-UA-Platform
export const DevicePlatforms = Object.freeze({
  MACOS: 'macOS',
  WINDOWS: 'Windows',
  LINUX: 'Linux',
  UNKNOWN: 'Unknown',
});

let DEVICE_PLATFORM = DevicePlatforms.UNKNOWN;

if (window.navigator.platform) {
  if (window.navigator.platform.indexOf('Win') !== -1) {
    DEVICE_PLATFORM = DevicePlatforms.WINDOWS;
  } else if (window.navigator.platform.indexOf('Mac') !== -1) {
    DEVICE_PLATFORM = DevicePlatforms.MACOS;
  } else if (window.navigator.platform.indexOf('Linux') !== -1) {
    DEVICE_PLATFORM = DevicePlatforms.LINUX;
  }
}

if (window.navigator.userAgentData) {
  DEVICE_PLATFORM = window.navigator.userAgentData.platform || DEVICE_PLATFORM;
}

export const getDevicePlatform = () => DEVICE_PLATFORM;
