import { padStart } from 'lodash';

export const normalizeTime = dirtyTime => {
  const parts = dirtyTime.split(':');
  return Array.from({ length: 3 }, (_, index) => parts[index] ?? 0);
};

export const normalizeInterval = interval => {
  return Array.isArray(interval)
    ? interval.map(time => {
        const [hours, minutes] = normalizeTime(time);

        // if there is a need to set the end time of a day correctly
        // return hours === '23' && minutes === '59'
        //   ? '23:59:59'
        //   : time;
        return minutes === '59'
          ? [Number(hours) + 1, 0, 0].map(unit => padStart(unit, 2, '0')).join(':')
          : time;
      })
    : interval;
};

export const normalizeBusinessTime = businessTime => {
  return Object.fromEntries(
    Object.entries(businessTime).map(([day, interval]) => [day, normalizeInterval(interval)])
  );
};
