import EventEmmiter from "@/eventEmitter";

class Modals {
  install(app) {
    var modalsEmmiter = new EventEmmiter();
    app.config.globalProperties.$modals = modalsEmmiter;
  }
}

export default new Modals();
