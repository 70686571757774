<template>
  <div :class="className" />
</template>

<script>
export default {
  name: 'ContentPlaceholdersImg',
  data() {
    return {
      className: 'vue-content-placeholders-img',
    };
  },
};
</script>
