export class IntegrationDetailsAction {
  constructor(data) {
    this.title = data.title
    this.uri = data.uri
    this.zone = data.zone

    this.type = data.uri
  }
}

export default IntegrationDetailsAction