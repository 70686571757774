import Provider from '@provider'
import { PlanningResourcePlanItemModel } from '@/models'
import { convertZonedDateToBackendDate } from '@/helpers/dates/index.js';

export default {
  addPlanItem: ({
    name,
    resourceIds,
    projectId,
    checklistId,
    startAt,
    endAt,
    concept,
    color,
    description,
  }) => {
    if (checklistId) projectId = undefined

    const start_at = convertZonedDateToBackendDate(startAt);
    const end_at = convertZonedDateToBackendDate(endAt);

    return new Promise((resolve, reject) => {
      Provider.api
        .post(`/planning/planitems/`, {
          name,
          start_at,
          end_at,
          project_id: projectId,
          checklist_id: checklistId,
          concept: concept,
          color: color,
          description: description,
          resource_ids: resourceIds,
        })
        .then(response =>
          resolve(new PlanningResourcePlanItemModel(response.data))
        )
        .catch(reject)
    })
  },

  getPlanItems: ({ startAt, endAt, search, cancelToken }) => {
    return new Promise((resolve, reject) => {
      Provider.api
        .get(`/planning/planitems/`, {
          params: {
            search,
            date_from: startAt,
            date_till: endAt,
          },
          cancelToken,
        })
        .then(response =>
          resolve(response.data.map(e => new PlanningResourcePlanItemModel(e)))
        )
        .catch(reject)
    })
  },

  getPlanItem: ({ id }) => {
    return new Promise((resolve, reject) => {
      Provider.api
        .get(`/planning/planitems/${id}`)
        .then(response =>
          resolve(new PlanningResourcePlanItemModel(response.data))
        )
        .catch(reject)
    })
  },

  updatePlanItem: ({
    id,
    name,
    resourceIds,
    projectId,
    checklistId,
    startAt,
    endAt,
    concept,
    color,
    description,
  }) => {
    if (checklistId) projectId = undefined

    const start_at = convertZonedDateToBackendDate(startAt);
    const end_at = convertZonedDateToBackendDate(endAt);

    return new Promise((resolve, reject) => {
      Provider.api
        .put(`/planning/planitems/${id}`, {
          name,
          start_at,
          end_at,
          project_id: projectId,
          checklist_id: checklistId,
          resource_ids: resourceIds,
          concept: concept,
          color: color,
          description: description,
        })
        .then(response =>
          resolve(new PlanningResourcePlanItemModel(response.data))
        )
        .catch(reject)
    })
  },

  deletePlanItem: ({ id }) => {
    return new Promise((resolve, reject) => {
      Provider.api
        .delete(`/planning/planitems/${id}`)
        .then(response => resolve(response.data))
        .catch(reject)
    })
  },

  addPlanItemResources: ({ id, resourceIds }) => {
    return new Promise((resolve, reject) => {
      Provider.api
        .post(`/planning/planitems/${id}/resources`, {
          resource_ids: resourceIds,
        })
        .then(
          response => resolve()
          // resolve(new PlanningResourcePlanItemModel(response.data))
        )
        .catch(reject)
    })
  },

  getPlanItemResources: ({ id }) => {
    return new Promise((resolve, reject) => {
      Provider.api
        .get(`/planning/planitems/${id}/resources`)
        .then(
          response => resolve()
          // resolve(new PlanningResourcePlanItemModel(response.data))
        )
        .catch(reject)
    })
  },

  deletePlanItemResource: ({ id, resourceId }) => {
    return new Promise((resolve, reject) => {
      Provider.api
        .delete(`/planning/planitems/${id}/resources`, {
          params: {
            resource_id: resourceId,
          },
        })
        .then(
          response => resolve()
          // resolve(new PlanningResourcePlanItemModel(response.data))
        )
        .catch(reject)
    })
  },

  duplicateItem: id => {
    return new Promise((resolve, reject) => {
      Provider.api
        .post(`/planning/planitems/${id}/clone`)
        .then(response => resolve(new PlanningResourcePlanItemModel(response.data)))
        .catch(reject)
    })
  },
}
