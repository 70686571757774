import PdfImportsProvider from '@/provider/v1/pdfImports.js';
import * as TYPES from '@/store/types.js';

const state = {
  loading: false,
  templates: [],
};

const getters = {};

// actions
const actions = {
  fetchPdfTemplates({ commit }, { cancelToken }) {
    commit(TYPES.PRF_IMPORTS.SET_LOADING, true);

    return PdfImportsProvider.fetchPdfTemplates({ cancelToken })
      .then(templates => {
        commit(TYPES.PRF_IMPORTS.INIT_TEMPLATES, templates);
      })
      .finally(() => {
        commit(TYPES.PRF_IMPORTS.SET_LOADING, false);
      });
  },
};

// mutations
const mutations = {
  [TYPES.PRF_IMPORTS.SET_LOADING](state, loading) {
    state.loading = loading;
  },

  [TYPES.PRF_IMPORTS.INIT_TEMPLATES](state, templateOrTemplates) {
    const templates = Array.isArray(templateOrTemplates)
      ? templateOrTemplates
      : [templateOrTemplates];

    const templatesMap = new Map(state.templates.map(template => [template.id, template]));

    templates.forEach(template => templatesMap.set(template.id, template));

    state.templates = [...templatesMap.values()];
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
