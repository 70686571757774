import IntegrationDetailModel from "./IntegrationDetail";

class IntegrationMappingModel {
  constructor(data) {
    this.id = Number(data.id);
    this.iteration = data.iteration;
    this.integration = new IntegrationDetailModel(data.integration);
    this.remoteId = data.remote_id;
  }
}

export default IntegrationMappingModel
