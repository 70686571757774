import { useDragLayer } from 'vue3-dnd';
import { toRefs } from '@vueuse/core';
import { DRAGGABLE_TYPES } from '@/constants.js';

const draggableTypes = Object.values(DRAGGABLE_TYPES);

export const useIsDragging = () => {
  const collect = useDragLayer(monitor => ({
    isDragging: draggableTypes.includes(monitor.getItemType()),
  }));

  return toRefs(collect).isDragging;
};
