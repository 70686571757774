const
  LINK = "https://static.hotjar.com/c/hotjar-",
  EXT = ".js?sv=",
  ACTIVE_HOTJAR = process.env.VUE_APP_HOT_JAR === "true",
  HOTJAR_CONFIG = {
    hjid: 1241533,
    hjsv: 6
  };

class HotJar {
  constructor() {
    this.setup();
  }

  setup() {
    if(!ACTIVE_HOTJAR) { return null };
    debug("Active hotjar");
  
    window.hj = window.hj || function() {
        (window.hj.q = window.hj.q || []).push(arguments)
    };

    window._hjSettings = HOTJAR_CONFIG;

    let attachScript = window.document.createElement('script');
    attachScript.async = 1;
    attachScript.src = LINK + HOTJAR_CONFIG.hjid + EXT + HOTJAR_CONFIG.hjsv;

    let linkHead = window.document.getElementsByTagName('head')[0];          
    linkHead.appendChild(attachScript);
  }

  addTag(tag) {
    if(!ACTIVE_HOTJAR) { return null };

    window.hj('tagRecording', tag);
  }
}

const HotJarManager = new HotJar();

export default {
  computed: {
    hotjar() {
      return HotJarManager;
    }
  }
};