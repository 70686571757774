import { mapGetters } from 'vuex'

export default {
  emits: ['resource-mousemove'],

  computed: {
    ...mapGetters('PlanningResourcePlanItems', ['getPlanningItemByFilter']),
  },

  methods: {
    handleMouseLeave(event) {
      let el = this.lodash.get(this, '$refs.itemHover')
      if (!el) return

      el.style.left = '0'
      el.style.width = '0px'
    },

    handleMouseMove(event) {
      this.$emit('resource-mousemove', event);

      let startLine = this.dataGenLines.startAt.clone().startOf('seconds')
      let endLine = this.dataGenLines.endAt.clone().endOf('seconds')
    
      let el = this.lodash.get(this, '$refs.itemHover')
      if (!el) return

      let tt = this.dataGenLines.zoomHover(
        this.dataGenLines.startAt
          .clone()
          .add(event.offsetX / this.dataGenLines.pxTime, 'seconds')
      )

      if (this.getPlanningItemByFilter({ ...tt, resourceId: this.params.id })) {
        this.handleMouseLeave()
        return
      }


      let dstSec = 0
      //if (startLine.isDST() && endLine.isDST()) dstSec = 0
      //else if (tt.startAt.isDST()) dstSec = 3600

      const lineStartAt = this.dataGenLines.startAt.clone().startOf('seconds'),
        left = (((tt.startAt - lineStartAt) / 1000) + dstSec) * this.dataGenLines.pxTime,
        width =
          (this.$moment.duration(tt.endAt.diff(tt.startAt)).asSeconds()) *
          this.dataGenLines.pxTime

      el.style.left = `${left + 1}px`
      el.style.width = `${width - 1}px`
    },

    handleDoubleClick(e) {
      this.openModal({
        name: 'PlanningCreateItem',
        params: {
          resourceId: this.params.id,

          ...this.dataGenLines.zoomHover(
            this.dataGenLines.startAt
              .clone()
              .add(e.offsetX / this.dataGenLines.pxTime, 'seconds')
          ),
        },
      })
    },
  },
}
