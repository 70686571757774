import { middlewareGroup, logMid } from './plugins/middleware';

export default middlewareGroup(
  [logMid],
  [
    {
      path: '/404/sign-up/invite',
      name: 'notFoundSignUpInvite',
      component: () => import('@/views/errors/404/SignUpInvite.vue'),
    },
    {
      path: '/:noMatch(.*)',
      redirect: {
        name: 'notFound',
      },
    },
    {
      path: '/404',
      name: 'notFound',
      component: () => import('@/views/errors/404/Index.vue'),
    },
    {
      path: '/404-link',
      name: 'notFoundLink',
      component: () => import('@/views/errors/404/Link.vue'),
    },
    {
      path: '/403-permissions',
      name: 'ForbiddenPermissions',
      component: () => import('@/views/errors/403/Permissions.vue'),
    },
  ]
);
