import { CancelToken, isCancel } from 'axios'
import Scheduler from '@/plugins/utils/scheduler'

class CancelSourceManager {
  constructor() {
    this.store = {}
  }

  isCancel(error) {
    return isCancel(error);
  }

  create() {
    return CancelToken.source()
  }

  add({ id, cancelSource }) {
    const requestsWithId = this.store[id] = this.store[id] || []
    requestsWithId.push(cancelSource)
  }

  remove({ id, cancelSource }) {
    const requestsWithId = this.store[id]
    this.store[id] = requestsWithId?.filter(
      (requestCancelSource) =>  requestCancelSource !== cancelSource
    )
  }

  async cancelById(id, withFlushPromises = false) {
    const cancelSources = this.store[id] ?? []
    cancelSources.forEach((cancelSource) => cancelSource.cancel())
    delete this.store[id]

    if (withFlushPromises) {
      await Scheduler.flushPromises()
    }
  }

  withCancelSource(id) {
    const runWithCancelSource = async (callback) => {
      const cancelSource = this.create()
      this.add({ id, cancelSource })

      try {
        return await callback({ cancelSource })
      } finally {
        this.remove({ id, cancelSource })
      }
    }

    return runWithCancelSource
  }
}

export default new CancelSourceManager()
