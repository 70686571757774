import DraggEmitter from './emitter'

class DraggItem {
  /**
   * 
   * @param {Object} param0
   * @param {String} param0.ignoreElementSelector - Selector for nested html elements that do not affect dragging
   */
  constructor({
    el,
    id,
    parentId,
    namespace,
    params = {},
    ignoreElementSelector = null,
  }) {
    this.el = el
    this.id = id
    this.parentId = parentId
    this.namespace = namespace
    this.ignoreElementSelector = ignoreElementSelector

    // Params
    this.params = params
    this.stickyParent = params.stickyParent || false

    this.dragged = false

    let startDragPos = { x: 0, y: 0 }
    let elBounds = { x: 0, y: 0 }

    el.setAttribute('data-dragg-item', id)
    el.setAttribute('data-dragg-item-namespace', namespace)
    el.setAttribute('dragg-elem', true)

    const mouseMove = event => {
      event.preventDefault()

      if (
        !(
          Math.abs(startDragPos.x - event.x) > 10 ||
          Math.abs(startDragPos.y - event.y) > 10
        )
      ) {
        return null
      }

      if (!el.style.width) {
        el.style.width = `${el.clientWidth}px`
      }

      if (!this.dragged) {
        this.dragged = true

        DraggEmitter.emit('dragg-start', this)
        el.dispatchEvent(new CustomEvent('dragg-start', el))
      }

      DraggEmitter.emit('move', this)

      setPosition(event, el)

      el.classList.add('ghost')
    }

    const mouseUp = event => {
      event.preventDefault()
      event.stopPropagation()

      // If card has been dragged - cancel default click function
      if (el.classList.contains('ghost')) {
        const func = el.onclick

        el.onclick = () => {}

        setTimeout(() => {
          el.onclick = func
        }, 150)

        DraggEmitter.emit('drop', this)
      }

      // Clear dragg styles
      el.classList.remove('ghost')
      el.style.left = ''
      el.style.top = ''
      el.style.zIndex = ''
      el.style.width = ''

      el.dispatchEvent(new CustomEvent('dragg-end', el))

      document.removeEventListener('mousemove', mouseMove)
      document.removeEventListener('mouseup', mouseUp)
    }

    const mouseDown = event => {
      if(event.button !== 0) return
      
      if (this.params.preventClick) {
        event.preventDefault()
        event.stopPropagation()
      }

      if (this.ignoreElementSelector && this.el) {
        const ignoredElements = Array.from(this.el.querySelectorAll(this.ignoreElementSelector))

        if (this.el.matches(this.ignoreElementSelector)) {
          ignoredElements.splice(0, 0, this.el);
        }

        const isIgnoredEvent = ignoredElements.some((ignoredElement) => ignoredElement.contains(event.target))
        if (isIgnoredEvent) return
      }

      updateBound(event)
      startDragPos = {
        x: event.x,
        y: event.y,
      }

      el.style.zIndex = 1000

      document.addEventListener('mousemove', mouseMove)
      document.addEventListener('mouseup', mouseUp)
    }

    const setPosition = async event => {
      el.style.left = `${event.x + elBounds.x}px`
      el.style.top = `${event.y + elBounds.y - el.clientHeight / 2}px`
    }

    el.addEventListener('mousedown', mouseDown)

    const updateBound = event => {
      let extData = el.getBoundingClientRect()

      elBounds.x = extData.x - event.x
      elBounds.y = extData.y - event.y + extData.height / 2

      return extData
    }
  }
}

export default DraggItem
