import Model from "@/models/Model";

class ProjectContactModel extends Model {
  constructor(data) {
    super(data);

    this.id = Number(data.id);
    this.projectId = Number(data.project_id) || null;
    this.access = data.access;
    this.order = data.order;
    this.address = data.address || "";
    this.email = data.email || "";
    this.name = data.name || "";
    this.phone = data.phone || "";
  }
}

export default ProjectContactModel;
