window.perf = {
  _d: new Date().getTime(),
  start() {
    this._d = new Date().getTime()
  },
  end() {
    debug(new Date().getTime() - this._d)
  },
  fn(callback) {
    const _d = new Date().getTime()
    callback()
    debug(new Date().getTime() - _d)
  },
}
