export default {
  methods: {
    getDayClasses(momentDay) {
      const hours = this.getHours(
        momentDay.clone().startOf('day'),
        momentDay.clone().endOf('day')
      )

      const classList = [];

      if (!momentDay.isBusinessDay()) {
        classList.push('holiday')
      }

      if (hours > 24) {
        classList.push('dst-long-day')
      } else if (hours < 24) {
        classList.push('dst-short-day')
      }

      return classList
    },

    getWeekStyle(momentDay, value, units) {
      const daysHours = this.getHours(
        momentDay.clone().startOf('week'),
        momentDay.clone().endOf('week')
      )
      const factor = (daysHours / 168).toFixed(4)

      return {
        minWidth: factor * value + `${units}`,
      };
    },

    getDayStyle(momentDay, value, units) {
      const hours = this.getHours(
        momentDay.clone().startOf('day'),
        momentDay.clone().endOf('day')
      )
      const factor = (hours / 24).toFixed(4)

      return {
        minWidth: factor * value + `${units}`,
      };
    },

    getHours(from, to) {
      const hours = [
        ...this.$moment
          .range(from, to)
          .by('hours')
      ]

      return hours.length;
    }
  }
}
