import DraggEmitter from './emitter'

class ListManager {
  constructor() {
    this.arr = []
  }

  set(id, item) {
    this.delete(id)
    this.arr.push(item)
  }

  delete(id) {
    this.arr = this.arr.filter(e => e.id != id)
  }

  get list() {
    return this.arr
  }
}

class StoreDragg {
  constructor() {
    this.draggList = new ListManager()
    this.draggItems = new ListManager()
    this.draggScroll = new ListManager()

    this.draggActive = false
    this.lastDraggToList = null

    DraggEmitter.on('drop', draggItem => {
      if (this.lastDraggToList) this.lastDraggToList.handleDrop(draggItem)

      this.lastDraggToList = null
    })

    DraggEmitter.on(
      'move',
      this.throttled(100, draggItem => {
        // Handle stickyParent logic
        if (draggItem.stickyParent) {
          let lActive = this.draggList.list.find(
            e => e.id == draggItem.parentId && this.isMouseCollide(e.el)
          )

          if (lActive) {
            lActive.handleMove(draggItem)
            this.lastDraggToList = lActive
          }

          return
        }

        if (this.lastDraggToList) {
          if (this.isMouseCollide(this.lastDraggToList.el)) {
            this.lastDraggToList.handleMove(draggItem)
            return null
          } else {
            this.lastDraggToList.unMark()
            this.lastDraggToList = null
          }
        }

        let lActive = this.draggList.list.find(
          e => e.namespace == draggItem.namespace && this.isMouseCollide(e.el)
        )

        if (lActive) {
          lActive.handleMove(draggItem)
          this.lastDraggToList = lActive
        }
      })
    )
  }

  throttled(delay, fn) {
    let lastCall = 0
    return function(...args) {
      const now = new Date().getTime()
      if (now - lastCall < delay) {
        return
      }
      lastCall = now
      return fn(...args)
    }
  }

  isMouseCollide(element) {
    const rect = element.getBoundingClientRect(),
      x = window.mouse.x,
      y = window.mouse.y

    return !(
      y < rect.top ||
      y > rect.top + rect.height ||
      x < rect.left ||
      x > rect.left + rect.width
    )
  }
}

export default new StoreDragg()
