import ReferralsProvider from '@/provider/v1/referrals.js';
import * as TYPES from '@/store/types';

// initial state
const state = {
  referrals: [],
};

// getters
const getters = {};

// actions
const actions = {
  getReferrals({ commit }, data) {
    return ReferralsProvider.getReferrals(data).then(list => {
      commit(TYPES.REFERRALS.SET_REFERRALS_LIST, list);
    });
  },

  createReferral({ commit }, data) {
    return ReferralsProvider.createReferral(data).then(referral => {
      commit(TYPES.REFERRALS.SET_REFERRAL, referral);
    });
  },

  updatePaymentType({ commit }, data) {
    return ReferralsProvider.updatePaymentType(data).then(referral => {
      commit(TYPES.REFERRALS.SET_REFERRAL, referral);
    });
  },
};

// mutations
const mutations = {
  [TYPES.REFERRALS.SET_REFERRALS_LIST](state, data) {
    const referrals = Array.isArray(data) ? data : [data],
      ids = referrals.map(e => e.id);

    state.referrals = [...state.referrals.filter(e => !ids.includes(e.id)), ...referrals];
  },

  [TYPES.REFERRALS.SET_REFERRAL](state, data) {
    const index = state.referrals.findIndex(r => r.id === data.id);

    if (index === -1) {
      state.referrals.push(data);
    } else {
      // patch model properties
      Object.assign(state.referrals[index], data);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
