import _ from 'lodash'

import Provider from '@provider'
import downloadjs from 'downloadjs'
import helpers from '@/helpers'

import {
  ChecklistModel,
  ChatModel,
  UserModel,
  UserInvitedModel,
  MediaFileModel,
} from '@/models'

export default {
  exportChatToPDF: ({ fileName = 'chat.pdf', id, files = true }) => {
    return new Promise((resolve, reject) => {
      Provider.api
        .post(
          `/chats/${id}/export`,
          {
            files: +files,
          },
          {
            responseType: 'blob',
          }
        )
        .then(response => {
          downloadjs(response.data, fileName, response.headers['content-type'])
          resolve()
        })
        .catch(reject)
    })
  },

  downloadAllChatFiles: ({ fileName = 'chat.zip', id, files = true }) => {
    return new Promise((resolve, reject) => {
      Provider.api
        .post(
          `chats/${id}/download_files_zip`,
          {
            // files: +files,
          },
          {
            responseType: 'blob',
          }
        )
        .then(response => {
          const status = _.get(response, 'status', 204)
          if (status == 204) {
            // To do nothing
          } else {
            downloadjs(
              response.data,
              fileName,
              response.headers['content-type']
            )
          }

          resolve()
        })
        .catch(reject)
    })
  },

  markMessagesRead: ({ chatId }) => {
    return new Promise((resolve, reject) => {
      Provider.api
        .post(`/chats/${chatId}/markmessagesread`)
        .then(response => resolve(response.data))
        .catch(error => reject(error))
    })
  },

  getChats: ({ chatIds } = {}) => {
    return new Promise((resolve, reject) => {
      Provider.api
        .get('/chats', {
          params: {
            chat_ids: chatIds,
          },
        })
        .then(response => resolve(response.data.map(e => new ChatModel(e))))
        .catch(error => reject(error))
    })
  },

  getChatChecklists: ({ chatId }) => {
    return new Promise((resolve, reject) => {
      Provider.api
        .get(`/chats/${chatId}/checklists`)
        .then(response =>
          resolve(response.data.map(e => new ChecklistModel(e)))
        )
        .catch(reject)
    })
  },

  chatInviteExternalUser: ({ chatId, projectId, user }) => {
    return new Promise((resolve, reject) => {
      Provider.api
        .post(`/chats/${chatId}/projects/${projectId}/invitemembers`, user)
        .then(response => resolve(new UserInvitedModel(response.data)))
        .catch(error => reject(error))
    })
  },

  getChat: chatId => {
    return new Promise((resolve, reject) => {
      Provider.api
        .get(`/chats/${chatId}`)
        .then(response => resolve(new ChatModel(response.data)))
        .catch(reject)
    })
  },

  getChatFiles: ({ chatId, limit }) => {
    return new Promise((resolve, reject) => {
      Provider.api
        .get(`/chats/${chatId}/files`, {
          params: {
            limit,
          },
        })
        .then(response => {
          resolve(response.data.map(e => new MediaFileModel(e)))
        })
        .catch(error => reject(error))
    })
  },

  getChatMembers: chatId => {
    return new Promise((resolve, reject) => {
      Provider.api
        .get(`/chats/${chatId}/members`)
        .then(response => resolve(response.data.map(e => new UserModel(e))))
        .catch(error => reject(error))
    })
  },

  leaveChat: chatId => {
    return new Promise((resolve, reject) => {
      Provider.api
        .post(`/chats/${chatId}/leave`)
        .then(response => resolve(response.data))
        .catch(reject)
    })
  },

  deleteChat: chatId => {
    return new Promise((resolve, reject) => {
      Provider.api
        .delete(`/chats/${chatId}`)
        .then(response => resolve(response.data))
        .catch(reject)
    })
  },

  addChatMember: ({ chatId, userId }) => {
    return Provider.api
      .post(`/chats/${chatId}/members`, {
        user_id: userId,
      });
  },

  deleteChatMember: ({ chatId, userId }) => {
    return Provider.api
      .delete(`/chats/${chatId}/members`, {
        params: {
          user_id: userId,
        },
      });
  },

  updateChat: ({ chatId, fields }) => {
    return new Promise((resolve, reject) => {
      Provider.api
        .put(`/chats/${chatId}`, {
          chat_name: fields.name,
        })
        .then(response => resolve(new ChatModel(response.data)))
        .catch(reject)
    })
  },

  muteChat: ({ chatId, mute }) => {
    const url = mute ? `/chats/${chatId}/mute` : `/chats/${chatId}/unmute`
    return new Promise((resolve, reject) => {
      Provider.api
        .put(url)
        .then(response => resolve(new ChatModel(response.data)))
        .catch(reject)
    })
  },

  addChat: ({ projectId, userId, name, userIds }) => {
    return new Promise((resolve, reject) => {
      Provider.api
        .post('/chats', {
          chat_name: name,
          project_id: projectId,
          user_id: userId,
          user_ids: userIds,
        })
        .then(response => resolve(new ChatModel(response.data)))
        .catch(error => reject(error))
    })
  },

  sendFilesToChats({ fileIds, chatIds }) {
    const data = helpers.createForm({
      files: fileIds,
      chats: chatIds,
    });

    return Provider.api
      .post('/chats/send_files', data)
      .then(response => response.data.map(e => new MediaFileModel(e)));
  },
};
