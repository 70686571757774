import vueEsc from './vue-esc.js';

const plugin = {
  install(app) {
    app.directive('esc', vueEsc);
    document.addEventListener('keyup', vueEsc.onEvent);
  },
};

export default plugin;
