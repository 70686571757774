import { mapGetters, mapActions } from 'vuex'

export default {
  computed: {
    ...mapGetters('App', ['sortMediaBy']),
  },

  methods: {
    handleClickSort(e) {
      const TPS = this.$utils.$sort.DATE_SORT

      this.openModal({
        name: 'ContextMenu',
        params: {
          skin: 'checked',
          el: e.target,
          position: 'bottom left',
          list: [
            {
              placeholder: this.$t('sortBy.nameAZ'),
              class: { check: this.sortMediaBy == TPS.NAME_AZ },
              callback: () => this.updateSortMediaBy(TPS.NAME_AZ),
            },
            {
              placeholder: this.$t('sortBy.nameZA'),
              class: { check: this.sortMediaBy == TPS.NAME_ZA },
              callback: () => this.updateSortMediaBy(TPS.NAME_ZA),
            },
            {
              placeholder: this.$t('sortBy.dateOldNew'),
              class: { check: this.sortMediaBy == TPS.DATE_OLD_NEW },
              callback: () => this.updateSortMediaBy(TPS.DATE_OLD_NEW),
            },
            {
              placeholder: this.$t('sortBy.dateNewOld'),
              class: { check: this.sortMediaBy == TPS.DATE_NEW_OLD },
              callback: () => this.updateSortMediaBy(TPS.DATE_NEW_OLD),
            },
          ],
        },
      })
    },

    ...mapActions('App', ['updateSortMediaBy']),
  },
}
