import lodash from 'lodash'
import LocalStorageWrapper from '@/plugins/localStorageWrapper'
import FiltersProvider from '@provider/filters'
import * as TYPES from '@/store/types'
import { FILTER_TYPES } from '@/models'

import defDashboard from './default/dashboard'
import defTasks from './default/tasks'
import defKanban from './default/kanban.js';

/* Locatl storage keys */
const LST_KEYS = {
  FILTER_DASHBOARD: 'FILTER_DASHBOARD',
  FILTER_TASKS: 'FILTER_TASKS',
  FILTER_KANBAN: 'FILTER_KANBAN',
};

const defineFilterFields = (storedFields, defaultFields) => {
  // check the object reference
  if (storedFields === defaultFields) return storedFields;

  return Object.fromEntries(
    Object.entries(defaultFields).map(([key, fieldDefinition]) => {
      const storedFieldDefinition = storedFields[key];

      if (storedFieldDefinition) {
        return [key, Object.assign({}, fieldDefinition, storedFieldDefinition)];
      }
      return [key, fieldDefinition];
    })
  );
};

const defineDefaultProperties = (storedData, defaultData) => {
  Object.entries(defaultData).forEach(([property, value]) => {
    if (!(property in storedData)) storedData[property] = value;
  });
};

const storedProjectInfo = LocalStorageWrapper.getObj(LST_KEYS.FILTER_DASHBOARD, defDashboard);
const storedTasksInfo = LocalStorageWrapper.getObj(LST_KEYS.FILTER_TASKS, defTasks);
const storedKanbanInfo = LocalStorageWrapper.getObj(LST_KEYS.FILTER_KANBAN, defKanban);

storedProjectInfo.fields = defineFilterFields(storedProjectInfo.fields, defDashboard.fields);
storedTasksInfo.fields = defineFilterFields(storedTasksInfo.fields, defTasks.fields);

defineDefaultProperties(storedTasksInfo, defTasks);

// initial state
const state = {
  filters: [],
  dashboardFilter: storedProjectInfo,
  tasksFilter: storedTasksInfo,
  kanbanFilter: storedKanbanInfo,
};

// getters
const getters = {
  dashboardFilter: state => state.dashboardFilter,
  tasksFilter: state => state.tasksFilter,

  dashboardFilters: state =>
    state.filters.filter(e => e.type == FILTER_TYPES.DASHBOARD),

  tasksFilters: state =>
    state.filters.filter(e => e.type == FILTER_TYPES.TASKS),

  kanbanFilters: state =>
    state.filters.filter(e => e.type == FILTER_TYPES.KANBAN),

  getterFiltersById: state =>
    state.filters.reduce((out, item) => {
      out[item.id] = item
      return out
    }, {}),
}

const actions = {
  getFilters({ commit }) {
    return new Promise((resolve, reject) => {
      FiltersProvider.getFilters()
        .then(filters => {
          commit(TYPES.FILTERS.INIT_FILTERS, filters)
          resolve(filters)
        })
        .catch(reject)
    })
  },

  addFilter({ commit }, data) {
    return new Promise((resolve, reject) => {
      FiltersProvider.addFilter(data)
        .then(filter => {
          commit(TYPES.FILTERS.INIT_FILTERS, filter)
          resolve(filter)
        })
        .catch(reject)
    })
  },

  updateFilter({ commit }, data) {
    return new Promise((resolve, reject) => {
      FiltersProvider.updateFilter(data)
        .then(filter => {
          commit(TYPES.FILTERS.INIT_FILTERS, filter)
          resolve(filter)
        })
        .catch(reject)
    })
  },

  deleteFilter({ commit }, id) {
    return new Promise((resolve, reject) => {
      FiltersProvider.deleteFilter(id)
        .then(() => {
          commit(TYPES.FILTERS.DELETE_FILTER, id)
          resolve()
        })
        .catch(reject)
    })
  },

  updateDashboardLocalFilter({ commit, state }, data) {
    let filter = JSON.parse(JSON.stringify(state.dashboardFilter))

    for (const key in data) filter[key] = data[key]

    commit(TYPES.FILTERS.UPDATE_DASHBOARD_LOCAL_FILTER, filter)
  },

  updateProjectLocalFilterField({ commit, state }, { key, data }) {
    let filter = JSON.parse(JSON.stringify(state.dashboardFilter))

    for (const valKey in data)
      lodash.set(filter, `fields[${key}][${valKey}]`, data[valKey])

    commit(TYPES.FILTERS.UPDATE_DASHBOARD_LOCAL_FILTER, filter)
  },

  updatePositionDashboardFilter({ commit }, { name, position }) {
    commit(TYPES.FILTERS.UPDATE_POSITION_DASHBOARD_FILTER, { name, position })
  },

  updateKanbanFilterLocally({ commit, state }, data) {
    const filter = JSON.parse(JSON.stringify(state.kanbanFilter));
    Object.assign(filter, data);

    commit(TYPES.FILTERS.UPDATE_KANBAN_FILTER, filter);
    LocalStorageWrapper.setItem(LST_KEYS.FILTER_KANBAN, filter);
  },

  handleChangeTasksTimeRange({ commit, state }, data) {
    commit(TYPES.FILTERS.UPDATE_TASKS_LOCAL_FILTER, {
      ...state.tasksFilter,
      selectTimeRange: data,
    });
  },

  patchTaskFilterLocally({ state, commit }, data) {
    commit(TYPES.FILTERS.PATCH_TASKS_LOCAL_FILTER, data);
    LocalStorageWrapper.setItem(LST_KEYS.FILTER_TASKS, state.tasksFilter);
  },
};

// mutations
const mutations = {
  [TYPES.FILTERS.INIT_FILTERS](state, data) {
    const filters = Array.isArray(data) ? data : [data],
      ids = filters.map(e => e.id)

    state.filters = [
      ...state.filters.filter(e => !ids.includes(e.id)),
      ...filters,
    ]
  },

  [TYPES.FILTERS.UPDATE_DASHBOARD_LOCAL_FILTER](state, data) {
    state.dashboardFilter = {
      ...data,
      fields: defineFilterFields(data.fields, defDashboard.fields),
    };

    LocalStorageWrapper.setItem(LST_KEYS.FILTER_DASHBOARD, data)
  },

  [TYPES.FILTERS.UPDATE_KANBAN_FILTER](state, data) {
    Object.assign(state.kanbanFilter, data);
  },

  [TYPES.FILTERS.UPDATE_TASKS_LOCAL_FILTER](state, data) {
    state.tasksFilter = data
    LocalStorageWrapper.setItem(LST_KEYS.FILTER_TASKS, data)
  },

  [TYPES.FILTERS.PATCH_TASKS_LOCAL_FILTER](state, data) {
    Object.entries(data).forEach(([key, value]) => {
      state.tasksFilter[key] = value;
    });
  },

  [TYPES.FILTERS.DELETE_FILTER](state, id) {
    state.filters = state.filters.filter(e => e.id != id)
  },

  [TYPES.FILTERS.UPDATE_POSITION_DASHBOARD_FILTER](state, { name, position }) {
    let data = state.dashboardFilter.fields

    let list = Object.keys(data)
      .map(key => {
        return { name: key, ...data[key] }
      })
      .filter(e => e.name != name)
      .sort((a, b) => a.position - b.position)

    let newPos = 1,
      nextCard = list[position - 1],
      prevCard = list[position - 2]

    if (!prevCard && nextCard) {
      newPos = nextCard.position - 0.0000005
    } else if (!nextCard && prevCard) {
      newPos = prevCard.position + 1
    } else if (prevCard && nextCard) {
      newPos = (prevCard.position + nextCard.position) / 2
    }

    state.dashboardFilter.fields[name].position = newPos

    LocalStorageWrapper.setItem(
      LST_KEYS.FILTER_DASHBOARD,
      state.dashboardFilter
    )
  },

  [TYPES.FILTERS.UPDATE_POSITION_TASKS_FILTER](state, { name, position }) {
    let data = state.tasksFilter.fields

    let list = Object.keys(data)
      .map(key => {
        return { name: key, ...data[key] }
      })
      .filter(e => e.name != name)
      .sort((a, b) => a.position - b.position)

    let newPos = 1,
      nextCard = list[position - 1],
      prevCard = list[position - 2]

    if (!prevCard && nextCard) {
      newPos = nextCard.position - 0.0000005
    } else if (!nextCard && prevCard) {
      newPos = prevCard.position + 1
    } else if (prevCard && nextCard) {
      newPos = (prevCard.position + nextCard.position) / 2
    }

    state.tasksFilter.fields[name].position = newPos

    LocalStorageWrapper.setItem(LST_KEYS.FILTER_TASKS, state.tasksFilter)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
