import Model from '@/models/Model'

export const FILTER_TYPES = {
  DASHBOARD: 'dashboard',
  TASKS: 'tasks',
  KANBAN: 'kanban',
};

class FilterModel extends Model {
  constructor(data) {
    super(data)

    this.id = data.id
    this.name = data.name
    this.fields = data.fields ? JSON.parse(data.fields) : null
    this.personal = Boolean(data.personal)
    this.type = data.type
    this.companyId = data.company_id
    this.createdBy = data.created_by
    this.permissions = data.permissions

    this.createdAt = this.toMomentDate(data.created_at_utc)
    this.updatedAt = this.toMomentDate(data.updated_at_utc)
    this.deletedAt = this.toMomentDate(data.deleted_at_utc)
  }
}

export default FilterModel;
