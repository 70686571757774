import Provider from "@provider";
import { ImportFileModel } from '@/models/ImportFileModel'
import { ImportFileResultsModel} from '@/models/ImportFileResultsModel'

export default  {
  getImportedFiles: ({ type, projectId, chatId }) => {
    return new Promise((resolve, reject) => {
      Provider.api.get(`/imports/files`, {
        params: {
          type: Number(type),
          project_id: projectId,
          chat_id: chatId,
        },
      })
        .then(({ data }) => resolve(data.map(importFile => new ImportFileModel(importFile))))
        .catch(reject)
    })
  },
  rollbackFile: (fileId) => {
    return new Promise((resolve, reject) => {
      Provider.api.delete(`/imports/${ fileId }`)
        .then(({ data }) => resolve(data))
        .catch(reject)
    })
  },
  getImportedData: ({ type, projectId, chatId }) => {
    return new Promise((resolve, reject) => {
      Provider.api.get(`/imports`, {
        params: {
          type: Number(type),
          project_id: projectId,
          chat_id: chatId,
        },
      })
        .then(({ data }) => resolve(data.map(importData => new ImportFileResultsModel(importData))))
        .catch(reject)
    })
  },
  addImportsFile({ form, progress }) {
    return Provider.api
      .post('/imports', form, { onUploadProgress: progress })
      .then(({ data }) => data);
  },
  downloadImportsFile(fileId, fileName) {
    return new Promise((resolve, reject) => {
      Provider.api.get(`/imports/file/${ fileId }`, {
        responseType: 'blob'
      })
        .then(({ data }) => {
          let link = document.createElement('a')
          link.href = window.URL.createObjectURL(data)
          link.download = fileName
          link.click()
          link.remove()

          resolve(data)
        })
        .catch(reject)
    })
  }
}
