import Model from '@/models/Model'

const IMAGE = 'I',
  ATACHMENT = 'A',
  LINK = 'L',
  VIDEO = 'V',
  SOUND = 'S',
  DELETED = 'D',
  ACCESS_LOCK = 1

class MediaFileModel extends Model {
  static linkDomains = {
    DROPBOX: 'www.dropbox.com',
    GOOGLE_DRIVE: 'drive.google.com',
    GOOGLE_DOCS: 'docs.google.com',
    ONE_DRIVE: '1drv.ms',
  }

  static linkTypes = {
    LINK: 'LINK',
    DROPBOX: 'DROPBOX',
    GOOGLE_DRIVE: 'GOOGLE_DRIVE',
    GOOGLE_DOCS: 'GOOGLE_DOCS',
    GOOGLE_SHEETS: 'GOOGLE_SHEETS',
    GOOGLE_SLIDES: 'GOOGLE_SLIDES',
    ONE_DRIVE: 'ONE_DRIVE',
  }

  static linkClasses = {
    LINK: 'LINK',
    DROPBOX: 'DROPBOX',
    GOOGLE_DRIVE: 'GOOGLE-DRIVE',
    GOOGLE_DOCS: 'GOOGLE-DOCS',
    GOOGLE_SHEETS: 'GOOGLE-SHEETS',
    GOOGLE_SLIDES: 'GOOGLE-SLIDES',
    ONE_DRIVE: 'ONE-DRIVE',
  }

  constructor(data) {
    super(data)

    this.canDeleteTillTime = this.toMomentDate(data.can_delete_till_time_utc)

    this.fileName = data.filename
    this.name = data.filename

    this.id = data.id
    this.chatId = data.chat_id
    this.projectId = data.project_id
    this.commentId = data.comment_id
    this.folderId = data.folder_id

    this.url = data.url
    this.type = data.type
    this.notes = data.notes
    this.previewUrl = data.preview_url
    this.size = data.size * 1000 || 0
    this.link = data.link
    this.status = data.status

    let ext = this.fileName
      ? this.fileName.substring(
          this.fileName.lastIndexOf('.') + 1,
          this.fileName.length
        )
      : null

    this.ext = ext ? ext.toUpperCase() : null

    this.isImage = false
    this.isSound = false
    this.isVideo = false
    this.isAtachment = false
    this.isPdf = (this.ext == 'PDF')
    this.isDoc = (this.ext == 'DOC')
    this.isDocx = (this.ext == 'DOCX')
    this.isXls = (this.ext == 'XLS')
    this.isXlsx = (this.ext == 'XLSX')
    this.isOffice = (this.isDoc || this.isDocx || this.isXls || this.isXlsx)
    this.isLink = false
    this.linkType = null
    this.isDropbox = false
    this.isGoogleDrive = false
    this.isGoogleDocs = false
    this.isGoogleSheets = false
    this.isGooglePresentations = false
    this.isOneDrive = false
    this.isDeleted = Boolean(data.deleted_at)

    this.percentDownload = 0

    this.isLocked = data.access === ACCESS_LOCK
    this.access = Boolean(data.access)

    this.typesConstructor(data.type)

    this.createdAt = this.fixData(data.created_at_utc)
    this.createdAtM = this.toMomentDate(data.created_at_utc)
  }

  typesConstructor(type) {
    switch (type) {
      case DELETED:
        this.isDeleted = true
        break

      case IMAGE:
        this.isImage = true
        break

      case ATACHMENT:
        this.isAtachment = true
        break

      case VIDEO:
        this.isVideo = true
        this.isAtachment = true
        break

      case SOUND:
        this.isSound = true
        this.isAtachment = true
        break

      case LINK:
        this.isLink = true
        this.linkType = MediaFileModel.getLinkType(this.link)
        this.isDropbox = (this.linkType === MediaFileModel.linkTypes.DROPBOX) 
        this.isGoogleDrive = (this.linkType === MediaFileModel.linkTypes.GOOGLE_DRIVE) 
        this.isGoogleDocs = (this.linkType === MediaFileModel.linkTypes.GOOGLE_DOCS) 
        this.isGoogleSheets = (this.linkType === MediaFileModel.linkTypes.GOOGLE_SHEETS) 
        this.isOneDrive = (this.linkType === MediaFileModel.linkTypes.ONE_DRIVE) 
        break
    }
  }

  static getLinkType(link) {
    let type = MediaFileModel.linkTypes.LINK
    let url = null 

    try {
      url = new URL(link)
    } catch (e) {
      return type
    }

    switch (url.hostname) {
      case MediaFileModel.linkDomains.DROPBOX:
        type = MediaFileModel.linkTypes.DROPBOX
        break
      case MediaFileModel.linkDomains.GOOGLE_DRIVE:
        type = MediaFileModel.linkTypes.GOOGLE_DRIVE
        break
      case MediaFileModel.linkDomains.GOOGLE_DOCS:
        type = MediaFileModel.linkTypes.GOOGLE_DRIVE

        const path = url.pathname.split('/')
        if (path && path.length >= 2) {
          if (path[1] === 'document') {
            type = MediaFileModel.linkTypes.GOOGLE_DOCS
          } else if (path[1] === 'spreadsheets') {
            type = MediaFileModel.linkTypes.GOOGLE_SHEETS
          } else if (path[1] === 'presentation') {
            type = MediaFileModel.linkTypes.GOOGLE_SLIDES
          }
        }
        break
      case MediaFileModel.linkDomains.ONE_DRIVE:
        type = MediaFileModel.linkTypes.ONE_DRIVE
        break
      default:
        break
    }

    return type
  }
}

export default MediaFileModel
