import Model from './Model.js';
import FormSectionModel from './FormSection.js';

export class FormModel {
  constructor(data) {
    this.id = data.id;
    this.name = data.name;
    this.description = data.description;
    this.companyId = data.company_id ?? null;
    this.checklistItemId = data.checklist_item_id;

    this.canEdit = Boolean(data.permissions?.can_edit);
    this.canDelete = Boolean(data.permissions?.can_delete);
    this.canDuplicate = Boolean(data.permissions?.can_duplicate);

    this.createdAt = data.created_at
      ? Model.prototype.toMomentDate.call(this, data.created_at).toDate()
      : null;
    this.createdBy = data.created_by ?? null;

    this.updatedAt = data.updated_at
      ? Model.prototype.toMomentDate.call(this, data.updated_at).toDate()
      : null;

    this.sections = data.sections?.map(section => new FormSectionModel(section)) ?? [];
  }
}

export default FormModel;
