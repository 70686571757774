import Model from '@/models/Model'

class FolderModel extends Model {
  constructor(data) {
    super(data)

    this.id = data.id
    this.name = data.name

    this.chatId = data.chat_id
    this.projectId = data.project_id
    this.parentId = data.parent_id
    this.companyId = data.company_id

    this.filesCount = data.files_count
    this.foldersCount = data.folders_count

    this.access = Boolean(data.access)
    this.percentDownload = 0

    this.createdAt = this.fixData(data.created_at_utc)
    this.createdAtM = this.toMomentDate(data.created_at_utc)
  }
}

export default FolderModel
