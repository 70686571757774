import { addDays, addSeconds, getDay, getHours, getMinutes, getSeconds, subDays, subSeconds } from "date-fns";
import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";
import { checkBusinessDaysOrThrowError } from "./errors";
import { getBusinessDayIntervalDetails, getFirstInterval, getIntervalEnd, getIntervalStart, getLastInterval } from "./intervals";
import { addBusinessSeconds, getBusinessIntervalDuration, subBusinessSeconds } from "./time";
import { DEFAULT_TIMEZONE } from "./_defaults";

const nextDay = day => (day + 1) % 7;
const previousDay = day => (day + 6) % 7; // (day + 6) === (7 + day - 1)

export const getBusinessDay = (date, timezone = DEFAULT_TIMEZONE) => getDay(utcToZonedTime(date, timezone));

export const hasBusinessDays = (businessTime) => {
  return Object.values(businessTime).some(Boolean);
};

export const isBusinessDay = (date, { businessTime, timezone = DEFAULT_TIMEZONE }) => {
  const day = getBusinessDay(date, timezone);
  return Boolean(businessTime[day]);
};

export const getPreviousBusinessDay = (date, { businessTime, timezone = DEFAULT_TIMEZONE }) => {
  checkBusinessDaysOrThrowError(businessTime);

  const initialDay = getBusinessDay(date, timezone);
  let day = previousDay(initialDay);
  let counter = 1;

  while (!businessTime[day]) {
    day = previousDay(day);
    counter++;
  }

  return zonedTimeToUtc(subDays(utcToZonedTime(date, timezone), counter), timezone);
};

export const getNextBusinessDay = (date, { businessTime, timezone = DEFAULT_TIMEZONE }) => {
  checkBusinessDaysOrThrowError(businessTime);

  const initialDay = getBusinessDay(date, timezone);
  let day = nextDay(initialDay);
  let counter = 1;

  while (!businessTime[day]) {
    day = nextDay(day);
    counter++;
  }

  return zonedTimeToUtc(addDays(utcToZonedTime(date, timezone), counter), timezone);
};

export const startOfBusinessDay = (date, timeOptions) => {
  if (!isBusinessDay(date, timeOptions)) {
    date = getPreviousBusinessDay(date, timeOptions);
  }

  const interval = getFirstInterval(date, timeOptions);
  return getIntervalStart(interval);
};

export const endOfBusinessDay = (date, timeOptions) => {
  if (!isBusinessDay(date, timeOptions)) {
    date = getNextBusinessDay(date, timeOptions);
  }

  const interval = getLastInterval(date, timeOptions);
  return getIntervalEnd(interval);
};

export const shiftToNearBusinessDayStart = (date, timeOptions, toLeft = false) => {
  if (isBusinessDayStart(date, timeOptions)) {
    return date;
  }

  if (!toLeft) {
    date = getNextBusinessDay(date, timeOptions);
  }

  return startOfBusinessDay(date, timeOptions);
};

export const shiftToNearBusinessDayEnd = (date, timeOptions, toLeft = false) => {
  if (isBusinessDayEnd(date, timeOptions)) {
    return date;
  }

  if (toLeft) {
    date = getPreviousBusinessDay(date, timeOptions);
  }

  let resultDate = endOfBusinessDay(date, timeOptions)

  // TODO: remove the date correction after changing the working time calculation math
  // 00:00:00.000  should not be an end time of an interval, the max end time is 23:59:59.999
  if (getHours(resultDate) === 0 && getMinutes(resultDate) === 0 && getSeconds(resultDate) === 0) {
    resultDate = subSeconds(resultDate, 1);
  }

  return resultDate;
};

export const getBusinessDayInterval = (date, timeOptions) => {
  const startDate = startOfBusinessDay(date, timeOptions);
  const endDate = endOfBusinessDay(date, timeOptions);
  return [startDate, endDate];
};

/**
 * This function differs from getBusinessDayInterval in that
 * it returns dates that belong to the same day.
 *
 * NOTE:
 * The start date cannot be earlier than 00:00.
 * The end date cannot be later than 23:59.
 */
export const getNearBusinessDayInterval = (date, timeOptions, toLeft = false) => {
  if (!isBusinessDay(date, timeOptions)) {
    const startDate = shiftToNearBusinessDayStart(date, timeOptions, toLeft);
    const endDate = shiftToNearBusinessDayEnd(date, timeOptions, toLeft);
    return [startDate, endDate];
  }
  const startDate = startOfBusinessDay(date, timeOptions);
  const endDate = shiftToNearBusinessDayEnd(date, timeOptions);
  return [startDate, endDate];
};

export const isBusinessDayStart = (date, timeOptions) => {
  if (!isBusinessDay(date, timeOptions)) {
    return false;
  }

  const interval = getFirstInterval(date, timeOptions);
  const dayStart = getIntervalStart(interval);

  return date.valueOf() === dayStart.valueOf();
};

export const isBusinessDayEnd = (date, timeOptions) => {
  if (!isBusinessDay(date, timeOptions)) {
    return false;
  }

  const interval = getLastInterval(date, timeOptions);
  const dayEnd = getIntervalEnd(interval);

  return date.valueOf() === dayEnd.valueOf();
};

export const addBusinessDays = (date, days, timeOptions) => {
  if (days < 0) {
    throw new Error('The value of days must be a positive number');
  }

  if (!isBusinessDay(date, timeOptions)) {
    date = shiftToNearBusinessDayStart(date, timeOptions);
  }

  const details = getBusinessDayIntervalDetails(date, timeOptions);
  const durationTillEnd = getBusinessIntervalDuration([date, details.end], timeOptions);

  const notFilledDay = durationTillEnd / details.duration;

  while(days > notFilledDay) {
    date = getNextBusinessDay(date, timeOptions);
    days -= 1;
  }

  const availableDay = 1 + days - notFilledDay;
  if (availableDay) {
    const { duration, start } = getBusinessDayIntervalDetails(date, timeOptions);
    const additionalSeconds = Math.ceil(duration * availableDay);
    date = addBusinessSeconds(start, additionalSeconds, timeOptions);
  }

  return date;
};

export const subBusinessDays = (date, days, timeOptions) => {
  if (days < 0) {
    throw new Error('The value of days must be a positive number');
  }

  if (!isBusinessDay(date, timeOptions)) {
    date = shiftToNearBusinessDayEnd(date, timeOptions, true);
  }

  const details = getBusinessDayIntervalDetails(date, timeOptions);
  const durationFromStart = getBusinessIntervalDuration([details.start, date], timeOptions);

  const notFilledDay = durationFromStart / details.duration;

  while(days > notFilledDay) {
    date = getPreviousBusinessDay(date, timeOptions);
    days -= 1;
  }

  const availableDay = 1 + days - notFilledDay;
  if (availableDay) {
    const { duration, end } = getBusinessDayIntervalDetails(date, timeOptions);
    const subtractionSeconds = Math.ceil(duration * availableDay);
    date = subBusinessSeconds(end, subtractionSeconds, timeOptions);
  }

  return date;
};
