import UiQuill from '@ui/components/quill'
import UiSelectMembers from './SelectMembers/index.vue';

import UiWarningBlock from './WarningBlock'
import UiFindMember from './FindMember'
import UiButtonC from './Button'
import UiInputC from './Input'
import UiSelectMultiple from './SelectMultiple'
import UiLineLoader from './LineLoader'

import UiFormTextarea from '@ui/form/modules/textarea'
import UiFormInput from '@ui/form/modules/Input'
import UiFormInputAutofill from '@ui/form/modules/autofill'

import UiCheckbox from '@ui/controls/checkbox'
import UiRadioInput from '@ui/controls/radio-input'
import UiCounter from '@ui/controls/counter'
import UiScrollbar from '@ui/controls/scrollbar'

import UiLink from '@ui/controls/link'

import UiInput from '@ui/controls/input'
import UiInputTextarea from '@ui/controls/input/modules/textarea.vue'
import UiInputPrice from '@ui/controls/input/price.vue'
import UiInputFormula from '@ui/controls/input/formula.vue'

import UiDatePicker from '@ui/controls/date-picker'
import UiToggle from '@ui/controls/toggle'
import UiButton from '@ui/controls/button'
import UiTextButton from '@ui/controls/text-button'
import UiUsersCounter from '@ui/controls/users-counter'
import UiIcon from '@ui/controls/icon'

import UiDateInput from '@ui/components/date-input'
import UiTimeInput from '@ui/components/time-input'
import UiLocation from '@ui/components/location'
import UiEmailInput from '@ui/components/email-input'
import UiPickColor from '@ui/components/pick-color'
import UiUsersRowAvatars from '@ui/components/users-row-avatars'
import UiAvatar from '@ui/components/avatar'
import UiAvatarSelect from '@ui/components/avatar-select'
import UiErrorsBlock from '@ui/components/errors-block'
import UiFindAndSelectUser from '@ui/components/find-and-select-user'
import UiSelectStatus from '@ui/controls/select-status'
import UiDropSelect from '@ui/components/drop-select'
import UiCustomSelect from '@ui/components/custom-select'
import UiEditFormRow from '@ui/components/edit-form-row'
import UiEditField from '@ui/components/edit-field'
import UiEditFormField from '@ui/components/edit-form-field'
import UiStick from '@ui/components/stick'
import UiSvg from '@ui/controls/svg'
import UiDrogArea from '@ui/DropArea/Index'
import UiTag from './Tag'
import UiMenuButton from './MenuButton'

import { installUiSearchListUsersComponents } from '@ui/components/SearchDropList';

import UITimePicker from './TimePicker'
import UIFindList from './FindList/index.vue';

export const installComponents = app => {
  installUiSearchListUsersComponents(app);

  app.component('UiQuill', UiQuill)
  app.component('UiSelectMembers', UiSelectMembers)
  app.component('UiWarningBlock', UiWarningBlock)
  app.component('UiFindMember', UiFindMember)
  app.component('UiButton', UiButtonC)
  app.component('UiInputC', UiInputC)
  app.component('UiSelectMultiple', UiSelectMultiple)
  app.component('UiLineLoader', UiLineLoader)
  app.component('ui-form-textarea', UiFormTextarea)
  app.component('ui-form-input', UiFormInput)
  app.component('ui-form-autofill', UiFormInputAutofill)

  app.component('ui-checkbox', UiCheckbox)
  app.component('ui-radio-input', UiRadioInput)
  app.component('ui-counter', UiCounter)
  app.component('ui-scrollbar', UiScrollbar)

  app.component('ui-link', UiLink)

  app.component('ui-input', UiInput)
  app.component('ui-input-price', UiInputPrice)
  app.component('ui-input-formula', UiInputFormula)
  app.component('ui-textarea', UiInputTextarea)

  app.component('ui-date-picker', UiDatePicker)
  app.component('ui-toggle', UiToggle)
  app.component('ui-button', UiButton)
  app.component(UiTextButton.name, UiTextButton)
  app.component('ui-users-counter', UiUsersCounter)

  app.component('ui-date-input', UiDateInput)
  app.component('ui-time-input', UiTimeInput)
  app.component('ui-location', UiLocation)
  app.component('ui-email-input', UiEmailInput)
  app.component('ui-pick-color', UiPickColor)
  app.component('ui-users-row-avatars', UiUsersRowAvatars)
  app.component('ui-avatar', UiAvatar)
  app.component('ui-avatar-select', UiAvatarSelect)
  app.component('ui-errors-block', UiErrorsBlock)
  app.component('ui-find-and-select-user', UiFindAndSelectUser)
  app.component('ui-select-status', UiSelectStatus)
  app.component('ui-drop-select', UiDropSelect)
  app.component('ui-custom-select', UiCustomSelect)
  app.component('ui-edit-form-row', UiEditFormRow)
  app.component(UiEditField.name, UiEditField)
  app.component(UiEditFormField.name, UiEditFormField)
  app.component('ui-icon', UiIcon)
  app.component('ui-stick', UiStick)
  app.component('ui-svg', UiSvg)
  app.component('ui-drop-area', UiDrogArea)
  app.component('ui-time-picker', UITimePicker)
  app.component('UIFindList', UIFindList)

  app.component(UiTag.name, UiTag);
  app.component(UiMenuButton.name, UiMenuButton);
};
