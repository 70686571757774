import { cloneDeep } from 'lodash';
import * as TYPES from '@/store/types.js';

import CompanyProjectTemplates from '@/provider/v1/companyProjectTemplates.js';

// initial state
const state = {
  templates: [],
  loading: false,
};

// actions
const actions = {
  getProjectTemplates({ commit }, { companyId }) {
    commit(TYPES.COMPANY_PROJECT_TEMPLATES.SET_LOADING, true);

    return CompanyProjectTemplates.getProjectTemplates({ companyId })
      .then(templates => {
        commit(TYPES.COMPANY_PROJECT_TEMPLATES.SET_PROJECT_TEMPLATES, templates);
      })
      .finally(() => {
        commit(TYPES.COMPANY_PROJECT_TEMPLATES.SET_LOADING, false);
      });
  },

  updateProjectTemplates(
    { commit },
    {
      companyId,
      templates,
    },
  ) {
    const projectIds = templates.map(({ id }) => id);

    commit(TYPES.COMPANY_PROJECT_TEMPLATES.SET_LOADING, true);

    return CompanyProjectTemplates.updateProjectTemplates({
      companyId,
      projectIds,
    })
      .then(() => {
        commit(TYPES.COMPANY_PROJECT_TEMPLATES.SET_PROJECT_TEMPLATES, cloneDeep(templates));
      })
      .finally(() => {
        commit(TYPES.COMPANY_PROJECT_TEMPLATES.SET_LOADING, false);
      });
  },

  deleteProjectTemplate(
    { commit },
    {
      companyId,
      projectId,
    },
  ) {
    return CompanyProjectTemplates.deleteProjectTemplate({
      companyId,
      projectId
    })
      .then(() => {
        commit(TYPES.COMPANY_PROJECT_TEMPLATES.DELETE_PROJECT_TEMPLATES, projectId);
      });
  },

  updateTemplateProjectNumber(
    {
      commit,
      dispatch,
    },
    {
      projectId,
      number,
    },
  ) {
    return dispatch('Projects/updateProject', { projectId, number }, { root: true })
      .then(() => {
        commit(TYPES.COMPANY_PROJECT_TEMPLATES.UPDATE_TEMPLATE_PROJECT_NUMBER, { projectId, number });
      });
  },
};

// mutations
const mutations = {
  [TYPES.COMPANY_PROJECT_TEMPLATES.SET_LOADING](state, loading) {
    state.loading = loading;
  },

  [TYPES.COMPANY_PROJECT_TEMPLATES.INIT_PROJECT_TEMPLATES](state, data) {
    const list = Array.isArray(data) ? data : [data];
    const ids = list.map(e => e.id);

    state.templates = [
      ...state.templates.filter(e => !ids.includes(e.id)),
      ...list.filter(e => !e.isDeleted),
    ];
  },

  [TYPES.COMPANY_PROJECT_TEMPLATES.SET_PROJECT_TEMPLATES](state, data) {
    const list = Array.isArray(data) ? data : [data];
    state.templates = list;
  },

  [TYPES.COMPANY_PROJECT_TEMPLATES.DELETE_PROJECT_TEMPLATES](state, ids) {
    ids = Array.isArray(ids) ? ids : [ids];

    state.templates = state.templates.filter(e => !ids.includes(e.id));
  },

  [TYPES.COMPANY_PROJECT_TEMPLATES.UPDATE_TEMPLATE_PROJECT_NUMBER](state, { projectId, number }) {
    const template = state.templates.find(template => template.id === projectId)
    if (!template) return;
    template.number = number;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
