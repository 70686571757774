import { setDefaultOptions } from 'date-fns';
import * as TYPES from '@/store/types';
import { LANGUAGES } from '@/constants';
import { DateFnsLocaleMap, resolveDateFnsLocale } from '@/helpers/dates/_defaults';

const availableLocales = Object.values(LANGUAGES);

export const setDateFnsLocale = language => {
  let locale = resolveDateFnsLocale(language);

  if (!locale) {
    console.warn('Using the fallback locale \`en_GB\` for date-fns');
    locale = DateFnsLocaleMap[LANGUAGES.ENGLISH];
  }

  setDefaultOptions({
    locale,
  });
};

const setDocumentLocale = locale => {
  window.document.documentElement.lang = locale;
};

const setMomentLocale = (moment, locale) => {
  moment.locale(locale);
};

export const createI18nPlugin = (i18n, moment) => {
  return store => {
    const { language } = store.state.App;

    if (i18n.global.locale !== language) {
      i18n.global.locale = language;
    }

    setMomentLocale(moment, language);
    setDocumentLocale(language);
    setDateFnsLocale(language);

    store.subscribe(({ type, payload }) => {
      if (type === TYPES.APP.MOD.UPDATE_LANGUAGE) {
        if (!availableLocales.includes(payload)) {
          console.warn(`[Vuex I18nPlugin] Not available locale: ${payload}`);
        }
        i18n.global.locale = payload;
        setMomentLocale(moment, payload);
        setDocumentLocale(payload);
        setDateFnsLocale(payload);
      }

      if (type === TYPES.USERS.MOD.INIT_USER) {
        store.dispatch('App/updateLanguage', payload.language);
      }
    });
  }
}