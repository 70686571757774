import FormFieldModel from './FormField.js';

export class FormSectionModel {
  constructor(data) {
    this.id = data.id;
    this.name = data.name;
    this.description = data.description;
    this.position = data.position;
    this.fields = data.fields
      .map(field => new FormFieldModel(field))
      .sort((a, b) => a.position - b.position);
  }
}

export default FormSectionModel;
