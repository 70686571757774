import Provider from "@provider";
import {ProjectStatusModel} from "@/models";

export default {
  addStatus: (form) => {
    return new Promise((resolve, reject) => {
      Provider.api.post("/companyprojectstatus", form)
        .then(response => resolve(new ProjectStatusModel(response.data)))
        .catch(reject);
    });
  },

  getStatuses: () => {
    return new Promise((resolve, reject) => {
      Provider.api.get("/companyprojectstatus")
        .then(response => resolve(response.data.map(e => new ProjectStatusModel(e))))
        .catch(reject);
    });
  },

  getStatus: (id) => {
    return new Promise((resolve, reject) => {
      Provider.api.get(`/companyprojectstatus/${id}`)
        .then(response => resolve(new ProjectStatusModel(response.data)))
        .catch(reject);
    });
  },

  updateStatus: (form) => {
    return new Promise((resolve, reject) => {
      Provider.api.put(`/companyprojectstatus/${form.id}`, form)
        .then(response => resolve(new ProjectStatusModel(response.data)))
        .catch(reject);
    });
  },

  deleteStatus: (id) => {
    return new Promise((resolve, reject) => {
      Provider.api.delete(`/companyprojectstatus/${id}`)
        .then(response => resolve(response.data))
        .catch(reject);
    });
  }
};
