import NotificationsProvider from '@provider/notifications'
import * as TYPES from '@/store/types'

// initial state
const state = {
  notifications: [],
}

// getters
const getters = {
  notifications: state => state.notifications,
  hasUnread: state => !!state.notifications.filter(e => !e.isDeleted).find(e => !e.readAtM),
}

// actions
const actions = {
  getNotifications({ commit }) {
    return new Promise((resolve, reject) => {
      NotificationsProvider.getNotifications()
        .then(list => {
          commit(TYPES.NOTIFICATIONS.INIT_NOTIFICATIONS, list)
          resolve(list)
        })
        .catch(reject)
    })
  },

  markNotificationsAsUnread({ commit }, ids) {
    return new Promise((resolve, reject) => {
      NotificationsProvider.markNotificationsAsUnread(ids)
        .then(list => {
          commit(TYPES.NOTIFICATIONS.INIT_NOTIFICATIONS, list)
          resolve(list)
        })
        .catch(reject)
    })
  },

  markNotificationAsRead({ commit }, id) {
    return new Promise((resolve, reject) => {
      NotificationsProvider.markNotificationAsRead(id)
        .then(updated => {
          commit(TYPES.NOTIFICATIONS.INIT_NOTIFICATIONS, updated)
          resolve(updated)
        })
        .catch(reject)
    })
  },

  markAllNotificationsAsRead({ commit }) {
    return new Promise((resolve, reject) => {
      NotificationsProvider.markAllNotificationsAsRead()
        .then(updated => {
          commit(TYPES.NOTIFICATIONS.INIT_NOTIFICATIONS, updated)
          resolve(updated)
        })
        .catch(reject)
    })
  },
}

// mutations
const mutations = {
  [TYPES.NOTIFICATIONS.INIT_NOTIFICATIONS](state, data) {
    let notifications = Array.isArray(data) ? data : [data],
      ids = notifications.map(e => e.id)

    state.notifications = [
      ...state.notifications.filter(e => !ids.includes(e.id)),
      ...notifications,
    ]
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
