const ScrollList = new Map();

export const installScrollSaverPlugin = app => {
  app.directive('scroll-saver', {
    mounted: function (el, binding) {
      const id = binding.value;

      if (ScrollList.has(id)) {
        const scrollData = ScrollList.get(id);

        el.scrollTop = scrollData.top;
        el.scrollLeft = scrollData.left;
      }

      let f = function () {
        if (!id) {
          el.removeEventListener('scroll', f);
        } else {
          ScrollList.set(id, { top: el.scrollTop, left: el.scrollLeft });
        }
      };
      el.addEventListener('scroll', f);
    },
  });
};
