import Model from '@/models/Model';

class FormLogModel extends Model {
  constructor(data) {
    super(data);

    this.id = Number(data.id);
    this.formId = Number(data.form_id);
    this.userId = data.user_id ? Number(data.user_id) : null;
    this.action = data.action;
    this.actionType = data.action_type;

    this.updatedAtM = this.toMomentDate(data.updated_at);
    this.createdAtM = this.toMomentDate(data.created_at);
  }
}

export default FormLogModel;
