import Provider from "@provider";
import {CommentModel} from "@/models";

export default {
  addComment: ({projectId, comment, checklistItemId}) => {
    return new Promise((resolve, reject) => {
      Provider.api.post("/comments", {
        project_id: projectId,
        comment,
        checklist_item_id: checklistItemId
      })
      .then(response => resolve(new CommentModel(response.data)))
      .catch(reject);
    });
  },

  getComment: ({id}) => {
    return new Promise((resolve, reject) => {
      Provider.api.get(`/comments/${id}`)
        .then(response => resolve(new CommentModel(response.data)))
        .catch(reject);
    });
  },

  updateComment: ({id, comment}) => {
    return new Promise((resolve, reject) => {
      Provider.api.put(`/comments/${id}`, {comment})
        .then(response => resolve(new CommentModel(response.data)))
        .catch(reject);
    });
  },

  deleteComment: ({id}) => {
    return new Promise((resolve, reject) => {
      Provider.api.delete(`/comments/${id}`)
        .then(resolve)
        .catch(reject);
    });
  }
}