import Provider from '@provider'
import { FilterModel } from '@/models'

export default {
  getFilters: () => {
    return new Promise((resolve, reject) => {
      Provider.api
        .get('/filters')
        .then(response =>
          resolve(response.data.map(filter => new FilterModel(filter)))
        )
        .catch(reject)
    })
  },

  addFilter: ({ name, personal, type, fields }) => {
    return new Promise((resolve, reject) => {
      Provider.api
        .post('/filters', {
          name,
          personal: Number(personal),
          type,
          fields,
        })
        .then(response => resolve(new FilterModel(response.data)))
        .catch(reject)
    })
  },

  updateFilter: ({ id, name, personal, type, fields }) => {
    return new Promise((resolve, reject) => {
      Provider.api
        .put(`/filters/${id}`, {
          name,
          personal: personal != undefined ? Number(personal) : undefined,
          type,
          fields,
        })
        .then(response => resolve(new FilterModel(response.data)))
        .catch(reject)
    })
  },

  deleteFilter: id => {
    return new Promise((resolve, reject) => {
      Provider.api
        .delete(`/filters/${id}`)
        .then(response => resolve(new FilterModel(response.data)))
        .catch(reject)
    })
  },
}
