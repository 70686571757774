export function canEditChecklistItem(task) {
  return task.canEdit === true;
}

export function getCommonUsersAndResources(tasks) {
  const { userIds, resourceIds } = tasks.reduce(
    (common, item, index) => {
      if (index === 0) {
        item.userIds.forEach(userId => common.userIds.add(userId));
        item.resourceIds.forEach(resourceId => common.resourceIds.add(resourceId));
      } else {
        const itemUserIds = new Set(item.userIds);
        const itemResourceIds = new Set(item.resourceIds);

        [...common.userIds].forEach(
          userId => !itemUserIds.has(userId) && common.userIds.delete(userId)
        );
        [...common.resourceIds].forEach(
          resourceId => !itemResourceIds.has(resourceId) && common.resourceIds.delete(resourceId)
        );
      }
      return common;
    },
    {
      userIds: new Set(),
      resourceIds: new Set(),
    }
  );

  return {
    userIds: [...userIds],
    resourceIds: [...resourceIds],
  };
}
