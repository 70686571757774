import Provider from '@provider'
import { PlanningResourceModel } from '@/models'

export default {
  addResource: ({
    userId,
    checklistItemId,
    resourceGroupId,
    name,
    position,
  }) => {
    return new Promise((resolve, reject) => {
      Provider.api
        .post(`/planning/resources/`, {
          user_id: userId,
          checklist_item_id: checklistItemId,
          resource_group_id: resourceGroupId,
          name,
          position,
        })
        .then(response => resolve(new PlanningResourceModel(response.data)))
        .catch(reject)
    })
  },

  getResources: () => {
    return new Promise((resolve, reject) => {
      Provider.api
        .get(`/planning/resources/`)
        .then(response =>
          resolve(response.data.map(e => new PlanningResourceModel(e)))
        )
        .catch(reject)
    })
  },

  getResource: ({ id }) => {
    return new Promise((resolve, reject) => {
      Provider.api
        .get(`/planning/resources/${id}`)
        .then(response => resolve(new PlanningResourceModel(response.data)))
        .catch(reject)
    })
  },

  updateResource: ({ id, name, position, resourceGroupId }) => {
    return new Promise((resolve, reject) => {
      Provider.api
        .put(`/planning/resources/${id}`, {
          name,
          position,
          resource_group_id: resourceGroupId,
        })
        .then(response => resolve(new PlanningResourceModel(response.data)))
        .catch(reject)
    })
  },

  deleteResource: ({ id }) => {
    return new Promise((resolve, reject) => {
      Provider.api
        .delete(`/planning/resources/${id}`)
        .then(response => resolve(response.data))
        .catch(reject)
    })
  },
}
