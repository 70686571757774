import store from '@/store'
import Company from '@/models/Company';
import Project from '@/models/Project';
import User from '@/models/User';
import { format, parse } from 'date-fns';
import { PROJECT_IMPORT_STATUS } from '@/constants'

export const PROJECT_IMPORT_ERROR_STATUSES = [
  PROJECT_IMPORT_STATUS.NAME_REQUIRED,
  PROJECT_IMPORT_STATUS.RECORD_EXIST,
  PROJECT_IMPORT_STATUS.WRONG_DATETIME,
  PROJECT_IMPORT_STATUS.CANT_CREATE
]

export class ProjectsImportFileModel {
  constructor(data) {
    this.id = data.id
    this.filename = data.filename
    this.original_filename = data.original_filename
    this.path = data.path
    this.status = data.status
    this.type = data.type
    this.createdBy = data.created_by
    this.user = data.user ? new User(data.user) : null

    this.company = new Company(data.company)
    this.companyId = data.company_id

    this.createdAt = parse(data.created_at, 'yyyy-MM-dd HH:mm:ss', new Date())
    this.createdAtLabel = format(this.createdAt, 'dd LLL. yyyy HH:mm').toLowerCase()
    this.createdAtUtc = data.created_at_utc
    this.updatedAt = data.updated_at
    this.updatedAtUtc = data.updated_at_utc
  }

  get projectImports() {
    return store.getters['ProjectsImports/getFileImports'](this.id)
  }
  get projectsImportsCurrentCount() {
    return this.projectImports.length || 0
  }
  get projectsImportsTotalCount() {
    return this.projectImports?.[0]?.totalRecords || 0
  }
  get isInProgress() {
    return this.projectsImportsCurrentCount !== this.projectsImportsTotalCount
  }
}

export class ProjectImportModel {
  constructor(data) {
    this.id = data.id
    this.name = data.name
    this.fileId = data.file_id
    this.description = data.description
    this.createdBy = data.created_by
    this.location = data.location

    this.project = data.project ? new Project(data.project) : null
    this.projectName = data.project_name
    this.projectNumber = data.project_number
    this.user = data.user ? new User(data.user) : null

    this.calculationsNotes = data.calculations_notes
    this.companyId = data.company_id

    this.contactPerson = data.contact_person
    this.contactPersonDescription = data.contact_person_description
    this.contactPersonEmail = data.contact_person_email
    this.contactPersonPhone = data.contact_person_phone

    this.createdAt = parse(data.created_at, 'yyyy-MM-dd HH:mm:ss', new Date())
    this.createdAtLabel = format(this.createdAt, 'dd LLL. yyyy HH:mm').toLowerCase()
    this.isError = PROJECT_IMPORT_ERROR_STATUSES.includes(data.status)
    this.createdAtUtc = data.created_at_utc
    this.startAt = data.start_at
    this.startAtUtc = data.start_at_utc
    this.updatedAt = data.updated_at
    this.updatedAtUtc = data.updated_at_utc
    this.endAt = data.end_at
    this.endAtUtc = data.end_at_utc
    this.notifyAt = data.notify_at
    this.notifyAtUtc = data.notify_at_utc

    this.currentRecord = data.current_record
    this.totalRecords = data.total_records

    this.number = data.number
    this.projectId = data.project_id

    this.status = data.status
    this.statusMessage = data.status_message
  }
}

export default {
  ProjectsImportFileModel,
  ProjectImportModel
}
