import { nextTick } from 'vue';

const GROUPS = {};

function setChildSizes(el, target, { height, width }) {
  if (!el || !target) {
    debug('[v-size-sync] Error set child sizes', el, target);
    return;
  }

  const widthEl = parseFloat(window.getComputedStyle(el).width),
    widthHeight = parseFloat(window.getComputedStyle(el).height),
    isHidden = el.style.display == 'none';

  if (widthHeight == 0 || widthEl == 0 || isHidden) target.style.display = 'none';
  else target.style.removeProperty('display');

  if (height) {
    target.style.height = `${widthHeight}px`;
  }

  if (width) {
    target.style.minWidth = `${widthEl}px`;
    target.style.maxWidth = `${widthEl}px`;
  }
}

export const installSizeSyncDirective = app => {
  app.directive('size-sync', {
    created(el, binding, vnode) {
      if (!binding.value) return;

      const args = binding.value.split('.'),
        group = args[0];

      const modifiers = {
        isParent: args.includes('parent'),
        isChild: args.includes('child'),
        height: args.includes('h'),
        width: args.includes('w'),
      };

      if (!GROUPS.hasOwnProperty(group)) GROUPS[group] = { list: [], parent: null };

      if (modifiers.isChild) {
        GROUPS[group].list.push({ target: el, modifiers });

        setTimeout(() => {
          setChildSizes(GROUPS[group].parent, el, modifiers);
        });
      }

      let onResize = () => {
        // requestAnimationFrame(() => {
        GROUPS[group].list
          .filter(({ e }) => e !== el)
          .forEach(({ modifiers, target }) => {
            setChildSizes(el, target, modifiers);
          });
        // })
      };

      if (modifiers.isParent) {
        GROUPS[group].parent = el;

        new ResizeObserver(() => {
          nextTick(() => {
            onResize();
          });
        }).observe(el);

        nextTick(() => {
          onResize();
        });
      }
    },
  });
};
