import _ from 'lodash';

export default {
  canImportPdf: ({ $store, $router }) => {
    const importPdf = _.get($store, 'state.Companies.company.importPdf', false);

    if (importPdf) return;

    $router.push({ name: 'dashboard' });

    throw `Don't access to pdf imports`;
  },
};
