<template>
  <div :class="classObject">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'ContentPlaceholders',
  props: {
    rounded: {
      type: Boolean,
      default: false,
    },
    centered: {
      type: Boolean,
      default: false,
    },
    animated: {
      type: Boolean,
      default: true,
    },
    /**
     * @values primary, info
     */
    kind: {
      type: String,
      default: undefined,
    },
  },
  computed: {
    classObject() {
      const { kind } = this;
      return {
        'vue-content-placeholders': true,
        'vue-content-placeholders-is-rounded': this.rounded,
        'vue-content-placeholders-is-centered': this.centered,
        'vue-content-placeholders-is-animated': this.animated,
        [`vue-content-placeholders--kind_${kind}`]: Boolean(kind),
      };
    },
  },
};
</script>

<style lang="scss">
@import './styles.scss';
</style>
