import * as TYPES from '@/store/types'
import RegistrationProvider from '@provider/registrations'

import { createZonedDateFromUtcDate } from '@/helpers/dates/index.js';

const isSameWeek = ({ date, dateFrom, dateTill }) => {
  const dateFromInUtc = createZonedDateFromUtcDate(dateFrom);

  return dateFrom && dateTill
    ? date.isSame(dateFromInUtc, 'week')
    : true;
};

// initial state
const state = {
  registrations: [],
}

// getters
const getters = {
  registrations: state => ({ dateFrom, dateTill, projectId } = {}) => {
    const dateFromInUtc = createZonedDateFromUtcDate(dateFrom);
    const dateTillInUtc = createZonedDateFromUtcDate(dateTill);

    return state.registrations.filter(e => {
      if (projectId) return e.project && e.project.id == projectId

      return dateFrom && dateTill
        ? e.dateMoment.isSameOrAfter(dateFromInUtc) &&
            e.dateMoment.isSameOrBefore(dateTillInUtc)
        : true
    })
  },

  hoursRegistrationsByProjectId: state => {
    const { registrations } = state;

    const hoursRegistrationsByProjectId = registrations.reduce((hashTable, registration) => {
      const { project, registrationType } = registration;

      if (project?.id && registrationType.isHoursProject) {
        hashTable[project.id] = (hashTable[project.id] ?? []);
        hashTable[project.id].push(registration);
      }

      return hashTable;
    }, {});

    return hoursRegistrationsByProjectId;
  },

  registrationsHoursProject: state => ({
    dateFrom,
    dateTill,
    projectId,
  } = {}) =>
    state.registrations.filter(e => {
      if (!e.registrationType.isHoursProject) return false

      if (projectId) return e.project && e.project.id == projectId

      return isSameWeek({ date: e.dateMoment, dateFrom, dateTill });
    }),

  registrationsHours: state => ({ dateFrom, dateTill } = {}) =>
    state.registrations.filter(e => {
      if (!e.registrationType.isHours) return false

      return isSameWeek({ date: e.dateMoment, dateFrom, dateTill });
    }),

  registrationsMaterials: state => ({ dateFrom, dateTill, projectId } = {}) =>
    state.registrations.filter(e => {
      if (!e.registrationType.isMaterial) return false

      if (projectId) return e.project && e.project.id == projectId

      return isSameWeek({ date: e.dateMoment, dateFrom, dateTill });
    }),

  registrationsProjectHoursSumm: state => ({ projectId } = {}) =>
    state.registrations
      .filter(
        e =>
          e.registrationType.isHoursProject &&
          e.project &&
          e.project.id == projectId
      )
      .map(e => e.registrationType.price * e.amount)
      .reduce((a, b) => a + b, 0),

  registrationsMaterialSumm: state => ({ projectId } = {}) =>
    state.registrations
      .filter(
        e =>
          e.registrationType.isMaterial &&
          e.project &&
          e.project.id == projectId
      )
      .map(e => e.registrationType.price * e.amount)
      .reduce((a, b) => a + b, 0),
}

// actions
const actions = {
  addRegistration({ commit }, form) {
    return new Promise((resolve, reject) => {
      RegistrationProvider.addRegistration(form)
        .then(registration => {
          commit(TYPES.REGISTRATIONS.INIT_REGISTRATIONS, registration)
          resolve(registration)
        })
        .catch(reject)
    })
  },

  getRegistrations({ commit }, form) {
    return new Promise((resolve, reject) => {
      RegistrationProvider.getRegistrations(form)
        .then(registrations => {
          if (registrations?.length) {
            commit(TYPES.REGISTRATIONS.INIT_REGISTRATIONS, registrations)
          }
          resolve(registrations)
        })
        .catch(reject)
    })
  },

  getRegistration({ commit }, { id }) {
    return new Promise((resolve, reject) => {
      RegistrationProvider.getRegistration({ id })
        .then(registration => {
          commit(TYPES.REGISTRATIONS.INIT_REGISTRATIONS, registration)
          resolve(registration)
        })
        .catch(reject)
    })
  },

  updateRegistration({ commit }, form) {
    return new Promise((resolve, reject) => {
      RegistrationProvider.updateRegistration(form)
        .then(registration => {
          commit(TYPES.REGISTRATIONS.INIT_REGISTRATIONS, registration)
          resolve(registration)
        })
        .catch(reject)
    })
  },

  deleteRegistration({ commit }, { id }) {
    return new Promise((resolve, reject) => {
      RegistrationProvider.deleteRegistration({ id })
        .then(() => {
          commit(TYPES.REGISTRATIONS.DELETE_REGISTRATION, id)
          resolve()
        })
        .catch(reject)
    })
  },
}

// mutations
const mutations = {
  [TYPES.REGISTRATIONS.INIT_REGISTRATIONS](state, data) {
    let list = Array.isArray(data) ? data : [data],
      ids = list.map(e => e.id)

    state.registrations = [
      ...state.registrations.filter(e => !ids.includes(e.id)),
      ...list.filter(e => e.canView && !e.isDeleted),
    ]
  },

  [TYPES.REGISTRATIONS.DELETE_REGISTRATION](state, id) {
    state.registrations = state.registrations.filter(e => e.id != id)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
