import Provider from '@provider'
import { NotificationModel } from '@/models'

export default {
  getNotifications: () => {
    return new Promise((resolve, reject) => {
      Provider.api
        .get('/notifications')
        .then(response =>
          resolve(response.data.map(e => new NotificationModel(e)))
        )
        .catch(reject)
    })
  },

  markNotificationAsRead: id => {
    return new Promise((resolve, reject) => {
      Provider.api
        .post(`/notifications/markread/${id}`)
        .then(response => resolve(new NotificationModel(response.data)))
        .catch(reject)
    })
  },

  markAllNotificationsAsRead: () => {
    return new Promise((resolve, reject) => {
      Provider.api
        .post(`/notifications/markread`)
        .then(response =>
          resolve(response.data.map(e => new NotificationModel(e)))
        )
        .catch(reject)
    })
  },

  markNotificationsAsUnread: ids => {
    return new Promise((resolve, reject) => {
      Provider.api
        .post(`/notifications/markunread`, {
          notification_ids: ids,
        })
        .then(response =>
          resolve(response.data.map(e => new NotificationModel(e)))
        )
        .catch(reject)
    })
  },
}
