class CalculationIntegrationMappingModel {
  constructor(data) {
    this.id = data.id;
    this.iteration = data.iteration;
    this.integrationId = data.company_integration?.integration_id;
    this.remoteId = data.remote_id;
    this.originLink = data.origin_link;
  }
}

export default CalculationIntegrationMappingModel
