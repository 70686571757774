import { createNanoEvents } from 'nanoevents';

export default class VueEventBus {
  constructor() {
    this._bus = createNanoEvents();
  }

  emit(event, ...args) {
    this._bus.emit(event, ...args);
  }

  on(event, callback) {
    if (!event || !callback) return;
    this._bus.on(event, callback);
  }

  once(event, callback) {
    if (!event || !callback) return;
    const unbind = this._bus.on(event, (...args) => {
      unbind();
      callback(...args);
    });
  }

  off(event, callback) {
    if (!event || !callback) return;
    const { events } = this._bus;
    events[event] = events[event]?.filter(i => callback !== i);
  }
}

window.vueEvents = new VueEventBus();
