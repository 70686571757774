import UiSearchDropListUsers from './Users'
import UiSearchDropListUserIds from './UserIds'
import UiSearchDropListProjects from './Projects'
import UiSearchDropList from './List'

export const installUiSearchListUsersComponents = app => {
  app.component('UiSearchDropListUsers', UiSearchDropListUsers);
  app.component('UiSearchDropListUserIds', UiSearchDropListUserIds);
  app.component('UiSearchDropListProjects', UiSearchDropListProjects);
  app.component('UiSearchDropList', UiSearchDropList);
};
