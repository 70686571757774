import Provider from "@provider";
import {
  RegistrationTypesGroup
} from "@/models";

export default {
  addRegistrationTypesGroup: ({name, position}) => {
    return new Promise((resolve, reject) => {
      Provider.api.post(`/registrationtypegroups`, {
        name,
        position
      })
        .then(response => resolve(new RegistrationTypesGroup(response.data)))
        .catch(reject);
    });
  },

  getRegistrationTypesGroups: () => {
    return new Promise((resolve, reject) => {
      Provider.api.get(`/registrationtypegroups`)
        .then(response => resolve(response.data.map(e => new RegistrationTypesGroup(e))))
        .catch(reject);
    });
  },

  getRegistrationTypesGroup: ({id}) => {
    return new Promise((resolve, reject) => {
      Provider.api.get(`/registrationtypegroups/${id}`)
        .then(response => resolve(new RegistrationTypesGroup(response.data)))
        .catch(reject);
    });
  },

  updateRegistrationTypesGroup: ({id, name, position}) => {
    return new Promise((resolve, reject) => {
      Provider.api.put(`/registrationtypegroups/${id}`, {
        name,
        position
      })
        .then(response => resolve(new RegistrationTypesGroup(response.data)))
        .catch(reject);
    });
  },

  deleteRegistrationTypesGroup: ({id}) => {
    return new Promise((resolve, reject) => {
      Provider.api.delete(`/registrationtypegroups/${id}`)
        .then(response => resolve(response.data))
        .catch(reject);
    });
  },
}