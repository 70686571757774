import {
  clearAuthMid,
  desktopMid,
  logMid,
  middlewareGroup,
  supportingDevice,
  unauthMid,
} from './plugins/middleware';

export default middlewareGroup(
  [logMid],
  [
    {
      component: () => import('@/layouts/auth'),
      path: '/auth',
      redirect: { name: 'signIn' },
      children: [
        ...middlewareGroup(
          [supportingDevice],
          [
            {
              path: '/sign-in',
              name: 'signInMobile',
              component: () => import('@/views/SignInMobile.vue'),
            },
          ]
        ),

        ...middlewareGroup(
          [desktopMid, unauthMid],
          [
            {
              path: '/sign-in',
              name: 'signIn',
              component: () => import('@/views/auth/SignIn/Index.vue'),
            },
            {
              path: '/forgot-password',
              name: 'forgotPassword',
              component: () => import('@/views/auth/ForgotPassword/Index.vue'),
            },
          ]
        ),

        ...middlewareGroup(
          [unauthMid],
          [
            {
              path: '/sign-up',
              name: 'signUp',
              component: () => import('@/views/auth/SignUp/Index.vue'),
            },
            {
              path: '/sign-up/:hash',
              name: 'signUpHash',
              component: () => import('@/views/auth/SignUp/Index.vue'),
            },
            {
              path: '/sign-up-referral/:hash',
              name: 'signUpReferral',
              component: () => import('@/views/auth/SignUp/Index.vue'),
            },
          ]
        ),

        ...middlewareGroup(
          [clearAuthMid],
          [
            {
              path: '/reset-password/:hash',
              name: 'resetPassword',
              component: () => import('@/views/auth/ResetPassword/Index.vue'),
            },
            {
              path: '/confirm-email/:hash',
              name: 'confirmEmail',
              component: () => import('@/views/auth/ConfirmEmail/Index.vue'),
            },
          ]
        ),

        {
          path: '/check-mail',
          name: 'checkMail',
          component: () => import('@/views/auth/CheckMail/Index.vue'),
        },
        {
          path: '/check-mail-reset-password',
          name: 'checkMailResetPassword',
          component: () => import('@/views/auth/CheckMailResetPassword/Index.vue'),
        },
      ],
    },
  ]
);
