import Provider from '@provider'
import { UserModel, ChatModel } from '@/models'
import LocalStorageWrapper from '@/plugins/localStorageWrapper'
import { LOCAL_STORAGE_KEYS } from '@/constants'
import { UPLOAD_FILES_URL } from '@/config'

export default {
  uploadAvatar: ({ userId, form }) => {
    return new Promise((resolve, reject) => {
      Provider.api
        .post(
          `${UPLOAD_FILES_URL}/users/${userId}/avatar`,
          form,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        )
        .then(response => resolve(new UserModel(response.data)))
        .catch(reject)
    })
  },

  register: form => {
    return new Promise((resolve, reject) => {
      Provider.api
        .post('/register', form)
        .then(response => {
          const tocken = response.data.access_token

          if (tocken) {
            Provider.setBearer(tocken)
          }

          resolve({
            tocken,
          })
        })
        .catch(reject)
    })
  },

  login: form => {
    return new Promise((resolve, reject) => {
      Provider.api
        .post('/login', form, { ignoreAuthError: true })
        .then(response => {
          Provider.setBearer(response.data.access_token)
          resolve()
        })
        .catch(reject)
    })
  },

  confirmEmail: hash => {
    return new Promise((resolve, reject) => {
      Provider.api
        .post(`/confirm-email/${hash}`)
        .then(response => {
          Provider.setBearer(response.data.access_token)
          resolve()
        })
        .catch(reject)
    })
  },

  getCurrentUser: () => {
    return new Promise((resolve, reject) => {
      Provider.api
        .get('/users/me')
        .then(response => resolve(new UserModel(response.data)))
        .catch(reject)
    })
  },

  getUsers: () => {
    return new Promise((resolve, reject) => {
      Provider.api
        .get('/users')
        .then(response => {
          resolve(response.data.map(e => new UserModel(e)))
        })
        .catch(reject)
    })
  },

  getUser: id => {
    return new Promise((resolve, reject) => {
      Provider.api
        .get(`/users/${id}`)
        .then(response => {
          resolve(new UserModel(response.data))
        })
        .catch(reject)
    })
  },

  getUsersChat: search => {
    return new Promise((resolve, reject) => {
      Provider.api
        .get('/teamchats', {
          params: {
            search,
          },
        })
        .then(response => {
          resolve(response.data.map(e => new ChatModel(e)))
        })
        .catch(reject)
    })
  },

  getContacts: ({ search, cancelToken }) => {
    return new Promise((resolve, reject) => {
      Provider.api
        .get('/contacts', {
          params: {
            search,
          },
          cancelToken,
        })
        .then(response => {
          resolve(
            response.data.map(e => {
              return {
                id: e.id,
                avatar: e.avatar,
                firstName: e.first_name,
                lastName: e.last_name,
                email: e.email,
              }
            })
          )
        })
        .catch(reject)
    })
  },

  updateUsers: (
    id,
    {
      firstName,
      lastName,
      email,
      tourDesktop,
      roleId,
      employeeNumber,
      password,
      language,
      user_properties,
      isGuest,
    },
  ) => {
    return new Promise((resolve, reject) => {
      Provider.api
        .put(`/users/${id}`, {
          first_name: firstName,
          last_name: lastName,
          tour_desktop: tourDesktop,
          role_id: roleId,
          employee_number: employeeNumber,
          is_guest: isGuest,
          email,
          password,
          language,
          user_properties
        })
        .then(response => {
          resolve(new UserModel(response.data))
        })
        .catch(reject)
    })
  },

  forgotPassword: ({ email }) => {
    return Provider.api.post('/forgotpassword', { email })
  },

  resetPassword: form => {
    return new Promise((resolve, reject) => {
      Provider.api
        .post(`/resetpassword/${form.hash}`, form)
        .then(response => {
          Provider.setBearer(response.data.access_token)
          resolve()
        })
        .catch(reject)
    })
  },

  deleteUser: id => {
    return new Promise((resolve, reject) => {
      Provider.api
        .delete(`/users/${id}`)
        .then(response => {
          resolve()
        })
        .catch(reject)
    })
  },

  clearUser: userId => {
    return Provider.api.post(`/users/${userId}/clear`)
      .then(response => new UserModel(response.data))
  },

  registerInvitationValidate: ({ hash, referral }) => {
    return new Promise((resolve, reject) => {
      Provider.api
        .get(`/registerinvite/${hash}`, {
          params: { referral: referral ? true : undefined },
        })
        .then(response => {
          resolve(response.data)
        })
        .catch(reject)
    })
  },

  userRegisterInvitation: ({ form, hash, referral }) => {
    form = referral ? { ...form, account_type: 'company' } : form;

    return new Promise((resolve, reject) => {
      Provider.api
        .post(`/registerinvite/${hash}`, form, {
          params: { referral: referral ? true : undefined },
        })
        .then(response => {
          Provider.setBearer(response.data.access_token)
          resolve(response.data)
        })
        .catch(reject)
    })
  },

  signOut: () => {
    return new Promise((resolve, reject) => {
      LocalStorageWrapper.clear([LOCAL_STORAGE_KEYS.CHECKLISTS])
      resolve()
    })
  },

  getOutlookOauthUrl: ({ email }) => {
    return Provider.api.post('/outlookauth', {
      oauth_email: email,
    })
      .then((response) => response.data)
  },

  getGoogleOauthUrl: ({ email }) => {
    return Provider.api.post('/googleauth', {
      oauth_email: email,
    })
      .then((response) => response.data?.url)
  },

  getUpvotyToken: () => {
    return new Promise((resolve, reject) => {
      Provider.api
        .get('/upvoty_token')
        .then(response => resolve(response))
        .catch(reject);
    });
  },
}
