import Provider from '@provider'
import { PlanningResourceGroupModel } from '@/models'

export default {
  addGroup: ({ name, position }) => {
    return new Promise((resolve, reject) => {
      Provider.api
        .post(`/planning/resourcegroups/`, {
          name,
          position,
        })
        .then(response =>
          resolve(new PlanningResourceGroupModel(response.data))
        )
        .catch(reject)
    })
  },

  getGroups: () => {
    return new Promise((resolve, reject) => {
      Provider.api
        .get(`/planning/resourcegroups/`)
        .then(response =>
          resolve(response.data.map(e => new PlanningResourceGroupModel(e)))
        )
        .catch(reject)
    })
  },

  getGroup: ({ id }) => {
    return new Promise((resolve, reject) => {
      Provider.api
        .get(`/planning/resourcegroups/${id}`)
        .then(response =>
          resolve(new PlanningResourceGroupModel(response.data))
        )
        .catch(reject)
    })
  },

  updateGroup: ({ id, name, position }) => {
    return new Promise((resolve, reject) => {
      Provider.api
        .put(`/planning/resourcegroups/${id}`, {
          name,
          position,
        })
        .then(response =>
          resolve(new PlanningResourceGroupModel(response.data))
        )
        .catch(reject)
    })
  },

  deleteGroup: ({ id }) => {
    return new Promise((resolve, reject) => {
      Provider.api
        .delete(`/planning/resourcegroups/${id}`)
        .then(response => resolve(response.data))
        .catch(reject)
    })
  },
}
