import ProjectsImportsProvider from '@provider/projectsImports'
import * as TYPES from '@/store/types'
import { ProjectsImportFileModel } from '@/models/ProjectsImport'
import { PROJECT_IMPORT_STATUS, IMPORT_FILE_STATUS } from '@/constants'

const state = {
  imports: [],
  files: []
}

const getters = {
  getFileImports: (state) => (fileId) => state.imports
    .filter((projectImport) => projectImport.fileId === fileId),
  loadingFile: (state) => state.files
    .find(({ status }) => status === IMPORT_FILE_STATUS.PENDING) || null
}

const actions = {
  getProjectImports({ commit }) {
    return new Promise((resolve, reject) => {
      ProjectsImportsProvider.getProjectImports()
        .then(imports => {
          commit(TYPES.PROJECTS_IMPORTS.INIT_IMPORTS, imports)
          resolve(imports)
        })
        .catch(reject)
    })
  },
  getImportedFiles({ commit }) {
    return new Promise((resolve, reject) => {
      ProjectsImportsProvider.getImportedFiles()
        .then(files => {
          commit(TYPES.PROJECTS_IMPORTS.INIT_FILES, files.reverse())
          resolve(files)
        })
        .catch(reject)
    })
  },
  rollbackFile(_, { fileId }) {
    return ProjectsImportsProvider.rollbackFile(fileId)
  },
  addProjectImportsFile({ rootGetters, commit }, { form }) {
    return new Promise((resolve, reject) => {
      ProjectsImportsProvider.addProjectImportsFile(form)
        .then(file => {
          commit(
            TYPES.PROJECTS_IMPORTS.ADD_FILE,
            new ProjectsImportFileModel(Object.assign(file, {
              company: rootGetters['Companies/meCompany'],
              status: 0,
              user: rootGetters['User/me']
            }))
          )
          resolve(file)
        })
        .catch(reject)
    })
  },
  downloadFileById(_, { fileId, fileName }) {
    return ProjectsImportsProvider.downloadProjectImportsFile(fileId, fileName)
  },

  processImportCsvSocketEvent({ state, commit, dispatch }, projectImport) {
    if (projectImport.status === PROJECT_IMPORT_STATUS.DELETED) {
      dispatch('removeProjectImport', projectImport)
    } else if (state.imports.some(({ id }) => id === projectImport.id)) {
      commit(TYPES.PROJECTS_IMPORTS.UPDATE_IMPORT, projectImport)
    } else {
      commit(TYPES.PROJECTS_IMPORTS.ADD_IMPORT, projectImport)
    }
  },
  removeProjectImport({ getters, commit }, projectImport) {
    commit(TYPES.PROJECTS_IMPORTS.REMOVE_IMPORT, projectImport.id)

    if (getters.getFileImports(projectImport.fileId).length === 0) {
      commit(TYPES.PROJECTS_IMPORTS.REMOVE_FILE, projectImport.fileId)
    }
  }
}

const mutations = {
  [TYPES.PROJECTS_IMPORTS.INIT_IMPORTS](state, imports) {
    state.imports = imports
  },
  [TYPES.PROJECTS_IMPORTS.ADD_IMPORT](state, projectImport) {
    state.imports.push(projectImport)
  },
  [TYPES.PROJECTS_IMPORTS.UPDATE_IMPORT](state, projectImport) {
    state.imports = [
      ...state.imports.filter(({ id }) => id !== projectImport.id),
      projectImport
    ]
  },
  [TYPES.PROJECTS_IMPORTS.REMOVE_IMPORT](state, projectImportId) {
    state.imports = state.imports.filter(({ id }) => id !== projectImportId)
  },
  [TYPES.PROJECTS_IMPORTS.REMOVE_IMPORT_BY_PROJECT_ID](state, projectId) {
    state.imports = state.imports.filter((projectImport) => projectImport.projectId !== projectId)
  },
  [TYPES.PROJECTS_IMPORTS.INIT_FILES](state, files) {
    state.files = files
  },
  [TYPES.PROJECTS_IMPORTS.ADD_FILE](state, file) {
    state.files.unshift(file)
  },
  [TYPES.PROJECTS_IMPORTS.REMOVE_FILE](state, fileId) {
    state.files = state.files.filter(({ id }) => id !== fileId)
  },
  [TYPES.PROJECTS_IMPORTS.SET_FILE_STATUS](state, { fileId, status }) {
    let file = state.files.find(({ id }) => id === fileId)

    if (file) {
      file.status = status
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
