class LocalStorageWrapper {
  setItem(key, value) {
    localStorage.setItem(key, JSON.stringify(value))
  }

  getObj(key, deff = undefined) {
    return this.getItem(key, { deff, parse: true })
  }

  mergeObj(key, obj = {}) {
    const data = this.getObj(key, obj)

    for (const key in data)
      if (Object.hasOwnProperty.call(data, key)) obj[key] = data[key]
  }

  setObj(key, value) {
    this.setItem(key, value)
  }

  getItem(key, { deff = undefined, parse = false }) {
    if (localStorage.hasOwnProperty(key)) {
      let value = localStorage.getItem(key)

      if (parse) {
        return JSON.parse(value)
      } else {
        return typeof value === 'string' ? value : JSON.parse(value)
      }
      // return JSON.parse(value)
    } else {
      return deff
    }
  }

  /**
   *
   * @param {String[]} exceptions
   */
  clear(exceptions = []) {
    let dataToSave = {}

    exceptions.forEach((key) => {
      Object.assign(dataToSave, {
        [key]: localStorage.getItem(key)
      })
    })

    localStorage.clear()

    exceptions.forEach((key) => {
      localStorage.setItem(key, dataToSave[key])
    })
  }
}

export default new LocalStorageWrapper()
