import * as TYPES from '@/store/types'
import PlanningResourcesProvider from '@provider/planningResources'

// initial state
const state = {
  loadingResources: false,
  resources: [],
  resourcesByIds: {},
}

// getters
const getters = {
  notUserResources: state => state.resources.filter(({ user }) => !user?.id),
  planningResources: state => state.resources,
  // TODO: replace with the resourcesByIds state
  planningResourcesByIds: state =>
    state.resources.reduce(
      (resourcesByIds, resource) => {
        resourcesByIds[resource.id] = resource;
        return resourcesByIds;
      },
      {},
    ),
  getPlanningResourcesByGroup: state => groupId =>
    state.resources.filter(e => e.resourceGroupId == groupId),
}

// actions
const actions = {
  addPlanningResource({ commit }, form) {
    return new Promise((resolve, reject) => {
      PlanningResourcesProvider.addResource(form)
        .then(resource => {
          commit(TYPES.PLANNING_RESOURCES.INIT_RESOURCES, resource)
          resolve(resource)
        })
        .catch(reject)
    })
  },

  getPlanningResource({ commit }, { id }) {
    return new Promise((resolve, reject) => {
      PlanningResourcesProvider.getResource({ id })
        .then(resource => {
          commit(TYPES.PLANNING_RESOURCES.INIT_RESOURCES, resource)
          resolve(resource)
        })
        .catch(reject)
    })
  },

  getPlanningResources({ commit }) {
    commit(TYPES.PLANNING_RESOURCES.SET_LOADING_RESOURCES, true);

    return new Promise((resolve, reject) => {
      PlanningResourcesProvider.getResources()
        .then(resources => {
          commit(TYPES.PLANNING_RESOURCES.INIT_RESOURCES, resources)
          resolve(resources)
        })
        .catch(reject)
        .finally(() => commit(TYPES.PLANNING_RESOURCES.SET_LOADING_RESOURCES, false));
    })
  },

  updatePlanningResource({ commit }, form) {
    return new Promise((resolve, reject) => {
      PlanningResourcesProvider.updateResource(form)
        .then(resource => {
          commit(TYPES.PLANNING_RESOURCES.INIT_RESOURCES, resource)
          resolve(resource)
        })
        .catch(reject)
    })
  },

  deletePlanningResource({ commit }, { id }) {
    return new Promise((resolve, reject) => {
      PlanningResourcesProvider.deleteResource({ id })
        .then(() => {
          commit(TYPES.PLANNING_RESOURCES.DELETE_RESOURCES, id)
          resolve()
        })
        .catch(reject)
    })
  },
}

// mutations
const mutations = {
  [TYPES.PLANNING_RESOURCES.INIT_RESOURCES](state, data) {
    let list = Array.isArray(data) ? data : [data],
      ids = list.map(e => e.id)

    state.resources = [
      ...state.resources.filter(e => !ids.includes(e.id)),
      ...list,
    ]

    const { resourcesByIds } = state;
    state.resources.forEach(resource => (resourcesByIds[resource.id] = resource));
  },

  [TYPES.PLANNING_RESOURCES.DELETE_RESOURCES](state, data) {
    const ids = Array.isArray(data) ? data : [data]

    state.resources = [...state.resources.filter(e => !ids.includes(e.id))]

    const { resourcesByIds } = state;
    ids.forEach(id => delete resourcesByIds[id]);
  },

  [TYPES.PLANNING_RESOURCES.SET_LOADING_RESOURCES](state, loading) {
    state.loadingResources = loading;
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
