import Provider from "@provider";
import {LabelModel} from "@/models";

export default {
  addLabel: ({name, color}) => {
    return new Promise((resolve, reject) => {
      Provider.api.post("/labels", {
        name, color
      })
      .then(response => resolve(new LabelModel(response.data)))
      .catch(reject);
    });
  },

  getLabels: () => {
    return new Promise((resolve, reject) => {
      Provider.api.get("/labels")
        .then(response => resolve(response.data.map(e => new LabelModel(e))))
        .catch(reject);
    });
  },

  getDefaultColors: () => {
    return new Promise((resolve, reject) => {
      Provider.api.get("/labels/defaultcolours")
          .then(response => resolve(response.data))
          .catch(reject);
    });
  },

  getLabel: ({id}) => {
    return new Promise((resolve, reject) => {
      Provider.api.get(`/labels/${id}`)
        .then(response => resolve(new LabelModel(response.data)))
        .catch(reject);
    });
  },

  updateLabel: ({id, name, color}) => {
    return new Promise((resolve, reject) => {
      Provider.api.put(`/labels/${id}`, {
        name, color
      })
      .then(response => resolve(new LabelModel(response.data)))
      .catch(reject);
    });
  },

  deleteLabel: ({id}) => {
    return new Promise((resolve, reject) => {
      Provider.api.delete(`/labels/${id}`)
        .then(response => resolve(new LabelModel(response.data)))
        .catch(reject);
    });
  }
};