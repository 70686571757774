import Provider from "@provider";
import { MaterialImportModel, MaterialsImportFileModel } from '@/models'

export default  {
  getImportedFiles: (type) => {
    return new Promise((resolve, reject) => {
      Provider.api.get(`/imports/files?type=1`)
        .then(({ data }) => resolve(data.map(materialsImportFile => new MaterialsImportFileModel(materialsImportFile))))
        .catch(reject)
    })
  },
  rollbackFile: (fileId) => {
    return new Promise((resolve, reject) => {
      Provider.api.delete(`/imports/${ fileId }`)
        .then(({ data }) => resolve(data))
        .catch(reject)
    })
  },
  getMaterialImports: () => {
    return new Promise((resolve, reject) => {
      Provider.api.get('/imports?type=1')
        .then(({ data }) => resolve(data.map(materialImport => new MaterialImportModel(materialImport))))
        .catch(reject)
    })
  },
  addMaterialImportsFile(form) {
    return new Promise((resolve, reject) => {
      Provider.api.post('/imports', form)
        .then(({ data }) => resolve(data))
        .catch(reject)
    })
  },
  downloadProjectImportsFile(fileId, fileName) {
    return new Promise((resolve, reject) => {
      Provider.api.get(`/imports/file/${ fileId }`, {
        responseType: 'blob'
      })
        .then(({ data }) => {
          let link = document.createElement('a')
          link.href = window.URL.createObjectURL(data)
          link.download = fileName
          link.click()
          link.remove()

          resolve(data)
        })
        .catch(reject)
    })
  }
}
