export default {
  canUseProjectRegistration: ({ $store, $router }) => {
    const me = $store.getters['User/me'];

    if (me.canUseProjectRegistration) return;

    $router.push({
      name: 'home',
    });

    throw `Access denied. The user can't use project registrations`;
  },
};
