export default {
  inject: ['planningScrollbar'],

  props: {
    zoomPlan: {
      require: true,
    },
  },

  computed: {
    style() {
      return {
        minHeight: this.planningScrollbar.height + 'px',
      }
    },

    items() {
      return this.lodash.get(this, 'zoomPlan.items', [])
    },
  },
}
