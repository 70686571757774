class UtilsColors {
  constructor() {}

  get list() {
    return [
      '#20CC67',
      '#8049D4',
      '#CDB42E',
      '#008148',
      '#FF5725',
      '#FFAB05',
      '#FF49C7',
      '#333333',
      '#FF008A',
      '#825443',
      '#F2325C',
      '#C4C4C4',
      '#C62354',
      '#AE59E0',
      '#808080',
      '#469EFF',
      '#0088C3',
      '#FFC900',
      '#8CD400',
      '#40D0FF',
    ]
  }
}

export default new UtilsColors()
